import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import moment from "moment";
import classnames from "classnames";
import { Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { jsPDF } from "jspdf";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import {
  getCopyTradeApplications,
  updateTraderStatus,
  updateCopyTradeApplication,
} from "../../actions/copyTradeAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";

// import library
import { firstLetterCase } from "../../lib/capitalize";

class CopyTradeApplications extends Component {
    constructor(props) {
        super(props);

        this.columns = [{
            key: "userId",
            text: "User",
            className: "identifier",
            align: "left",
            sortable: true,
            cell: record => {
                return record.userId.name;
            }
        },
        {
            key: "commission",
            text: "Commission (%)",
            className: "commission",
            align: "left",
            sortable: true,
        },
        {
            key: "adminFee",
            text: "Admin Commission (%)",
            className: "adminCommission",
            align: "left",
            sortable: true,
            cell: record => {
                return `Take ${record.adminFee ?? 0} Each ${record.adminFeeEach ?? 0}`;
            }
        },
        {
            key: "approve",
            text: "Approval",
            className: "approval",
            align: "left",
            sortable: true,
            cell: record => {
                if(record.approve === 1) {
                    return <span className="text-info">Pending</span>;
                } else if(record.approve===2) {
                    return <span className="text-success">Approved</span>;
                } else {
                    return <span className="text-danger">Rejected</span>;
                }
            }
        },
        {
            key: "createdAt",
            text: "Date",
            className: "date",
            align: "left",
            sortable: true,
            cell: record => {
                return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {record.approve===1 && (<><button className="btn btn-primary btn-sm mr-1" onClick={() => this.changestatus(record,2)}>Accept</button><button className="btn btn-danger btn-sm mr-1" onClick={() => this.changestatus(record,3)}>Reject</button></>)}
                        {record.approve===2 && (<button className="btn btn-primary btn-sm" onClick={() => this.editRecord(record)}><i className="fa fa-edit"></i></button>)}
                    </Fragment>
                ); 
            }
        }];

        this.state = {
            totalCount: 0,
            records: [],
            offset: 0,
            pageSize: 10,
            sortOrder: {
                column: "createdAt",
                order: "desc"
            },
            loading: true,
            exportType: "current",
            extraButtons: [{
                className: "btn btn-primary buttons-pdf",
                title: "Export PDF",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
                    </span>,
                ],
                onClick: (event) => {
                    this.exportPDF();
                },
            },
            {
                className: "btn btn-primary buttons-csv",
                title: "Export CSV",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    this.exportCSV();
                },
            }],
            formModal: false,
            traderId: "",
            adminFee: "",
            adminFeeEach: "",
            errors: {},
        };

        this.config = {
            page_size: this.state.pageSize,
            sort: this.state.sortOrder,
            length_menu: [ 10, 20, 50 ],
            no_data_text: 'No Application found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            key_column: "_id",
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            pagination: "advance",
            show_info: true,
            button: {
                extra: true,
            }
        };

        this.getData = this.getData.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    getData = async (filter = "") => {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                page: "application",
                sortOrder: this.state.sortOrder,
                filter,
                limit: this.state.pageSize,
                offset: this.state.offset,
            };
            this.setState({ loading: true });
            let { status, result, totalCount } = await getCopyTradeApplications(data);
            if (status) {
                this.setState({ loading: false });
                this.setState({ records: result, totalCount });
            } 
        } catch (err) {}
    }

    changestatus = async (record, action) => {
        try {
            let data = {
                id: record._id,
                action: action,
            };
            this.setState({ loader: true });
            let { status, message } = await updateTraderStatus(data);
            this.setState({ loader: false });
            if (status)
                toastAlert("success", message, "addTemplate");
            else
                toastAlert("error", message, "addTemplate");
            this.getData();
        } catch (err) {}
    }

    search = data => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number-1)*pageSize;
        let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter }, function() {
            this.getData(filter);
        }); 
    }

    editRecord(record) {
        this.setState({
            formModal: true,
            traderId: record._id,
            adminFee: record.adminFee,
            adminFeeEach: record.adminFeeEach,
        });
    }

    handleClose() {
        this.setState({ formModal: false, traderId: "", adminFee: "", adminFeeEach: "", errors: {} });
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        let errors = { ...this.state.errors, [name]:"" }
        this.setState({ [name]: value, errors });
    };

    validPositive = (e) => {
        if (new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) || (e.target.value = ""))
            e.preventDefault();
    };

    handleSubmit = async (e) => {
        try {
            let data = {
                id: this.state.traderId,
                adminFee: this.state.adminFee,
                adminFeeEach: this.state.adminFeeEach,
            };
            this.setState({ loader: true });
            let { status, loading, message, error } = await updateCopyTradeApplication(data);
            this.setState({ loader: loading });
            if (status) {
                this.handleClose();
                toastAlert("success", message);
            } else {
                if (error)
                    this.setState({ errors: error });
                toastAlert("error", message);
            }
            this.getData();
        } catch (err) {}
    }

    exportCSV = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1,
            page: "application",
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });

        let { status, result } = await getCopyTradeApplications(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];
        let separator = ";";
        let headers = [
            "User",
            "Commission (%)",
            "Admin Commission (%)",
            "Approval",
            "Date",
        ];

        let rows = [];
        records.length > 0 && records.map((elt) => rows.push({
            "User": elt.userId.name,
            "Commission (%)": elt.commission,
            "Admin Commission (%)": `Take ${elt.adminFee ?? 0} Each ${elt.adminFeeEach ?? 0}`,
            "Approval": elt.approve === 1 ? "Pending":elt.approve === 2 ? "Approved":"Rejected",
            "Date": moment(elt.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        }));

        let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
            headers.map((k) => {
                let cell = row[k] === null || row[k] === undefined ? "" : row[k];

                cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator)
        ).join("\n")}`;

        let title = "Copy Trade Applications";
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // In case of IE 10+
            navigator.msSaveBlob(blob, title);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", title);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    exportPDF = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1,
            page: "application",
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await getCopyTradeApplications(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];

        let unit = "pt";
        let size = "A4"; // Use A1, A2, A3 or A4
        let orientation = "landscape"; // portrait or landscape

        let marginLeft = 40;
        let doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);

        let title = "Copy Trade Applications";
        let headers = [[
            "User",
            "Commission (%)",
            "Admin Commission (%)",
            "Approval",
            "Date",
        ]];

        let downloadData = records.length > 0 && records.map((elt) => [
            elt.userId.name,
            elt.commission,
            `Take ${elt.adminFee ?? 0} Each ${elt.adminFeeEach ?? 0}`,
            elt.approve === 1 ? "Pending":elt.approve === 2 ? "Approved":"Rejected",
            moment(elt.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: downloadData,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Copy Trade Applications.pdf");
    }

    render() {
        const { errors, formModal, adminFee, adminFeeEach } = this.state;
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <div className="float-xl-right">
                                Export
                                {["current", "all"].map((v) => (
                                    <Form.Check
                                        inline
                                        type="radio"
                                        name="exportType"
                                        value={v}
                                        key={v}
                                        onChange={(e) => this.setState({ exportType: e.target.value })}
                                        defaultChecked={v == this.state.exportType}
                                        label={firstLetterCase(v)}
                                    />
                                ))}
                            </div>
                            <h3 className="mt-2 text-secondary">Copy Trade Applications List</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                loading={this.state.loading}
                                onChange={this.search}
                                total_record={this.state.totalCount}
                                extraButtons={this.state.extraButtons}
                            />
                        </div>
                    </div>
                    <Modal
                        show={formModal}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="md"
                        centered
                    >
                        <Modal.Header closeButton>
                            <h4 className="modal-title">
                                Admin Commission
                            </h4>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mt-2">
                                <div className="col-md-1">
                                    <label htmlFor="adminFee">Take</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onInput={this.validPositive}
                                        onChange={this.handleChange}
                                        value={adminFee}
                                        name="adminFee"
                                        type="text"
                                        error={errors.adminFee}
                                        className={classnames(
                                            "form-control",
                                            {
                                                invalid: errors.adminFee,
                                            }
                                        )}
                                    />
                                    <span className="text-danger">
                                        {errors.adminFee}
                                    </span>
                                </div>
                                <div className="col-md-1">
                                    <label htmlFor="adminFeeEach">
                                        Each
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onInput={this.validPositive}
                                        onChange={this.handleChange}
                                        value={adminFeeEach}
                                        name="adminFeeEach"
                                        type="text"
                                        error={errors.adminFeeEach}
                                        className={classnames(
                                            "form-control",
                                            {
                                                invalid:
                                                    errors.adminFeeEach,
                                            }
                                        )}
                                    />
                                    <span className="text-danger">
                                        {errors.adminFeeEach}
                                    </span>
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="adminFeeEach">%</label>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.handleClose}
                            >
                                Close
                            </button>
                            <button
                                onClick={this.handleSubmit}
                                type="submit"
                                className="btn btn-primary"
                            >
                                Submit
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }

}

export default CopyTradeApplications;
