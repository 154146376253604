import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Form } from "react-bootstrap";
import moment from "moment";
import { jsPDF } from "jspdf";

// import component
import CoinWithdrawModal from "../partials/CoinWithdrawModal";
import FiatWithdrawModal from "../partials/FiatWithdrawModal";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { getWithdrawList } from "../../actions/walletAction";

// import library
import { firstLetterCase } from "../../lib/capitalize";

class Withdraw extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "createdAt",
                text: "Created Date",
                className: "created_date",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
                },
            },
            {
                key: "userId",
                text: "Email",
                className: "userId",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.userId && record.userId.email ? record.userId.email:"-";
                },
            },
            {
                key: "toaddress",
                text: "To Address/Account",
                className: "toaddress",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.paymentType == "fiat_withdraw" ? record.bankDetail && record.bankDetail.accountNo ? record.bankDetail.accountNo:"":record.toaddress;
                },
            },
            {
                key: "currencySymbol",
                text: "Currency",
                className: "currency",
                align: "left",
                sortable: true,
            },
            {
                key: "paymentType",
                text: "Payment Type",
                className: "currency",
                align: "left",
                sortable: true,
            },
            {
                key: "actualAmount",
                text: "Transfer Amount",
                className: "amount",
                align: "left",
                sortable: true,
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return firstLetterCase(record.status);
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                align: "left",
                sortable: false,
                cell: (record) => {
                    if (record.paymentType == "coin_withdraw") {
                        return (
                            <Fragment>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                        this.setState({
                                            coinFormModal: true,
                                            withdrawRecord: record,
                                        })
                                    }
                                    style={{ marginRight: "5px" }}
                                >
                                    <i className="fa fa-check"></i>
                                </button>
                            </Fragment>
                        );
                    } else if (record.paymentType == "fiat_withdraw") {
                        return (
                            <Fragment>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                        this.setState({
                                            fiatFormModal: true,
                                            withdrawRecord: record,
                                        })
                                    }
                                    style={{ marginRight: "5px" }}
                                >
                                    <i className="fa fa-check"></i>
                                </button>
                            </Fragment>
                        );
                    }
                },
            },
        ];

        this.state = {
            coinFormModal: false,
            fiatFormModal: false,
            withdrawRecord: {},
            records: [],
            totalCount: 0,
            offset: 0,
            pageSize: 10,
            sortOrder: {
                column: "_id",
                order: "desc",
            },
            loading: true,
            categoryOption: [],
            exportType: "current",
            extraButtons: [{
                className: "btn btn-primary buttons-pdf",
                title: "Export PDF",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
                    </span>,
                ],
                onClick: (event) => {
                    this.exportPDF();
                }
            },
            {
                className: "btn btn-primary buttons-csv",
                title: "Export CSV",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
                    </span>,
                ],
                onClick: (event) => {
                    this.exportCSV();
                }
            }]
        };

        this.config = {
            page_size: this.state.limit,
            sort: this.state.sortOrder,
            length_menu: [10, 20, 50],
            no_data_text: "No Records found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            key_column: "_id",
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            pagination: "advance",
            show_info: true,
            button: {
                extra: true,
            },
        };

        this.getData = this.getData.bind(this);
        this.refetch = this.refetch.bind(this);
        this.handleCloseCoinForm = this.handleCloseCoinForm.bind(this);
        this.handleCloseFiatForm = this.handleCloseFiatForm.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (filter = "") => {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                sortOrder: this.state.sortOrder,
                filter,
                limit: this.state.pageSize,
                offset: this.state.offset,
            };
            let { status, loading, result, totalCount } = await getWithdrawList(data);
            this.setState({ loading });
            if (status) {
                this.setState({ records: [], totalCount: 0 }, function() {
                    this.setState({ totalCount, records: result });
                });
            }
        } catch (err) {}
    };

    handleCloseCoinForm() {
        this.setState({ coinFormModal: false });
    }

    handleCloseFiatForm() {
        this.setState({ fiatFormModal: false });
    }

    refetch() {
        this.getData();
    }

    exportCSV = async () => {

        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await getWithdrawList(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];
        let separator = ";";
        let headers = [
            "Created Date",
            "Email",
            "To Address/Account",
            "Currency",
            "Payment Type",
            "Transfer Amount",
            "Status",
        ];

        let rows = [];
        records.length > 0 && records.map((elt) => rows.push({
            "Created Date": moment(elt.createdAt).format("DD-MM-YYYY HH:mm:ss"),
            "Email": elt.userId && elt.userId.email ? elt.userId.email:"-",
            "To Address/Account": elt.paymentType == "fiat_withdraw" ? elt.bankDetail && elt.bankDetail.accountNo ? elt.bankDetail.accountNo:"":elt.toaddress,
            "Currency": elt.currencySymbol,
            "Payment Type": elt.paymentType,
            "Transfer Amount": elt.actualAmount,
            "Status": firstLetterCase(elt.status),
        }));

        let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
            headers.map((k) => {
                let cell = row[k] === null || row[k] === undefined ? "" : row[k];

                cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator)
        ).join("\n")}`;

        let title = "Withdraw History";
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // In case of IE 10+
            navigator.msSaveBlob(blob, title);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", title);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    async exportPDF() {

        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await getWithdrawList(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];

        let unit = "pt";
        let size = "A4"; // Use A1, A2, A3 or A4
        let orientation = "landscape"; // portrait or landscape

        let marginLeft = 40;
        let doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);

        let title = "Withdraw History";
        let headers = [[
            "Created Date",
            "Email",
            "To Address/Account",
            "Currency",
            "Payment Type",
            "Transfer Amount",
            "Status",
        ]];

        let downloadData = records.length > 0 && records.map((elt) => [
            moment(elt.createdAt).format("DD-MM-YYYY HH:mm:ss"),
            elt.userId && elt.userId.email ? elt.userId.email:"-",
            elt.paymentType == "fiat_withdraw" ? elt.bankDetail && elt.bankDetail.accountNo ? elt.bankDetail.accountNo:"":elt.toaddress,
            elt.currencySymbol,
            elt.paymentType,
            elt.actualAmount,
            firstLetterCase(elt.status),
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: downloadData,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Withdraw History.pdf");
    }

    search = data => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number-1)*pageSize;
        let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
            this.getData(filter);
        }); 
    }

    render() {
        let { coinFormModal, fiatFormModal, withdrawRecord } = this.state;

        return (
            <div>
                <CoinWithdrawModal
                    isShow={coinFormModal}
                    onHide={this.handleCloseCoinForm}
                    record={withdrawRecord}
                    fetchData={this.refetch}
                />
                <FiatWithdrawModal
                    isShow={fiatFormModal}
                    onHide={this.handleCloseFiatForm}
                    record={withdrawRecord}
                    fetchData={this.refetch}
                />

                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey} />
                    <div id="page-content-wrapper">
                        <div className="float-xl-right">
                            Export
                            {["current", "all"].map((v) => (
                                <Form.Check
                                    inline
                                    type="radio"
                                    name="exportType"
                                    value={v}
                                    key={v}
                                    onChange={(e) => this.setState({ exportType: e.target.value }) }
                                    defaultChecked={v == this.state.exportType}
                                    label={firstLetterCase(v)}
                                />
                            ))}
                        </div>
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Withdraw History</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                loading={this.state.loading}
                                onChange={this.search}
                                extraButtons={this.state.extraButtons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Withdraw;
