// import config
import axios from "../config/axios";

let applicationSource,
    followerSource,
    copyOrderSource,
    copyTradeSource,
    bannedSource,
    subscriptionSource,
    subscriberSource,
    adminProfitSource;

export const getCopyTradeSettings = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/copyTradeSettings`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const updateCopyTradeSettings = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/adminapi/copyTradeSettings`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getCopyTradeApplications = async (data = {}) => {
    try {
        if (applicationSource) applicationSource.cancel();
        applicationSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/copyTradeApplications`,
            data,
            cancelToken: applicationSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const updateTraderStatus = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/adminapi/copyTradeApplications`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const updateCopyTradeApplication = async (data) => {
    try {
        let respData = await axios({
            method: "patch",
            url: `/adminapi/copyTradeApplications`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getBannedTraders = async (data = {}) => {
    try {
        if (bannedSource) bannedSource.cancel();
        bannedSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getBannedTraders`,
            data,
            cancelToken: bannedSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const releaseTrader = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/releaseTrader`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getFollowersHistory = async (data = {}) => {
    try {
        if (followerSource) followerSource.cancel();
        followerSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getFollowersHistory`,
            data,
            cancelToken: followerSource.token,
        });
        return {
            status: "success",
            loading: false,
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const copyOrderHistory = async (data = {}) => {
    try {
        if (copyOrderSource) copyOrderSource.cancel();
        copyOrderSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/copyOrderHistory`,
            data,
            cancelToken: copyOrderSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const copyTradeHistory = async (data = {}) => {
    try {
        if (copyTradeSource) copyTradeSource.cancel();
        copyTradeSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/copyTradeHistory`,
            data,
            cancelToken: copyTradeSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const getAdminProfit = async (data = {}) => {
    try {

        if (adminProfitSource) adminProfitSource.cancel();
        adminProfitSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getAdminProfit`,
            data,
            cancelToken: adminProfitSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const getBadges = async (data = {}) => {
    try {
        const respData = await axios({
            url: `/adminapi/badge`,
            method: "post",
            data,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const badgeAddUpdate = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/badge`,
            method: "put",
            data,
        });
        return {
            response: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            response: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const badgeDelete = async (id) => {
    try {
        const respData = await axios({
            url: `/adminapi/badge`,
            method: "delete",
            data: { id },
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getSubscriptions = async (data = {}) => {
    try {

        if (subscriptionSource) subscriptionSource.cancel();
        subscriptionSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/subscription`,
            method: "post",
            data,
            cancelToken: subscriptionSource.token,
        });
        return {
            status: "success",
            loading: false,
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const subscriptionAddUpdate = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/subscription`,
            method: "put",
            data,
        });
        return {
            response: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            response: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const subscriptionDelete = async (id) => {
    try {
        const respData = await axios({
            url: `/adminapi/subscription`,
            method: "delete",
            data: { id },
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getSubscribersHistory = async (data = {}) => {
    try {

        if (subscriberSource) subscriberSource.cancel();
        subscriberSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getSubscribersHistory`,
            data,
            cancelToken: subscriberSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};
