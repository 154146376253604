import React, { Component, Fragment } from "react";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";

//import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toastAlert } from "../../lib/toastAlert";

//impport action
import { getairdropList } from "../../actions/airdropAction";

class AirdropList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "createdAt",
        text: "Created date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          console.log(record,'record')
          return (
            <Fragment>
              <p>
                {record.createdAt ? moment(record.createdAt).format("MMMM,Do YYYY, hh:mm A"):"-"}
              </p>
            </Fragment>
          );
        },
      },

      {
        key: "tokenamount",
        text: "Token Amount",
        className: "tokenamount",
        align: "left",
        sortable: true,
      },

      {
        key: "currencySymbol",
        text: "Token Symbol",
        className: "currencySymbol",
        align: "left",
        sortable: true,
        // cell: (record) => {
        //   return (
        //     <Fragment>
        //       <p>{record.currencyId && record.currencyId.currencySymbol}</p>
        //     </Fragment>
        //   );
        // },
      },

      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Perpetual",
      no_data_text: "No Contracts found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      loader: false,
      page: 1,
      limit: 10,
      count: 0,
      responsive: true,
    };

    this.getUserList = this.getUserList.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getUserList(reqData);
  }

  async getUserList(reqData) {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getairdropList(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({ count: result.count, records: result.data });
      }
    } catch (err) {}
  }
  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getUserList(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }
  addRecord() {
    this.props.history.push("/airdrop");
  }

  render() {
    const { count } = this.state;
    return (
  
     <>
              {/* <button
                onClick={() => this.addRecord()}
                className="btn btn-outline-primary float-end mt-3 mr-2"
              >
                Send Airdrop
              </button> */}
              <h3 className="mt-2 text-secondary">Airdrop Management</h3>

              <ReactDatatable
                // responsive={this.state.responsive}
                config={this.config}
                records={this.state.records}
                // total_record={count}
                // dynamic={true}
                columns={this.columns}
                onChange={this.handlePagination}
              />
           </>
       
    );
  }
}

export default AirdropList;
