// import lib
import isEmpty from './isEmpty';

export const firstLetterCase = (value) => {
    if (!isEmpty(value)) {
        return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
    }
    return ''
}
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  export default capitalizeFirstLetter;