import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
const url = keys.baseUrl;

class StakeOrderHist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      search: "",
      page: 1,
      limit: 10,
      count: 0,
      loader: false,
    };
    this.columns = [
      {
        key: "date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        width: 200,
        cell: (record) => {
          return moment(record.created_date).format("YYYY-MM-DD h:mm:ss");
        },
      },
      {
        key: "email",
        text: "Email",
        className: "date",
        align: "left",
        sortable: true,
        width: 200,
        cell: (record) => {
          if (record && record.userId) {
            return record.userId.email;
          }
          return "-";
        },
      },
      {
        key: "_id",
        text: "Order Id",
        className: "type",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "coin",
        text: "Coin",
        className: "type",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "amount",
        text: "Amount",
        className: "type",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "APY",
        text: "APY",
        className: "type",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "action",
        text: "Order Type",
        className: "type",
        align: "left",
        sortable: true,
        width: 200,
        cell: (record) => {
          if (record.type == "flexible") {
            return "Flexible";
          } else if (record.type == "fixed") {
            return "Locked";
          } 
        },
      },
      {
        key: "status",
        text: "Status",
        className: "email",
        align: "left",
        sortable: true,
        width: 200,
        cell: (record) => {
          if (record.status == "active") {
            return "Active";
          } else if (record.status == "cancel_date") {
            return "Completed";
          } else if (record.status == "cancel_user") {
            return "Cancelled";
          }
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Order",
      no_data_text: "No Records found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in Coins...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }
  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.fetchData(reqData);
  }

  async fetchData(reqData) {
    this.setState({ loader: true });
    try {
      const respData = await axios({
        url: `${url}adminapi/stakingorderHistory`,
        method: "get",
        params: reqData,
      });
      this.setState({
        loader: false,
        count: respData.data.count,
        records: respData.data.data,
      });
    } catch (err) {
      this.setState({ loader: false });
    }
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.fetchData(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  render() {
    let array = [
      [
        "Date",
        "Type",
        "Side",
        "Order Id",
        "Email",
        "Curreny/Pair",
        "Price",
        "Filled",
        "Fees",
        "Total",
      ],
    ];
    for (let item of this.state.records) {
      if (item.buyorsell == "sell") {
        var buyorsell = "Sell";
      } else if (item.buyorsell == "buy") {
        var buyorsell = "Buy";
      } else {
        var buyorsell = item.buyorsell;
      }

      if (item.filledArr != null) {
        var email = item.filledArr.email;
      } else if (item.filledArr) {
        var email = item.filledArr.email;
      } else {
        var email = "NULL";
      }

      var date = moment(item.date).format("YYYY-MM-DD h:mm:ss");
      var currency_pair = item.firstCurrency + "/" + item.secondCurrency;
      var filled = parseFloat(item.filledAmount);
      var price = parseFloat(item.price);
      var fees = parseFloat(item.fee);
      var total = (filled + fees) * price;

      let dataArray = [
        date,
        item.type,
        buyorsell,
        item.orderId,
        email,
        currency_pair,
        item.price,
        item.filledAmount,
        item.fee,
        total,
      ];
      array.push(dataArray);
    }

    const { records, loader, count } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              {/* <CSVLink data={array} className="btn btn-outline-primary float-end mt-3 mr-2">Download.csv</CSVLink> */}
              <h3 className="mt-2 text-secondary">Stake Order History</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={count}
                loading={loader}
                onChange={this.handlePagination}
              />
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

StakeOrderHist.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(StakeOrderHist);