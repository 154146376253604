import React, { Component } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import moment from "moment";
import socketClient from "socket.io-client";
import $ from 'jquery';
const url = keys.socketUrl;

class p2ptradeview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trade: {},
      chats: {},
      chatMessage: "",
      proofImage: "",
      p2psellerbtn:false,
      p2pbuyerbtn:false
    };

    this.chatsEndRef = React.createRef();
    this.socket = socketClient(url);
  }

  componentDidMount() {
    this.getData();
    this.getChatData();
    this.socket.on(`p2pchat-${this.props.match.params.id}`, (data) => {
      this.setState(
        function (prevState) {
          let newChat = [...prevState.chats, ...data];
          return {
            chats: newChat,
          };
        },
        function () {
          this.scrollToBottom();
        }
      );
    });
  }

  getData() {
    const id = this.props.match.params.id;
    axios
      .get(url + "adminapi/p2pTradeView/" + id)
      .then((res) => {
        console.log("p2pTradeViewp2pTradeViewp2pTradeView",res.data.data)
        this.setState({ trade: res.data.data });
      })
      .catch();
  }

  getChatData() {
    const id = this.props.match.params.id;
    axios
      .get(url + "adminapi/p2pchat/" + id)
      .then((res) => {
        this.setState({ chats: res.data.data }, function () {
          this.scrollToBottom();
        });
      })
      .catch();
  }

  scrollToBottom = () => {
    this.chatsEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  onSkipbutton() {
    this.props.history.goBack();
  }

  p2papprove_buyer(order) {
    this.setState({ p2pbuyerbtn: true })
    
    if (window.confirm("Are you sure?")) {
      var postdata = { _id: order._id };
      axios
        .post(url + "adminapi/p2presolveBuyer", postdata)
        .then((res) => {
          toast("Resolved Successfully for Buyer", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ p2pbuyerbtn: false })
          this.props.history.goBack();
        })
        .catch();
    }
  }

  p2papprove_seller(order) {
    this.setState({ p2psellerbtn: true })
    
    if (window.confirm("Are you sure?")) {
      var postdata = { _id: order._id };
      axios
        .post(url + "adminapi/p2presolveSeller", postdata)
        .then((res) => {
          toast("Resolved Successfully for Seller", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ p2psellerbtn: false })
          this.props.history.goBack();
        })
        .catch();
    }
  }

  handleMessageChange = (e) => {
    this.setState({ chatMessage: e.target.value });
  };

  handleFile = (e) => {
    this.setState({ proofImage: e.target.files[0] });
  };

  //  Send message
  handleSend = async (e) => {
    const formData = new FormData();
    formData.append("orderId", this.state.trade._id);
    formData.append("userid", "admin");
    formData.append("msg", this.state.chatMessage);
    formData.append("proofImage", this.state.proofImage);

    axios
      .post(url + "adminapi/saveChatDetails", formData)
      .then((res) => {
        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({ chatMessage: "" ,proofImage:""});
        $("#file-input").val('');
      })
      .catch((err) => {
        toast(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  render() {
    const { trade, chats, chatMessage, p2psellerbtn, p2pbuyerbtn } = this.state;
    console.log(p2psellerbtn, p2pbuyerbtn,"p2psellerbtn, p2pbuyerbtn")
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 mb-0 text-secondary">
                Trade Details
                <button
                  onClick={() => this.onSkipbutton()}
                  className="btn btn-outline-primary float-end mt-0 mr-0"
                >
                  Back
                </button>
              </h3>
              <hr></hr>
              <div className="row mt-2 col-md-12">
                <div className="col-md-6">
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="name">Post By</label>
                    </div>
                    <div className="col-md-6">{trade.buyerName}</div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="name">Trade By</label>
                    </div>
                    <div className="col-md-6">{trade.sellerName}</div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="name">Traded At</label>
                    </div>
                    <div className="col-md-6">
                      {moment(trade.created_date).format("DD-MM-YYYY k:mm:s")}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="email">Post Type</label>
                    </div>
                    <div className="col-md-6">{trade.BuyorSell}</div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="email">Currency Pair</label>
                    </div>
                    <div className="col-md-6">
                      {trade.firstCurrency} / {trade.secondCurrency}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="email">Fiat Amount</label>
                    </div>
                    <div className="col-md-6">
                      {trade.fiat_amount} {trade.secondCurrency}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="email">Crypto Amount</label>
                    </div>
                    <div className="col-md-6">
                      {trade.crypto_amount} {trade.firstCurrency}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="email">Trade Status</label>
                    </div>
                    <div className="col-md-6">{trade.status}</div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="email">Dispute Status</label>
                    </div>
                    <div className="col-md-6">
                      {trade.status == "Disputed" ? "Yes" : "No"}
                    </div>
                  </div>

                  {trade.status == "Disputed" && (
                    <div className="row mt-1">
                      <div className="col-md-6">
                        <button
                          title="View Message"
                          className="btn btn-primary btn-sm"
                          onClick={() => this.p2papprove_buyer(trade)}
                          style={{ marginRight: "5px" }}
                          disabled={p2pbuyerbtn}
                        >
                          Resolve to Buyer
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          title="View Message"
                          className="btn btn-primary btn-sm"
                          onClick={() => this.p2papprove_seller(trade)}
                          style={{ marginRight: "5px" }}
                          disabled={p2psellerbtn}
                        >
                          Resolve to Seller
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="chat_box_dark">
                    <div>
                      <div className="chat_message_section">
                        {chats.length > 0 &&
                          chats.map((item, i) => {
                            var imagepath =
                              url + "images/chat/" + item.attachment;
                            var name = item.admin
                              ? "Admin"
                              : item.senderdetails.email;
                            return (
                              <div
                                className={
                                  item.admin
                                    ? "message_section message_send"
                                    : "message_section messAge_recive"
                                }
                                key={i}
                              >
                                <div>
                                  <p>
                                    {item.message}&nbsp;&nbsp;
                                    {item.attachment != "" ? (
                                      <>
                                        <a href={imagepath} target="_blank">
                                          <i className="fa fa-paperclip fa-lg"></i>
                                        </a>
                                      </>
                                    ) : (
                                      ""
                                    )}&nbsp;&nbsp;
                                    <span>{name}</span>&nbsp;&nbsp;
                                    <span className="text-end">
                                      <TimeAgo date={new Date(item.created_at)}>
                                        {({ value }) => value}
                                      </TimeAgo>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        <div ref={this.chatsEndRef} />
                      </div>

                      {trade.status == "Disputed" && (
                        <div className="footer_chat">
                          <div className="form-group">
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="write message..."
                                name="message"
                                value={chatMessage}
                                onChange={this.handleMessageChange.bind(this)}
                              />
                              <div className="file-field">
                                <a className="btn-floating mt-0">
                                  <label htmlFor="file-input">
                                    <i
                                      className="fa fa-paperclip fa-lg"
                                      aria-hidden="true"
                                    ></i>
                                  </label>
                                  <input
                                    type="file"
                                    // className="hide"
                                    name="proofImage"
                                    id="file-input"
                                    onChange={this.handleFile.bind(this)}
                                  />
                                </a>
                                {/* {this.state.proofImage&&this.state.proofImage.name} */}
                              </div>
                              <i
                                className="fa fa-paper-plane fa-lg"
                                onClick={this.handleSend.bind(this)}
                              ></i>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

export default p2ptradeview;