import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import jsPDF from "jspdf";
import moment from "moment";
import { Form } from "react-bootstrap";

//import components
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//import actions
import { getTdsTaxList } from "../../actions/tdsAction";

// import library
import { firstLetterCase } from "../../lib/capitalize";

import keys from "../../config";

class TdsTaxList extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "createdAt",
                text: "Date",
                className: "Date",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
                },
            },
            {
                key: "tableId",
                text: "Table Id",
                className: "tableId",
                align: "left",
                sortable: true,
            },
            {
                key: "userId",
                text: "User Name",
                className: "name",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.userId && record.userId.name ? record.userId.name:"-";
                }
            },
            {
                key: "userId",
                text: "User Email",
                className: "email",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.userId && record.userId.email ? record.userId.email:"-";
                }
            },
            {
                key: "tax_amount",
                text: "TDS Amount",
                className: "tax_amount",
                align: "left",
                sortable: true,
                cell: (record) => {
                  return record.tax_amount ? parseFloat(parseFloat(record.tax_amount).toFixed(8)):"-";
                },
            },
            {
                key: "buyorsell",
                text: "Type",
                className: "buyorsell",
                align: "left",
                sortable: true,
            },
            {
                key: "ConvertPrice",
                text: "Price (INR)",
                className: "ConvertPrice",
                align: "left",
                sortable: true,
                cell: (record) => {
                  return record.ConvertPrice ? parseFloat(parseFloat(record.ConvertPrice).toFixed(2)):"-";
                },
            },
            {
                key: "priceOnInr",
                text: "Fee (INR)",
                className: "priceOnInr",
                align: "left",
                sortable: true,
                cell: (record) => {
                  return record.priceOnInr ? parseFloat(parseFloat(record.priceOnInr).toFixed(2)):"-";
                },
            },
            {
                key: "pair",
                text: "Trade Pair",
                className: "pair",
                align: "left",
                sortable: true,
            },
            {
                key: "type",
                text: "Trade Type",
                className: "type",
                align: "left",
                sortable: true,
            },
            {
                key: "tax_percent",
                text: "TDS %",
                className: "tax_percent",
                align: "left",
                sortable: true,
            },
        ];

        this.state = {
            records: [],
            totalCount: 0,
            offset: 0,
            pageSize: 10,
            sortOrder: {
                column: "createdAt",
                order: "desc",
            },
            loading: true,
            exportType: "current",
            extraButtons: [{
                className: "btn btn-primary buttons-pdf",
                title: "Export PDF",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
                    </span>,
                ],
                onClick: (event) => {
                    this.exportPDF();
                },
            },
            {
                className: "btn btn-primary buttons-csv",
                title: "Export CSV",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    this.exportCSV();
                },
            }]
        };

        this.config = {
            page_size: this.state.pageSize,
            sort: this.state.sortOrder,
            length_menu: [10, 20, 50],
            no_data_text: "No Records found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            key_column: "_id",
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            pagination: "advance",
            show_info: true,
            button: {
                extra: true,
            },
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    async getData(filter = "") {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                sortOrder: this.state.sortOrder,
                filter,
                limit: this.state.pageSize,
                offset: this.state.offset
            };
            const { status, result, totalCount } = await getTdsTaxList(data);
            if (status == "success") {
                this.setState({ loading: false });
                this.setState({ records: [], totalCount: 0 }, function() {
                    this.setState({ totalCount, records: result });
                });
            }
        } catch (err) {}
    }

    search = data => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number-1)*pageSize;
        let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
            this.getData(filter);
        }); 
    }

    exportCSV = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await getTdsTaxList(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];
        let separator = ";";
        let headers = [
            "Date",
            "Table Id",
            "User Name",
            "User Email",
            "TDS Amount",
            "Type",
            "Price (INR)",
            "Fee (INR)",
            "Trade Pair",
            "Trade Type",
            "TDS %",
        ];

        let rows = [];
        records.length > 0 && records.map((elt) => rows.push({
            "Date": moment(elt.createdAt).format("DD-MM-YYYY HH:mm:ss"),
            "Table Id": elt.tableId,
            "User Name": elt.userId && elt.userId.name ? elt.userId.name:"-",
            "User Email": elt.userId && elt.userId.email ? elt.userId.email:"-",
            "TDS Amount": elt.tax_amount ? parseFloat(parseFloat(elt.tax_amount).toFixed(8)):"-",
            "Type": elt.buyorsell,
            "Price (INR)": elt.ConvertPrice ? parseFloat(parseFloat(elt.ConvertPrice).toFixed(8)):"-",
            "Fee (INR)": elt.priceOnInr ? parseFloat(parseFloat(elt.priceOnInr).toFixed(8)):"-",
            "Trade Pair": elt.pair,
            "Trade Type": elt.type,
            "TDS %": elt.tax_percent,
        }));

        let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
            headers.map((k) => {
                let cell = row[k] === null || row[k] === undefined ? "" : row[k];

                cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator)
        ).join("\n")}`;

        let title = "TDS Tax List";
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // In case of IE 10+
            navigator.msSaveBlob(blob, title);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", title);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    async exportPDF() {
        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await getTdsTaxList(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];

        let unit = "pt";
        let size = "A4"; // Use A1, A2, A3 or A4
        let orientation = "landscape"; // portrait or landscape

        let marginLeft = 40;
        let doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);

        let title = "TDS Tax List";
        let headers = [
            [
                "Date",
                "Table Id",
                "User Name",
                "User Email",
                "TDS Amount",
                "Type",
                "Price (INR)",
                "Fee (INR)",
                "Trade Pair",
                "Trade Type",
                "TDS %",
            ],
        ];

        let downloadData = records.length > 0 && records.map((elt) => [
            moment(elt.createdAt).format("DD-MM-YYYY HH:mm:ss"),
            elt.tableId,
            elt.userId && elt.userId.name ? elt.userId.name:"-",
            elt.userId && elt.userId.email ? elt.userId.email:"-",
            elt.tax_amount ? parseFloat(parseFloat(elt.tax_amount).toFixed(8)):"-",
            elt.buyorsell,
            elt.ConvertPrice ? parseFloat(parseFloat(elt.ConvertPrice).toFixed(8)):"-",
            elt.priceOnInr ? parseFloat(parseFloat(elt.priceOnInr).toFixed(8)):"-",
            elt.pair,
            elt.type,
            elt.tax_percent,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: downloadData,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("TDS Tax List.pdf");
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <div className="float-xl-right">
                                Export
                                {["current", "all"].map((v) => (
                                    <Form.Check
                                        inline
                                        type="radio"
                                        name="exportType"
                                        value={v}
                                        key={v}
                                        onChange={(e) => this.setState({ exportType: e.target.value })}
                                        defaultChecked={v == this.state.exportType}
                                        label={firstLetterCase(v)}
                                    />
                                ))}
                            </div>
                            <h3 className="mt-2 text-secondary">
                                TDS Tax List
                            </h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                loading={this.state.loading}
                                onChange={this.search}
                                extraButtons={this.state.extraButtons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TdsTaxList;
