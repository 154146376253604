import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateProfile } from "../../actions/userActions";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { withRouter } from "react-router-dom";
const url = keys.baseUrl;
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      email: "",
      phonenumber: "",
      profile: "",
      profileurl: "",
      errors: {},
      showeditbutton: 0,
      showemailchangeinputbox: 0,
      newemail: "",
      nameerr: "",
      showbutton: 0,
      oldemailotp: 0,
      newemailotp: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.profile !== undefined &&
      nextProps.auth.profile.data !== undefined &&
      nextProps.auth.profile.data.message !== undefined
    ) {
      toast(nextProps.auth.profile.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.profile = undefined;
    }
  }

  getData() {
    // console.log(this.props.auth);
    axios
      .get(url + "adminapi/getadmindetail")
      .then((res) => {
        console.log("resresresresresres", res);
        let value = res.data.result;
        console.log("valuevaluevaluevaluevalue", value);
        //   if (isEmpty(value.mobileno)) {
        //     this.setState({
        //       showeditbutton: 1,
        //     });
        //   }
        this.setState({
          name: value.name,
          email: value.email,
          phonenumber: value.mobileno,
        });
      })
      .catch((err) => console.log("errerrerrerrerrerrerrerr", err));
    console.log(this.props.auth, "auth");
  }

  handleChange = (event) => {
    this.setState({
      profileurl: URL.createObjectURL(event.target.files[0]),
      profile: event.target.files[0],
    });
    //   const data = new FormData()
    //   data.append('file', this.state.profile)
    //   axios.post(url+"api/profileupload", data, { // receive two parameter endpoint url ,form data
    //   })
    //   .then(res => { // then print response status
    //     console.log(res.statusText)
    //   })
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onProfileUpdate = (e) => {
    e.preventDefault();
    const updateProfile = {
      _id: this.state.id,
      name: this.state.name,
      email: this.state.email,
      phonenumber: this.state.phonenumber,
      profile: this.state.profile,
    };
    const data = new FormData();
    data.append("name", this.state.name);
    data.append("email", this.state.email);
    data.append("phonenumber", this.state.phonenumber);
    data.append("file", this.state.profile);
    data.append("_id", this.state.id);
    this.props.updateProfile(updateProfile);
    // axios.post(url+"api/profileupload", data, { // receive two parameter endpoint url ,form data
    // })
    // .then(res => { // then print response status
    //     console.log(res.statusText)
    // })
  };

  //   onEmailupdate = (e) => {
  //     e.preventDefault();
  //     const updateemail = {
  //       _id: this.state.id,
  //       newemail: this.state.newemail,
  //       email: this.state.email,
  //     };
  //     let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  //     if (this.state.newemail == "") {
  //       toast("Please enter new Email", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       return;
  //     } else {
  //       if (!emailRegex.test(this.state.newemail)) {
  //         toast("Invalid Email", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         return;
  //       }
  //     }
  //     // this.props.onEmailupdateaction(updateemail);
  //     axios
  //       .post(url + "adminapi/onEmailupdate", updateemail)
  //       .then((res) => {
  //         if (res.data.success) {
  //           toast(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //           console.log("res.data", res.data.showbutton);
  //           this.setState({ showbutton: res.data.showbutton });
  //         } else {
  //           toast(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         }
  //       })
  //       .catch((err) => console.log("onEmailupdate", err));
  //     this.getData();
  //   };
  //   handleClick(e) {
  //     e.preventDefault();
  //     console.log(
  //       "this.state.newemailotpthis.state.newemailotp",
  //       isNaN(this.state.newemailotp)
  //     );

  //     if (isNaN(this.state.oldemailotp)) {
  //       toast("Enter Number", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       return;
  //     } else {
  //       if (this.state.oldemailotp.length < 6) {
  //         toast("Invalid Otp", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         return;
  //       }
  //     }
  //     const updateemail = {
  //       _id: this.state.id,
  //       newemail: this.state.newemail,
  //       email: this.state.email,
  //       oldemailotp: this.state.oldemailotp,
  //     };
  //     axios
  //       .post(url + "adminapi/otpsendtonewemail", updateemail)
  //       .then((res) => {
  //         if (res.data.success) {
  //           toast(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //           console.log("res.data", res.data.showbutton);
  //           this.setState({ showbutton: res.data.showbutton });
  //         } else {
  //           toast(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         }
  //       })
  //       .catch((err) => console.log("onEmailupdate", err));
  //   }

  //   handleClicknewemailotp(e) {
  //     e.preventDefault();
  //     console.log(
  //       "this.state.newemailotpthis.state.newemailotp",
  //       isNaN(this.state.newemailotp)
  //     );
  //     if (isNaN(this.state.newemailotp)) {
  //       toast("Enter a Number", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       return;
  //     } else {
  //       if (this.state.newemailotp.length < 6) {
  //         toast("Invalid Otp", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         return;
  //       }
  //     }
  //     const updateemail = {
  //       _id: this.state.id,
  //       newemail: this.state.newemail,
  //       email: this.state.email,
  //       newemailotp: this.state.newemailotp,
  //     };
  //     axios
  //       .post(url + "adminapi/updateadminemail", updateemail)
  //       .then((res) => {
  //         if (res.data.success) {
  //           toast(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //           console.log("res.data", res.data.showbutton);
  //           this.setState({ showbutton: res.data.showbutton });
  //           window.location.reload();
  //         } else {
  //           toast(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         }
  //       })
  //       .catch((err) => console.log("onEmailupdate", err));
  //   }

  render() {
    const { errors } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Edit Profile</h3>
              <form
                noValidate
                onSubmit={this.onProfileUpdate}
                id="update-profile"
              >
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Name</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      onChange={this.onChange}
                      value={this.state.name}
                      id="name"
                      type="text"
                      error={errors.name}
                      className={classnames("form-control", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="col-md-6">
                    {this.state.email}
                    <span className="text-danger">{errors.email}</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="phonenumber">Phone Number</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      onChange={this.onChange}
                      value={this.state.phonenumber}
                      id="phonenumber"
                      type="text"
                      error={errors.phonenumber}
                      className={classnames("form-control", {
                        invalid: errors.phonenumber,
                      })}
                    />
                    <span className="text-danger">{errors.phonenumber}</span>
                  </div>
                </div>

                {/*}<div className="row mt-2">
                                        <div className="col-md-3">
                                        <br/>
                                        <label htmlFor="profile">Profile Image</label>
                                        </div>
                                        <div className="col-md-6">
                                        <input type="file" onChange={this.handleChange}
                                        />
                                        <img width="100px" src={this.state.profileurl} />

                                        </div>
                                    </div>
                                    */}
              </form>
              <br />
              <button
                form="update-profile"
                type="submit"
                className="btn btn-primary"
              >
                Update Profile
              </button>
            </div>
            {/* <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Change Email</h3>
              <form noValidate onSubmit={this.onEmailupdate} id="email-change">
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="email">Current Email</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      onChange={this.onChange}
                      value={this.state.email}
                      id="email"
                      type="text"
                      // error={errors.email}
                      className={classnames("form-control")}
                      disabled
                    />

                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="phonenumber">New Email</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      onChange={this.onChange}
                      value={this.state.newemail}
                      id="newemail"
                      type="text"
                      disabled={this.state.showbutton == 0 ? false : true}
                      className={classnames("form-control")}
                    />
                  </div>
                </div>
                {this.state.showbutton == 1 && (
                  <>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="phonenumber">Current Email OTP</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onChange={this.onChange}
                          value={this.state.oldemailotp}
                          id="oldemailotp"
                          type="number"
                          className={classnames("form-control")}
                        />
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => this.handleClick(e)}
                    >
                      Submit
                    </button>
                  </>
                )}

                {this.state.showbutton == 2 && (
                  <>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="phonenumber">New Email OTP</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onChange={this.onChange}
                          value={this.state.newemailotp}
                          id="newemailotp"
                          type="number"
                          className={classnames("form-control")}
                        />
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => this.handleClicknewemailotp(e)}
                    >
                      Update Email
                    </button>
                  </>
                )}
              </form>
              <br />
              {this.state.showbutton == 0 && (
                <button
                  form="email-change"
                  type="submit"
                  className="btn btn-primary"
                >
                  Change Email
                </button>
              )}
            </div> */}
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateProfile })(withRouter(Profile));
