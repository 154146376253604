import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";

import DatePicker from "react-datepicker";

// import action
// import { approveUserKyc, rejectUserKyc } from '../../actions/userKycAction';

// import lib
import { toastAlert } from "../../lib/toastAlert";
import { getUserTDS, UpdateUserTDS } from "../../actions/tdsAction";

const initialFormValue = {
    citizen: "",
    endfy: "",
    startfy: "",
    tdsproof: "",
    tds_status: "",
    email: "",
    name: "",
    id: "",
    reason: "",
};

class UserTDSUpdate extends React.Component {
    constructor(props) {
        super();
        this.state = {
            formValue: initialFormValue,
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record) {
            this.setState({ formValue: record });
        }
    }

    handleChange = (e) => {
        // e.preventDefault();
        let { name, value } = e.target;
        console.log(name, value, "11111111111111d");
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
    };

    // getYear = (date) =>{
    //     let d = new Date(date);
    //     let year = d.getFullYear();
    //     return year;
    // }

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ formValue: initialFormValue, errors: {} });
    };

    handleApprove = async (e) => {
        e.preventDefault();
        try {
            const { fetchData } = this.props;
            const { formValue } = this.state;

            let reqData = formValue;
            const { status, loading, message, error } = await UpdateUserTDS(
                reqData
            );
            if (status == "success") {
                fetchData();
                toastAlert("success", "Updated Successfully", "addTemplate");
                this.handleClose();
                this.props.history.push("/usertdslist");
            } else {
                this.setState({ errors: error });
                toastAlert("error", message, "addTemplate");
            }
        } catch (err) {}
    };

    render() {
        const { errors, formValue } = this.state;
        const {
            citizen,
            tdsproof,
            endfy,
            startfy,
            tds_status,
            email,
            firstName,
            lastName,
            id,
            reason,
            name,
        } = this.state.formValue;
        const { isShow } = this.props;
        console.log(
            "----11111---formValue",
            citizen,
            endfy,
            startfy,
            tdsproof,
            tds_status,
            email,
            name,
            id
        );

        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                    scrollable={true}
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">FDS Document</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form noValidate>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="email">Email-ID</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={email}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Name</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        name="email"
                                        id="name"
                                        value={name}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div>
                                        <label htmlFor="name">
                                            e-Filing(TDS) Image
                                        </label>
                                    </div>
                                    <a href={tdsproof} target="_blank">
                                        {" "}
                                        <img
                                            src={tdsproof}
                                            width="100%"
                                            height="250"
                                        />
                                    </a>
                                </div>
                            </div>
                            {startfy && (
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Start FY</label>
                                    </div>
                                    <div className="col-md-9">
                                        <DatePicker
                                            selected={new Date(startfy)}
                                            name="startfy"
                                            onChange={this.handleChange}
                                            showYearPicker
                                            dateFormat="yyyy"
                                        />
                                        <span className="text-danger">
                                            {errors.startfy}
                                        </span>
                                    </div>
                                </div>
                            )}
                            {endfy && (
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">End FY</label>
                                    </div>
                                    <div className="col-md-9">
                                        <DatePicker
                                            selected={new Date(endfy)}
                                            name="endfy"
                                            onChange={this.handleChange}
                                            showYearPicker
                                            dateFormat="yyyy"
                                        />
                                        <span className="text-danger">
                                            {errors.endfy}
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="verfication">
                                        Doc Verification
                                    </label>
                                    <span className="text-danger">*</span>
                                </div>

                                <div className="col-md-9">
                                    <div class="form-check">
                                        <input
                                            id="status1"
                                            value="1"
                                            name="tds_status"
                                            type="radio"
                                            onChange={this.handleChange}
                                            checked={
                                                tds_status == "1" ? true : false
                                            }
                                        />
                                        <label
                                            class="form-check-label"
                                            for="status1"
                                        >
                                            Approved
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input
                                            id="status2"
                                            value="0"
                                            name="tds_status"
                                            type="radio"
                                            onChange={this.handleChange}
                                            checked={
                                                tds_status == "0" ? true : false
                                            }
                                        />
                                        <label
                                            class="form-check-label"
                                            for="status2"
                                        >
                                            Rejected
                                        </label>
                                    </div>
                                    <span className="text-danger">
                                        {errors.tds_status}
                                    </span>
                                </div>
                            </div>
                            {tds_status == "0" && (
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="reason1">Reason</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            name="reason"
                                            id="reason1"
                                            value={reason}
                                            onChange={this.handleChange}
                                        />
                                        <span className="text-danger">
                                            {errors.reason}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.handleApprove}
                            >
                                Submit
                            </button>
                        </>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default UserTDSUpdate;