// import lib
import axios from "../config/axios";

let botSource;

export const openOrderBot = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/orderBot/open`,
            params: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const newBot = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/newBot`,
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const botList = async (data = {}) => {
    try {

        if (botSource) botSource.cancel();
        botSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/botList`,
            data,
            cancelToken: botSource.token,
        });

        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const removeBot = async (id) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/removeBot/${id}`,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const changeBotStatus = async (id) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/changeBotStatus/${id}`,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const botOrderCancel = async (id) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/bot-order-cancel/${id}`,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getBotUser = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/botUser`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const newBotUser = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/botUser`,
            data: data,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};