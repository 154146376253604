import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firstLetterCase } from "../../lib/capitalize";
import { jsPDF } from "jspdf";
//import compoents
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import P2PPairAddModal from "../partials/P2PPairAddModal";
import P2PPairUpdate from "../partials/P2PPairUpdate";
import { Form } from "react-bootstrap";
//import action
import { P2PPairList } from "../../actions/tradePairAction";
import { currencyListSelect } from "../../actions/currency";

class Pairmanagement extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "first_currency",
                text: "Base Currency",
                className: "firstCurrencySymbol",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "second_currency",
                text: "Quote Currency",
                className: "secondCurrencySymbol",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "transactionfee",
                text: "Transaction Fee",
                className: "Transaction Fee",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
                width: 200,
                cell: (record) => {
                    if (record.status == 0) var type = "Active";
                    else var type = "Deactive";
                    return <span>{type}</span>;
                },
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-spot-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Pairmanagement",
            no_data_text: "No Contracts found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },

            key_column: "_id",
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            pagination: "advance",
            show_info: true,
            button: {
                extra: true,
              },
        };

        this.state = {
            records: [],
            addFormModal: false,
            editFormModal: false,
            currencyOptions: [],
            editRecord: {},
            loader: true,
            totalCount: 0,
            offset: 0,
            pageSize: 10,
            sortOrder: {
              column: "_id",
              order: "desc",
            },
            search: "",
            exportType: "current",     
            extraButtons: [
                {
                  className: "btn btn-primary buttons-pdf",
                  title: "Export PDF",
                  children: [
                    <span>
                      <i
                        className="glyphicon glyphicon-print fa fa-file-pdf-o"
                        aria-hidden="true"
                      ></i>
                    </span>,
                  ],
                  onClick: (event) => {
                    this.exportPDF();
                  },
                },
                {
                  className: "btn btn-primary buttons-csv",
                  title: "Export CSV",
                  children: [
                    <span>
                      <i
                        className="glyphicon glyphicon-print fa fa-file-excel-o"
                        aria-hidden="true"
                      ></i>
                    </span>,
                  ],
                  onClick: (event) => {
                    this.exportCSV();
                  },
                },
              ],
        };

        this.getP2PPairData = this.getP2PPairData.bind(this);
        this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
        this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.getCurrencyData = this.getCurrencyData.bind(this);
        this.refetch = this.refetch.bind(this);
    }


   

      
    componentDidMount() {
        this.getP2PPairData();
        this.getCurrencyData();
    }

    refetch() {
        this.getP2PPairData();
    }

    async getP2PPairData(filter = "") {
        try {
            let data = {
         sortOrder: this.state.sortOrder,
      filter,
      limit: this.state.pageSize,
      offset: this.state.offset,
            };
            this.setState({ loader: true });
            const { status, loading, result ,totalCount} = await P2PPairList(data);
            this.setState({ loader: loading });
            if (status == "success") {
                this.setState({ totalCount: result.totalCount, records: result.data });
            }
        } catch (err) {}
    }

    async getCurrencyData() {
        const { result, status } = await currencyListSelect();
        if (status == "success") {
            var currencyarray = [];

            result.data.map((item, i) => {
                const id = item._id;
                const label = item.currencySymbol;
                const type = item.type;
                const obj = { value: id, label: label, type: type };
                currencyarray.push(obj);
                this.setState({ currencyOptions: currencyarray });
            });
        }
    }

    editRecord(record) {
        this.setState({
            editFormModal: true,
            editRecord: record,
        });
    }
    addRecord() {
        this.setState({ addFormModal: true });
    }

    handleCloseAddForm() {
        this.setState({ addFormModal: false });
    }

    handleCloseEditForm() {
        this.setState({ editFormModal: false, editRecord: {} });
    }

    handlePagination(data) {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number-1)*pageSize;
        let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
          this.getP2PPairData(filter);
        });   
    }

    async exportPDF() {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let data = {
          sortOrder,
          filter,
          limit: exportType == "current" ? pageSize:totalCount,
          offset: exportType == "current" ? offset:0,
          download: 1
        };
    
        this.setState((prevState) => {
          let extraButtons = [...prevState.extraButtons];
          extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
          return { extraButtons };
        });
    
    
        let { status, result } = await P2PPairList(data);
    
        this.setState((prevState) => {
          let extraButtons = [...prevState.extraButtons];
          extraButtons[0].children = [
            <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
          ];
          return { extraButtons };
        });
    
        let records = status ? result: [];
    
        let unit = "pt";
        let size = "A4"; // Use A1, A2, A3 or A4
        let orientation = "landscape"; // portrait or landscape
    
        let marginLeft = 40;
        let doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(13);
   
        let title = "P2P Pairs";
        let headers = [[
          "Base Currency",
        "Quote Currency",
        "Transaction Fee",
        "Status"
        ]];
    
        let downloadData = records&& records.data.length > 0 && records.data.map((elt) => [
          elt.first_currency,
          elt.second_currency,
          elt.transactionfee ,
          elt.status==1?"Deactive":"Active"
        ]);
    
    

        let content = {
          startY: 50,
          head: headers,
          body: downloadData,
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("P2P_Pair.pdf");
      }
      exportCSV = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let data = {
          sortOrder,
          filter,
          limit: exportType == "current" ? pageSize:totalCount,
          offset: exportType == "current" ? offset:0,
          download: 1
        };
    
        this.setState((prevState) => {
          let extraButtons = [...prevState.extraButtons];
          extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
          return { extraButtons };
        });
    
        let { status, result } = await P2PPairList(data);
    
        this.setState((prevState) => {
          let extraButtons = [...prevState.extraButtons];
          extraButtons[1].children = [
            <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
          ];
          return { extraButtons };
        });
    
        let records = status ? result: [];
        let separator = ";";
        let headers = [
          "Base Currency",
          "Quote Currency",
          "Transaction Fee",
          "Status"
        ];
    
        let rows = [];
        records&& records.data.length > 0 && records.data.map((elt) => rows.push({
          "Base Currency": elt.first_currency,
          "Quote Currency": elt.second_currency,
          "Transaction Fee": elt.transactionfee ? elt.transactionfee : "-",
          "Status": elt.status==1?"Deactive":"Active"
        }));
    
        let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
          headers.map((k) => {
            let cell = row[k] === null || row[k] === undefined ? "" : row[k];
    
            cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');
    
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          }).join(separator)
        ).join("\n")}`;
    
        let title = "Currency";
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // In case of IE 10+
          navigator.msSaveBlob(blob, title);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", title);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      };

    render() {
        const {
            addFormModal,
            editRecord,
            editFormModal,
            totalCount,
            loader,
            currencyOptions,
        } = this.state;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey} />
                    <P2PPairAddModal
                        isShow={addFormModal}
                        onHide={this.handleCloseAddForm}
                        currencyOptions={currencyOptions}
                        fetchData={this.refetch}
                    />
                    <P2PPairUpdate
                        isShow={editFormModal}
                        onHide={this.handleCloseEditForm}
                        currencyOptions={currencyOptions}
                        fetchData={this.refetch}
                        record={editRecord}
                    />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button
                                onClick={() => this.addRecord()}
                                className="btn btn-outline-primary float-end mt-3 mr-2"
                                data-toggle="modal"
                                data-target="#add-spot-modal"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Add Pair Page
                            </button>
                            <div className="float-xl-right">
                Export
                {["current", "all"].map((v) => (
                  <Form.Check
                    inline
                    type="radio"
                    name="exportType"
                    value={v}
                    key={v}
                    onChange={(e) =>
                      this.setState({ exportType: e.target.value })
                    }
                    defaultChecked={v == this.state.exportType}
                    label={firstLetterCase(v)}
                  />
                ))}
              </div>
                            <h3 className="mt-2 text-secondary">
                                P2P Trade Pairs
                            </h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                total_record={totalCount}
                                columns={this.columns}
                                loading={loader}
                                dynamic={true}
                                onChange={this.handlePagination}
                                extraButtons={this.state.extraButtons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pairmanagement;