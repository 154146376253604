import axios from "../config/axios";

let ticketSource;

export const categoryAdd = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/supportCategory`,
            data: data,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.result.messages,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const categoryList = async (data = {}) => {
    try {
        let respData = await axios({
            method: "patch",
            url: `/adminapi/supportCategory`,
            data,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};

export const categoryUpdate = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/adminapi/supportCategory`,
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.result.messages,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const TicketList = async (data = {}) => {
    try {

        if (ticketSource) ticketSource.cancel();
        ticketSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/ticketList`,
            data,
            cancelToken: ticketSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};
export const getMessage = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/ticketMessage`,
            params: data,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};

export const replyMsg = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/adminApi/ticketMessage`,
            data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.result.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};