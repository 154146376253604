import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import  keys  from "../../actions/config";
import {Modal,Button} from 'react-bootstrap/';
import * as moment from "moment";

const url = keys.baseUrl;
class Users extends Component {

    constructor(props) {
        super(props);


        this.columns = [
        {
                key: "created_at",
                text: "Date & Time",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                   
                    const createddate = moment(record.created_at).format(
                        "DD-MM-YYYY  k:mm:s "
                      );
                      return createddate;
                }
                
            },  
           
              {
                key: "Sender_userId",
                text: "Sender",
                className: "userId",
                align: "left",
                sortable: true,
                cell: record => {
                    return record.senderdetails.email;
                }
              
              },
              
              {
                key: "Receiver_userId",
                text: "Receiver",
                className: "userId",
                align: "left",
                sortable: true,
                cell: record => {
                    return record.receiverdetails.email;
                }
              
              },

              {
                key: "message",
                text: "Message",
                className: "userId",
                align: "left",
                sortable: true,
              
              },

              {
                key: "attachment",
                text: "Attachment",
                className: "userId",
                align: "left",
                sortable: true,
                cell: record => {
                    console.log("Url---",url)
                    var imagepath=url+"images/chat/"+record.attachment
                    return (
                        
                        <Fragment>
{  
record.attachment?
<a href={imagepath} target="_blank" className="btn btn-primary btn-sm">
                              View Attachment
                           </a> :"---"
                           }
                           </Fragment>
                    );
              
              },

              }
              
          
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Buyhistory",
            no_data_text: 'No Record found!',
            sort:{column: "Created date", order: "desc"},
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
            defaultSortAsc: true,
        };

        this.state = {
            currentRecord: {
                records: [],
                responsive: true,
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
     //   console.log(this.state,'statezzzzzz');
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        const id = this.props.match.params.id;
        let postdata={'orderbookId':id}
        axios
            .post(url+"api/chat-data",postdata)
            .then(res => {
                this.setState({ records: res.data.data})
             console.log(res,'reszzzzhhhhhh');
            })
            .catch()
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }


    viewrecord(record) {
        this.setState({ currentRecord: record});
        this.props.history.push("/p2ptradeview/" + record._id);

    }



    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            
                            <h3 className="mt-2 text-secondary">Chat History</h3>
                            <ReactDatatable
                                responsive={this.state.responsive}
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            
            </div>

        );
    }

}

Users.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Users);
