// import config
import axios from "../config/axios";

let kycSource;

export const getAllUserKyc = async (data = {}) => {
    try {
        if (kycSource) kycSource.cancel();
        kycSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/userKyc`,
            method: "patch",
            data,
            cancelToken: kycSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const approveUserKyc = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/userKyc`,
            method: "post",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const rejectUserKyc = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/userKyc`,
            method: "put",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const updateCitizen = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/updateCitizen`,
            method: "post",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};