import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import SubscriptionModal from "../partials/SubscriptionModal";

// import action
import {
    getBadges,
    getSubscriptions,
    subscriptionDelete,
} from "../../actions/copyTradeAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import { firstLetterCase } from "../../lib/capitalize";
import isEmpty from "../../lib/isEmpty";

class Subscription extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "createdAt",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
                },
            },
            {
                key: "type",
                text: "Type",
                className: "type",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return firstLetterCase(record.type);
                },
            },
            {
                key: "count",
                text: "Count",
                className: "count",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return !isEmpty(record.count) ? record.count : "-";
                },
            },
            {
                key: "price",
                text: "Price ($)",
                className: "price",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return !isEmpty(record.price) ? record.price : "-";
                },
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.status ? "Active" : "Inactive";
                },
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.deleteRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];

        this.state = {
            totalCount: 0,
            records: [],
            offset: 0,
            pageSize: 10,
            sortOrder: {
                column: "createdAt",
                order: "desc"
            },
            loading: true,
            exportType: "current",
            extraButtons: [{
                className: "btn btn-primary buttons-pdf",
                title: "Export PDF",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
                    </span>,
                ],
                onClick: (event) => {
                    this.exportPDF();
                },
            },
            {
                className: "btn btn-primary buttons-csv",
                title: "Export CSV",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    this.exportCSV();
                },
            }],
            badges: [],
            formModal: false,
            currentRecord: "",
        };

        this.config = {
            page_size: this.state.pageSize,
            sort: this.state.sortOrder,
            length_menu: [10, 20, 50],
            no_data_text: "No subscription found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            key_column: "_id",
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            pagination: "advance",
            show_info: true,
            button: {
                extra: true,
            }
        };

        this.getData = this.getData.bind(this);
        this.handleCloseForm = this.handleCloseForm.bind(this);
    }

    componentDidMount() {
        this.getData();
        this.fetchBadges();
    }

    async fetchBadges() {
        try {
            this.setState({ loading: true });
            const { status, loading, result } = await getBadges({ user: 1 });
            this.setState({ loading });
            if (status == "success") {
                let badgeNames = await result.map((res) => {
                    return { [res._id]: res.name };
                });
                this.setState({ badges: badgeNames });
            }
        } catch (err) {}
    }

    getData = async (filter = "") => {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                sortOrder: this.state.sortOrder,
                filter,
                limit: this.state.pageSize,
                offset: this.state.offset,
            };
            this.setState({ loading: true });
            const { status, result, totalCount } = await getSubscriptions(data);
            if (status == "success") {
                this.setState({ loading: false });
                this.setState({ totalCount, records: result });
            }
        } catch (err) {}
    }

    addRecord() {
        this.setState({ formModal: true });
    }

    editRecord(record) {
        this.setState({
            formModal: true,
            currentRecord: record,
        });
    }

    async deleteRecord(record) {
        if (window.confirm("Are you sure?")) {
            let { _id } = record;
            const { status, loading, message, error } =
                await subscriptionDelete(_id);
            if (status == "success") {
                this.getData();
                toastAlert("success", message);
            } else {
                toastAlert("error", message);
            }
        }
    }

    handleCloseForm() {
        this.setState({ formModal: false, currentRecord: "" });
    }

    search = (data) => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number - 1) * pageSize;
        let sortOrder = data.sort_order ? data.sort_order : this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter }, function () {
            this.getData(filter);
        });
    };

    exportCSV = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1,
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });

        let { status, result } = await getSubscriptions(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];
        let separator = ";";

        let headers = [
            "Date",
            "Type",
            "Count",
            "Price",
            "Status",
        ];

        let rows = [];
        records.length > 0 && records.map((elt) => rows.push({
            "Date": moment(elt.createdAt).format("DD-MM-YYYY HH:mm:ss"),
            "Type": firstLetterCase(elt.type),
            "Count": !isEmpty(elt.count) ? elt.count : "-",
            "Price": !isEmpty(elt.price) ? elt.price : "-",
            "Status": elt.status ? "Active":"Inactive",
        }));

        let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
            headers.map((k) => {
                let cell = row[k] === null || row[k] === undefined ? "" : row[k];

                cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator)
        ).join("\n")}`;

        let title = "Subscriptions";
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // In case of IE 10+
            navigator.msSaveBlob(blob, title);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", title);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    exportPDF = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1,
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });

        let { status, result } = await getSubscriptions(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];

        let unit = "pt";
        let size = "A4"; // Use A1, A2, A3 or A4
        let orientation = "landscape"; // portrait or landscape

        let marginLeft = 40;
        let doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);

        let title = "Subscriptions";
        let headers = [[
            "Date",
            "Type",
            "Count",
            "Price",
            "Status",
        ]];

        let downloadData = records.length > 0 && records.map((elt) => [
            moment(elt.createdAt).format("DD-MM-YYYY HH:mm:ss"),
            firstLetterCase(elt.type),
            !isEmpty(elt.count) ? elt.count : "-",
            !isEmpty(elt.price) ? elt.price : "-",
            elt.status ? "Active":"Inactive",
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: downloadData,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`${title}.pdf`);
    }

    render() {
        const { formModal, currentRecord, badges } = this.state;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey} />
                    <SubscriptionModal
                        isShow={formModal}
                        onHide={this.handleCloseForm}
                        fetchData={this.getData}
                        record={currentRecord}
                        activeBadges={badges}
                    />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button
                                onClick={() => this.addRecord()}
                                className="btn btn-outline-primary float-end mt-3 mr-2"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Add
                                Subscription
                            </button>
                            <div className="float-xl-right">
                                Export
                                {["current", "all"].map((v) => (
                                    <Form.Check
                                        inline
                                        type="radio"
                                        name="exportType"
                                        value={v}
                                        key={v}
                                        onChange={(e) => this.setState({ exportType: e.target.value })}
                                        defaultChecked={v == this.state.exportType}
                                        label={firstLetterCase(v)}
                                    />
                                ))}
                            </div>
                            <h3 className="mt-2 text-secondary">
                                Subscription
                            </h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                loading={this.state.loading}
                                onChange={this.search}
                                total_record={this.state.totalCount}
                                extraButtons={this.state.extraButtons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Subscription;
