// import config
import axios from "../config/axios";

let passbookSource;

export const getPairDropdown = async () => {
    try {
        const respData = await axios({
            url: `/adminapi/getPairDropdown`,
            method: "get",
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const getPassbookHistory = async (data = {}) => {
    try {

        if (passbookSource) passbookSource.cancel();
        passbookSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getPassbookHistory`,
            data,
            cancelToken: passbookSource.token,
        });
        return {
            status: true,
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: false,
        };
    }
};

export const getContactsList = async (data = {}) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/getContactsList`,
            data,
        });
        return {
            status: "success",
            loading: false,
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};
