import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { Modal } from "react-bootstrap";
import classnames from "classnames";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";

// import components
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { getUsers, updateStatus, deactivate2FA, updateUserDiscount } from "../../actions/userActions";

// import library
import { toastAlert } from "../../lib/toastAlert";
import { firstLetterCase } from "../../lib/capitalize";

class Users extends Component {

  constructor(props) {
    super(props);

    this.columns = [{
      key: "createdAt",
      text: "Created At",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <p>
              {moment(record.createdAt).format("DD-MM-YYYY HH:mm")}
            </p>
          </Fragment>
        );
      },
    },
    {
      key: "name",
      text: "Username",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <p>{record.firstName && record.firstName.trim() ? `${record.firstName} ${record.lastName}`:"-"}</p>
          </Fragment>
        );
      },
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <p>{record.email ? record.email:"-"}</p>
          </Fragment>
        );
      },
    },
    {
      key: "phoneNo",
      text: "Phone No",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <p>{record.phoneNo ? `+${record.phoneCode}${record.phoneNo}` : "-"}</p>
          </Fragment>
        );
      },
    },
    {
      key: "emailStatus",
      text: "Email Status",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        return firstLetterCase(record.emailStatus);
      },
    },
    {
      key: "phoneStatus",
      text: "Phone Status",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        return firstLetterCase(record.phoneStatus);
      },
    },
    {
      key: "active",
      text: "Active Status",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "tradeDiscount",
      text: "Trade Fee Discount",
      className: "tradeDiscount",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.tradeDiscount ?? 0;
      }
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: (record) => {
        let lockVal;
        if (record.active == "Activated") {
          lockVal = "fa fa-unlock";
        } else {
          lockVal = "fa fa-lock";
        }

        return (
          <Fragment>
            {record.google2Fa && record.google2Fa.secret != "" && (
              <button
                title="Disable TFA"
                className="btn btn-primary btn-sm mr-1"
                onClick={() => this.disableTFA(record)}
              >
                <i className="fa fa-shield"></i>
              </button>
            )}

            <button
              title={`${record.active=="Activated" ? "Deativate":"Activate"} User`}
              className="btn btn-primary btn-sm mr-1"
              onClick={() => this.changestatus(record)}
            >
              <i className={lockVal}></i>
            </button>
            <button title="Edit Trade Discount" className="btn btn-primary btn-sm" onClick={() => this.editRecord(record)}>
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    }];

    this.state = {
      records: [],
      totalCount: 0,
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "_id",
        order: "desc",
      },
      loading: true,
      exportType: "current",
      extraButtons: [{
        className: "btn btn-primary buttons-pdf",
        title: "Export PDF",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportPDF();
        },
      },
      {
        className: "btn btn-primary buttons-csv",
        title: "Export CSV",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
          </span>
        ],
        onClick: (event) => {
          this.exportCSV();
        },
      }],
      formModal: false, 
      userId: "", 
      tradeDiscount: 0, 
      errors: {}
    };

    this.config = {
      page_size: this.state.limit,
      length_menu: [10, 20, 50],
      filename: "Users",
      no_data_text: "No user found!",
      sort: this.state.sortOrder,
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "_id",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      pagination: 'advance',
      show_info: true,
      button: {
        extra: true,
      }
    };

    this.getData = this.getData.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (filter = "") => {
    try {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let data = {
        timezone,
        sortOrder:this.state.sortOrder,
        filter,
        limit: this.state.pageSize,
        offset: this.state.offset,
      };
      let { status, totalCount, result } = await getUsers(data);
      if (status) {
        this.setState({ loading: false });
        this.setState({ records: result, totalCount });
      }
    } catch (e) {}
  }

  async changestatus(record) {
    try {
      let data = { id: record._id };
      this.setState({ loading: true });
      const { status, message } = await updateStatus(data);
      this.setState({ loading: false });
      if (status) {
        toastAlert("success", message, "");
        this.getData();
      }
    } catch (err) {}
  }

  async disableTFA(record) {
    try {
      let data = { id: record._id };
      this.setState({ loading: true });
      const { status, message } = await deactivate2FA(data);
      this.setState({ loading: false });
      if (status) {
        toastAlert("success", message, "");
        this.getData();
      }
    } catch (err) {}
  }

  search = data => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number-1)*pageSize;
    let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
    this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
      this.getData(filter);
    });  
  }

  editRecord(record) {
    this.setState({
      formModal: true,
      userId: record._id,
      tradeDiscount: record.tradeDiscount ?? 0,
    });
  }

  handleClose() {
    this.setState({ formModal: false, userId: "", tradeDiscount: "", errors: {} });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    let errors = { ...this.state.errors, [name]:"" }
    this.setState({ [name]: value, errors });
  };

  validPercentage = (e) => {
    if (!new RegExp(`^-?\\d{0,9}(\\.\\d{0,8})?$`).test(e.target.value)) {
      e.target.value = "";
      return false;
    } else {
      if(!isNaN(e.target.value) && e.target.value > 100) {
        e.target.value = "";
        return false;
      }
    }
  };
  
  handleSubmit = async (e) => {
    try {
      let data = {
        id: this.state.userId,
        tradeDiscount: this.state.tradeDiscount
      };
      this.setState({ loader: true });
      let { status, loading, message, error } = await updateUserDiscount(data);
      this.setState({ loader: loading });
      if (status) {
        this.handleClose();
        toastAlert("success", message);
      } else {
        if (error)
          this.setState({ errors: error });
        toastAlert("error", message);
      }
      this.getData();
    } catch (err) {}
  }

  exportCSV = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await getUsers(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];
    let separator = ";";
    let headers = [
      "Created At",
      "Username",
      "Email",
      "Phone No",
      "Email Status",
      "Phone Status",
      "Active Status",
      "Trade Fee Discount",
    ];

    let rows = [];
    records.length > 0 && records.map((elt) => rows.push({
      "Created At": moment(elt.createdAt).format("DD-MM-YYYY HH:mm"),
      "Username": elt.firstName.trim() ? `${elt.firstName} ${elt.lastName}`:"-",
      "Email": elt.email ? elt.email:"-",
      "Phone No": elt.phoneNo ? `+${elt.phoneCode}${elt.phoneNo}` : "-",
      "Email Status": firstLetterCase(elt.emailStatus),
      "Phone Status": firstLetterCase(elt.phoneStatus),
      "Active Status": elt.active,
      "Trade Fee Discount": elt.tradeDiscount ?? 0,
    }));

    let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
      headers.map((k) => {
        let cell = row[k] === null || row[k] === undefined ? "" : row[k];

        cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator)
    ).join("\n")}`;

    let title = "Users";
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
          // In case of IE 10+
      navigator.msSaveBlob(blob, title);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
              // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", title);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  exportPDF = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await getUsers(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];

    let unit = "pt";
    let size = "A4"; // Use A1, A2, A3 or A4
    let orientation = "landscape"; // portrait or landscape

    let marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title = "Users";
    let headers = [[
      "Created At",
      "Username",
      "Email",
      "Phone No",
      "Email Status",
      "Phone Status",
      "Active Status",
      "Trade Fee Discount",
    ]];

    let downloadData = records.length > 0 && records.map((elt) => [
      moment(elt.createdAt).format("DD-MM-YYYY HH:mm"),
      elt.firstName.trim() ? `${elt.firstName} ${elt.lastName}`:"-",
      elt.email ? elt.email:"-",
      elt.phoneNo ? `+${elt.phoneCode}${elt.phoneNo}` : "-",
      firstLetterCase(elt.emailStatus),
      firstLetterCase(elt.phoneStatus),
      elt.active,
      elt.tradeDiscount ?? 0,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: downloadData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Users.pdf");
  }

  render() {

    const { errors, formModal, tradeDiscount } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="float-xl-right">
                Export
                {["current", "all"].map((v) => (
                  <Form.Check
                    inline
                    type="radio"
                    name="exportType"
                    value={v}
                    key={v}
                    onChange={(e) => this.setState({ exportType: e.target.value })}
                    defaultChecked={v == this.state.exportType}
                    label={firstLetterCase(v)}
                  />
                ))}
              </div>
              <h3 className="mt-2 text-secondary">Users List</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={this.state.totalCount}
                loading={this.state.loading}
                onChange={this.search}
                extraButtons={this.state.extraButtons}
              />
            </div>
          </div>
          <Modal
            show={formModal}
            onHide={this.handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <h4 className="modal-title">Spot Trade</h4>
            </Modal.Header>
            <Modal.Body>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="tradeDiscount">Discount on Fee</label>
                </div>
                <div className="col-md-6">
                  <input
                    onInput={this.validPercentage}
                    onChange={this.handleChange}
                    value={tradeDiscount}
                    name="tradeDiscount"
                    type="text"
                    error={errors.tradeDiscount}
                    className={classnames("form-control", {
                      invalid: errors.tradeDiscount,
                    })}
                  />
                  <span className="text-danger">{errors.tradeDiscount}</span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.handleClose}
              >
                Close
              </button>
              <button
                onClick={this.handleSubmit}
                type="submit"
                className="btn btn-primary"
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Users;
