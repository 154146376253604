import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Link } from "react-router-dom";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons/faUserAlt";
import axios from "axios";
import keys from "../../config/index";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
var CanvasJSReact = require("../../canvasjs.react");
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      user: "",
      p2p: "",
      stacking: "",
      spot: "",
      supportticket: "",
      depposit: "",
      withdraw: "",
      nameerr: "",
      p2pdispute: "",
    };
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentDidMount() {
    // getadmindetail()
    this.getData();
  }
  getData() {
    axios
      .get(keys.API_URL + "/adminapi/getcountfordashboard")
      .then((res) => {
        let value = res.data.result;
        this.setState({
          user: value.user,
          p2p: value.p2p,
          p2pdispute: value.p2pdispute,
          stacking: value.stacking,
          spot: value.spot,
          supportticket: value.supportticket,
          depposit: value.depposit,
          withdraw: value.withdraw,
        });
      })
      .catch((err) => console.log("errerrerrerrerrerrerrerr", err));
    console.log(this.props.auth, "aaaaaaaaaaaa");
  }

  render() {
    const { user } = this.props.auth;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Dashboard</h3>

              <div className="row px-0 px-md-2">
                {user.role === "superadmin" || user.User === true ? (
                  <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                    <div
                      className="card text-white shadow-lg"
                      style={{ backgroundColor: "cornflowerblue" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">Total Users</h5>
                        <p className="card-text">
                          No Of users Registered Still now.
                        </p>
                        <Link to="/users" className="btn btn-light">
                          {" "}
                          <p className="btn btn-light">
                            {" "}
                            <FontAwesomeIcon
                              className="text-primary"
                              icon={faUserAlt}
                            />{" "}
                            Count {this.state.user}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {user.role === "superadmin" || user.p2ptradehistory === true ? (
                  <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                    <div
                      className="card text-white shadow-lg"
                      style={{ backgroundColor: "IndianRed" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">P2P</h5>
                        <p className="card-text">
                          No Of Order Completed Still now
                        </p>

                        <Link to="/p2ptrade" className="btn btn-light">
                          {" "}
                          <p className="btn btn-light">
                            <FontAwesomeIcon
                              className="text-primary"
                              icon={faUserAlt}
                            />{" "}
                            Count {this.state.p2p}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {user.role === "superadmin" || user.p2pdisputelist === true ? (
                  <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                    <div
                      className="card text-white shadow-lg"
                      style={{ backgroundColor: "cadetblue" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">P2P Dispute List</h5>
                        <p className="card-text">No Of Disputes Still now</p>

                        <Link to="/p2pdispute" className="btn btn-light">
                          {" "}
                          <p className="btn btn-light">
                            <FontAwesomeIcon
                              className="text-primary"
                              icon={faUserAlt}
                            />{" "}
                            Count {this.state.p2pdispute}
                          </p>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {user.role === "superadmin" ||
                user.SpotTradeHistory === true ? (
                  <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                    <div
                      className="card text-white shadow-lg"
                      style={{ backgroundColor: "Orange" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">Spot</h5>
                        <p className="card-text">
                          No Of Order Completed Still now
                        </p>

                        <Link to="/tradehistory" className="btn btn-light">
                          {" "}
                          <p className="btn btn-light">
                            <FontAwesomeIcon
                              className="text-primary"
                              icon={faUserAlt}
                            />{" "}
                            Count {this.state.spot}
                          </p>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {user.role === "superadmin" || user.stackingorder === true ? (
                  <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                    <div
                      className="card text-white shadow-lg"
                      style={{ backgroundColor: "MediumSeaGreen" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">Staking</h5>
                        <p className="card-text">
                          No Of Order Completed Still now
                        </p>

                        <Link to="/stakingorder" className="btn btn-light">
                          <p className="btn btn-light">
                            <FontAwesomeIcon
                              className="text-primary"
                              icon={faUserAlt}
                            />{" "}
                            Count {this.state.stacking}
                          </p>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {user.role === "superadmin" || user.Support === true ? (
                  <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                    <div
                      className="card text-white shadow-lg"
                      style={{ backgroundColor: "Gray" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">Support Ticket</h5>
                        <p className="card-text">
                          No Of Support Ticket Still now
                        </p>

                        <Link to="/support" className="btn btn-light">
                          <p className="btn btn-light">
                            <FontAwesomeIcon
                              className="text-primary"
                              icon={faUserAlt}
                            />{" "}
                            Count {this.state.supportticket}{" "}
                          </p>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                <div className="card text-white shadow-lg" style={{backgroundColor: "blueviolet"}}>
                                    <div className="card-body">
                                        <h5 className="card-title">KYC</h5>
                                        <p className="card-text">No Of Order Completed Still now</p>
                                        <p  className="btn btn-light"><FontAwesomeIcon className="text-primary" /><BsFillCalendar2CheckFill/> Count  </p>
                                        
                                    </div>
                                </div>
                            </div> */}

                {user.role === "superadmin" || user.DepositList === true ? (
                  <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                    <div
                      className="card text-white shadow-lg"
                      style={{ backgroundColor: "Tomato" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">Deposit</h5>
                        <p className="card-text">
                          No Of Deposit Completed Still now
                        </p>

                        <Link to="/deposit" className="btn btn-light">
                          <p className="btn btn-light">
                            <FontAwesomeIcon
                              className="text-primary"
                              icon={faUserAlt}
                            />{" "}
                            Count {this.state.depposit}{" "}
                          </p>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {user.role === "superadmin" || user.WithdrawList === true ? (
                  <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                    <div
                      className="card text-white shadow-lg"
                      style={{ backgroundColor: "chocolate" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">Withdraw</h5>
                        <p className="card-text">
                          No Of Withdraw Completed Still now
                        </p>

                        <Link to="/withdraw" className="btn btn-light">
                          <p className="btn btn-light">
                            <FontAwesomeIcon
                              className="text-primary"
                              icon={faUserAlt}
                            />{" "}
                            Count {this.state.withdraw}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
