import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";
import keys from "../../actions/config";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import adminLogo from "../../images/logo.png";
// import Logo from "../../Logo-small.png"
const url = keys.baseUrl;
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
        _id : "",
        name: "",
    };
}
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { user } = this.props.auth;
    return (
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark adminNav">
          <a className="navbar-brand" href="/dashboard">
            <img src={adminLogo} className="img-fluid" alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faList} />
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
               <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="settings"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Settings
                      </a>
                      <div className="dropdown-menu" aria-labelledby="settings">
                           <Link to="/profile">
                             Profile
                           </Link>
                          <Link to="/changepassword">
                             ChangePassword
                           </Link>
                           {/* <Link to="/settings">
                             Settings
                           </Link> */}
                           <Link to="/login-history">
                             LoginHistory
                           </Link>
                           {/* <Link to="/security">
                             Google 2FA
                           </Link> */}

                      

                      </div>
                  </li>
              {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/site-setting" id="settings"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Settings
                </a>

                <div className="dropdown-menu" aria-labelledby="settings">
                  <Link to="/settings">Change Password</Link>
                  <Link to="/changepassword">Change Password</Link>
                </div>
              </li> */}
              {user&&user.role=="superadmin"?
              <li className="nav-item active">
                <a className="nav-link" href="/site-setting" >
                  Site Settings
                </a>
              </li>:""}

              <li className="nav-item active">
                <a className="nav-link" href="#" onClick={this.onLogoutClick}>
                  Logout ({user.name}) <FontAwesomeIcon icon={faSignOutAlt} />{" "}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
