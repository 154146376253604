import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Form } from "react-bootstrap";
import moment from "moment";
import { jsPDF } from "jspdf";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { loginHistory } from "../../actions/admin";

// import library
import { firstLetterCase } from "../../lib/capitalize";

class LoginHistory extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "countryCode",
        text: "Country Code",
        className: "countryCode",
        align: "left",
        sortable: true,
      },
      {
        key: "countryName",
        text: "Country Name",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "regionName",
        text: "State",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "broswername",
        text: "Browser",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "ipaddress",
        text: "IP Address",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "os",
        text: "OS",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "ismobile",
        text: "Mobile",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "createdDate",
        text: "Created Date",
        className: "currency",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <p>
                {moment(record.createdDate).format("DD-MM-YYYY HH:mm")}
              </p>
            </Fragment>
          );
        },
      },
      {
        key: "status",
        text: "Status",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "reason",
        text: "Reason",
        className: "currency",
        align: "left",
        sortable: true,
      },
    ];

    this.state = {
      records: [],
      totalCount: 0,
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "_id",
        order: "desc",
      },
      loading: true,
      categoryOption: [],
      exportType: "current",
      extraButtons: [{
        className: "btn btn-primary buttons-pdf",
        title: "Export PDF",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportPDF();
        }
      },
      {
        className: "btn btn-primary buttons-csv",
        title: "Export CSV",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportCSV();
        }
      }]
    };

    this.config = {
      page_size: this.state.pageSize,
      sort: this.state.sortOrder,
      length_menu: [10, 20, 50],
      no_data_text: "No History found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "_id",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      pagination: "advance",
      show_info: true,
      button: {
          extra: true,
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (filter = "") => {
    try {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let data = {
        timezone,
        sortOrder:this.state.sortOrder,
        filter,
        limit: this.state.pageSize,
        offset: this.state.offset,
      };
      let { status, result, totalCount } = await loginHistory(data);
      this.setState({ loading: false });
      if (status) {
        this.setState({ records: [], totalCount: 0 }, function() {
          this.setState({ totalCount, records: result });
        });
      }
    } catch (err) {}
  };

  exportCSV = async () => {

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await loginHistory(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
        ];
      return { extraButtons };
    });

    let records = status ? result: [];
    let separator = ";";
    let headers = [
      "Country Code",
      "Country Name",
      "State",
      "Browser",
      "IP Address",
      "OS",
      "Mobile",
      "Created Date",
      "Status",
      "Reason",
    ];

    let rows = [];
    records.length > 0 && records.map((elt) => rows.push({
      "Country Code": elt.countryCode,
      "Country Name": elt.countryName,
      "State": elt.regionName,
      "Browser": elt.broswername,
      "IP Address": elt.ipaddress,
      "OS": elt.os,
      "Mobile": elt.ismobile,
      "Created Date": moment(elt.createdDate).format("DD-MM-YYYY HH:mm"),
      "Status": elt.status,
      "Reason": elt.reason,
    }));

    let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
      headers.map((k) => {
        let cell = row[k] === null || row[k] === undefined ? "" : row[k];

        cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator)
      ).join("\n")}`;

    let title = "Login History";
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
            // In case of IE 10+
      navigator.msSaveBlob(blob, title);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", title);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  async exportPDF() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await loginHistory(data);
    
    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];

    let unit = "pt";
    let size = "A4"; // Use A1, A2, A3 or A4
    let orientation = "landscape"; // portrait or landscape

    let marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title = "Login History";
    let headers = [
      [
        "Country Code",
        "Country Name",
        "State",
        "Browser",
        "IP Address",
        "OS",
        "Mobile",
        "Created Date",
        "Status",
        "Reason",
      ],
    ];

    let downloadData = records.length > 0 && records.map((elt) => [
      elt.countryCode,
      elt.countryName,
      elt.regionName,
      elt.broswername,
      elt.ipaddress,
      elt.os,
      elt.ismobile,
      moment(elt.createdDate).format("DD-MM-YYYY HH:mm"),
      elt.status,
      elt.reason,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: downloadData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Login History.pdf");
  }

  search = data => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number-1)*pageSize;
    let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
    this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
      this.getData(filter);
    }); 
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />

          <div id="page-content-wrapper">
            <div className="float-xl-right">
              Export
              {["current", "all"].map((v) => (
                <Form.Check
                  inline
                  type="radio"
                  name="exportType"
                  value={v}
                  key={v}
                  onChange={(e) => this.setState({ exportType: e.target.value }) }
                  defaultChecked={v == this.state.exportType}
                  label={firstLetterCase(v)}
                />
              ))}
            </div>
            <div className="container-fluid">
              <div>
                <h3 className="mt-2 text-secondary">Login History</h3>
              </div>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={this.state.totalCount}
                loading={this.state.loading}
                onChange={this.search}
                extraButtons={this.state.extraButtons}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LoginHistory;
