import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import fileObjectUrl from "../../lib/fileObjectUrl";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
// import action
import { addAdmin } from "../../actions/admin";
import "react-toastify/dist/ReactToastify.css";

const initialFormValue = {
  role: "",
  name: "",
  email: "",
  password: "",
  ethprivatekey: "",
};

class AdminSideAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      formValue: initialFormValue,
      // "Dashborad" : false,
      User: false,
      UserBalance: false,
      Currency: false,
      PriceConversation: false,
      SpotPair: false,
      SideSetting: false,
      FaqCategory: false,
      Faq: false,
      airdrop: false,
      SupportCategory: false,
      Support: false,
      EmailTemplate: false,
      CmsPage: false,
      Kyc: false,
      WithdrawList: false,
      DepositList: false,
      ContactUs: false,
      Announcement: false,
      p2ppair: false,
      p2pAds: false,
      p2ptradehistory: false,
      p2pdisputelist: false,
      p2pcommissionhistory: false,
      StakeOrderHistory: false,
      stackingsettlement: false,
      Newletter: false,
      Homepagecms: false,
      Launchpad: false,
      Language: false,
      SpotOrderHistory: false,
      SpotTradeHistory: false,
      SpotCommision: false,
      TradingBot: false,
      BlogCategory: false,
      BlogArticle: false,
      Staking: false,
      copyTradeSettings: false,
      copyTradeApplications: false,
      topTraders: false,
      bannedTraders: false,
      followers: false,
      adminProfit: false,
      badges: false,
      subscription: false,
      subscribers: false,
      passbook: false,
      CopyOrderHistory: false,
      CopyTradeHistory: false,
      tdsSetting: false,
      userTDS: false,
      userTDSTax: false,
      errors: {},
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleFile = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: files[0] } };
    this.setState({ formValue: formData });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const { fetchData, closeModal } = this.props;

    const {
      formValue,
      User,
      UserBalance,
      Currency,
      PriceConversation,
      SpotPair,
      SideSetting,
      FaqCategory,
      Faq,
      SupportCategory,
      Support,
      EmailTemplate,
      CmsPage,
      Kyc,
      WithdrawList,
      DepositList,
      ContactUs,
      Announcement,
      Launchpad,
      Language,
      SpotOrderHistory,
      SpotTradeHistory,
      SpotCommision,
      TradingBot,
      BlogCategory,
      BlogArticle,
      Staking,
      p2ppair,
      p2pAds,
      p2ptradehistory,
      p2pdisputelist,
      p2pcommissionhistory,
      stackingsettlement,
      StakeOrderHistory,
      Newletter,
      Homepagecms,

      airdrop,
      copyTradeSettings,
      copyTradeApplications,
      topTraders,
      bannedTraders,
      followers,
      adminProfit,
      badges,
      subscription,
      subscribers,
      passbook,
      CopyOrderHistory,
      CopyTradeHistory,
      tdsSetting,
      userTDS,
      userTDSTax,
    } = this.state;
    let reqData = {
      ...formValue,
      User,
      UserBalance,
      Currency,
      PriceConversation,
      SpotPair,
      SideSetting,
      FaqCategory,
      Faq,
      SupportCategory,
      Support,
      EmailTemplate,
      CmsPage,
      Kyc,
      StakeOrderHistory,
      WithdrawList,
      DepositList,
      p2ppair,
      p2pAds,
      p2pAds,
      p2ptradehistory,
      p2pdisputelist,
      p2pcommissionhistory,
      stackingsettlement,

      Newletter,
      Homepagecms,

      ContactUs,
      Announcement,
      Launchpad,
      Language,
      SpotOrderHistory,
      SpotTradeHistory,
      SpotCommision,
      TradingBot,
      BlogCategory,
      BlogArticle,
      Staking,
      airdrop,
      copyTradeSettings,
      copyTradeApplications,
      topTraders,
      bannedTraders,
      followers,
      adminProfit,
      badges,
      subscription,
      subscribers,
      passbook,
      CopyOrderHistory,
      CopyTradeHistory,
      tdsSetting,
      userTDS,
      userTDSTax,
    };

    // const formData = new FormData();
    // formData.append("type", type);
    // formData.append("currencyName", currencyName);
    // formData.append("currencySymbol", currencySymbol);
    // formData.append("contractAddress", contractAddress);
    // formData.append("minABI", minABI);
    // formData.append("decimals", decimals);
    // formData.append("withdrawFee", withdrawFee);
    // formData.append("minimumWithdraw", minimumWithdraw);
    // formData.append("bankName", bankName);
    // formData.append("accountNo", accountNo);
    // formData.append("holderName", holderName);
    // formData.append("bankcode", bankcode);
    // formData.append("country", country);
    // formData.append("currencyImage", currencyImage);
    this.setState({ loader: true });
    try {
      const { status, loading, message, error } = await addAdmin(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.handleClose();
        fetchData();
        toastAlert("success", message, "AdminSideAddModal");
      } else if (status == "failed") {
        if (error) {
          this.setState({ errors: error });
        } else {
          toastAlert("error", message, "AdminSideAddModal");
        }
      }
    } catch (err) {}
  };

  render() {
    const { role, name, companyName, email, password, ethprivatekey } =
      this.state.formValue;
    const { errors, loader } = this.state;

    const { isShow } = this.props;

    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Add Admin</h4>
          </Modal.Header>
          <Modal.Body>
            <form noValidate onSubmit={this.handleSubmit} id="add-template">
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Select Admin type</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"role"}
                    value={role}
                    onChange={this.handleChange}
                  >
                    <option value={""}>{"Select Admin Type"}</option>
                    <option value={"Admin"}>{"Admin"}</option>
                    {/* <option value={"subadmin"}>
                                            {"subadmin"}
                                        </option> */}
                  </Form.Control>
                  <span className="text-danger">{errors.serviceId}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={name}
                    name="name"
                    type="text"
                    error={errors.name}
                    className={classnames("form-control", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Email</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={email}
                    name="email"
                    type="text"
                    error={errors.email}
                    className={classnames("form-control", {
                      invalid: errors.email,
                    })}
                  />
                  <span className="text-danger">{errors.email}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">password</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={password}
                    name="password"
                    type="password"
                    error={errors.password}
                    className={classnames("form-control", {
                      invalid: errors.password,
                    })}
                  />
                  <span className="text-danger">{errors.password}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">User</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.User}
                    onlabel="ON"
                    offlabel="OFF"
                    name="User"
                    onChange={(checked) => {
                      this.setState({ User: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">UserBalance</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.UserBalance}
                    onlabel="ON"
                    offlabel="OFF"
                    name="UserBalance"
                    onChange={(checked) => {
                      this.setState({
                        UserBalance: checked,
                      });
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="name">Currency</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Currency}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Currency"
                    onChange={(checked) => {
                      this.setState({
                        Currency: checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">PriceConversation</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.PriceConversation}
                    onlabel="ON"
                    offlabel="OFF"
                    name="PriceConversation"
                    onChange={(checked) => {
                      this.setState({
                        PriceConversation: checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SpotPair</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SpotPair}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SpotPair"
                    onChange={(checked) => {
                      this.setState({
                        SpotPair: checked,
                      });
                    }}
                  />
                </div>
              </div>

              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">SideSetting</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.SideSetting}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="SideSetting"
                                        onChange={(checked) => {
                                            this.setState({
                                                SideSetting: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div> */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">FaqCategory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.FaqCategory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="FaqCategory"
                    onChange={(checked) => {
                      this.setState({
                        FaqCategory: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Faq</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Faq}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Faq"
                    onChange={(checked) => {
                      this.setState({ Faq: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SupportCategory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SupportCategory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SupportCategory"
                    onChange={(checked) => {
                      this.setState({
                        SupportCategory: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Support</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Support}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Support"
                    onChange={(checked) => {
                      this.setState({ Support: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">EmailTemplate</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.EmailTemplate}
                    onlabel="ON"
                    offlabel="OFF"
                    name="EmailTemplate"
                    onChange={(checked) => {
                      this.setState({
                        EmailTemplate: checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">CmsPage</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.CmsPage}
                    onlabel="ON"
                    offlabel="OFF"
                    name="CmsPage"
                    onChange={(checked) => {
                      this.setState({ CmsPage: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Kyc</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Kyc}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Kyc"
                    onChange={(checked) => {
                      this.setState({ Kyc: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">WithdrawList</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.WithdrawList}
                    onlabel="ON"
                    offlabel="OFF"
                    name="WithdrawList"
                    onChange={(checked) => {
                      this.setState({
                        WithdrawList: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">DepositList</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.DepositList}
                    onlabel="ON"
                    offlabel="OFF"
                    name="DepositList"
                    onChange={(checked) => {
                      this.setState({
                        DepositList: checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">ContactUs</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.ContactUs}
                    onlabel="ON"
                    offlabel="OFF"
                    name="ContactUs"
                    onChange={(checked) => {
                      this.setState({
                        ContactUs: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2ppair</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2ppair}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2ppair"
                    onChange={(checked) => {
                      this.setState({ p2ppair: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2pAds</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2pAds}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2pAds"
                    onChange={(checked) => {
                      this.setState({ p2pAds: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">StakeOrderHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.StakeOrderHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="StakeOrderHistory"
                    onChange={(checked) => {
                      this.setState({ StakeOrderHistory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2ptradehistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2ptradehistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2ptradehistory"
                    onChange={(checked) => {
                      this.setState({ p2ptradehistory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2pdisputelist</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2pdisputelist}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2pdisputelist"
                    onChange={(checked) => {
                      this.setState({ p2pdisputelist: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2pcommissionhistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2pcommissionhistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2pcommissionhistory"
                    onChange={(checked) => {
                      this.setState({ p2pcommissionhistory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">stackingsettlement</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.stackingsettlement}
                    onlabel="ON"
                    offlabel="OFF"
                    name="stackingsettlement"
                    onChange={(checked) => {
                      this.setState({ stackingsettlement: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Newletter</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Newletter}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Newletter"
                    onChange={(checked) => {
                      this.setState({ Newletter: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Homepagecms</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Homepagecms}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Homepagecms"
                    onChange={(checked) => {
                      this.setState({ Homepagecms: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Announcement</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Announcement}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Announcement"
                    onChange={(checked) => {
                      this.setState({ Announcement: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Launchpad</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Launchpad}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Launchpad"
                    onChange={(checked) => {
                      this.setState({
                        Launchpad: checked,
                      });
                    }}
                  />
                </div>
              </div>

              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Language</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Language}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Language"
                                        onChange={(checked) => {
                                            this.setState({
                                                Language: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div> */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SpotOrderHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SpotOrderHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SpotOrderHistory"
                    onChange={(checked) => {
                      this.setState({
                        SpotOrderHistory: checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SpotTradeHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SpotTradeHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SpotTradeHistory"
                    onChange={(checked) => {
                      this.setState({
                        SpotTradeHistory: checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SpotCommisionHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SpotCommision}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SpotCommision"
                    onChange={(checked) => {
                      this.setState({
                        SpotCommision: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">TradingBot</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.TradingBot}
                    onlabel="ON"
                    offlabel="OFF"
                    name="TradingBot"
                    onChange={(checked) => {
                      this.setState({ TradingBot: checked });
                    }}
                  />
                </div>
              </div>

              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">BlogCategory</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.BlogCategory}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="BlogCategory"
                                        onChange={(checked) => {
                                            this.setState({
                                                BlogCategory: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">BlogArticle</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.BlogArticle}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="BlogArticle"
                                        onChange={(checked) => {
                                            this.setState({
                                                BlogArticle: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div> */}
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Staking</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Staking}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Staking"
                    onChange={(checked) => {
                      this.setState({ Staking: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Airdrop</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.airdrop}
                    onlabel="ON"
                    offlabel="OFF"
                    name="airdrop"
                    onChange={(checked) => {
                      this.setState({ airdrop: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Copy Trade Settings</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.copyTradeSettings}
                    onlabel="ON"
                    offlabel="OFF"
                    name="copyTradeSettings"
                    onChange={(checked) => {
                      this.setState({ copyTradeSettings: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Copy Trade Applications</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.copyTradeApplications}
                    onlabel="ON"
                    offlabel="OFF"
                    name="copyTradeApplications"
                    onChange={(checked) => {
                      this.setState({ copyTradeApplications: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Top Traders</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.topTraders}
                    onlabel="ON"
                    offlabel="OFF"
                    name="topTraders"
                    onChange={(checked) => {
                      this.setState({ topTraders: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Banned Traders</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.bannedTraders}
                    onlabel="ON"
                    offlabel="OFF"
                    name="bannedTraders"
                    onChange={(checked) => {
                      this.setState({ bannedTraders: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Users Profit History</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.followers}
                    onlabel="ON"
                    offlabel="OFF"
                    name="followers"
                    onChange={(checked) => {
                      this.setState({ followers: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">CopyOrderHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.CopyOrderHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="CopyOrderHistory"
                    onChange={(checked) => {
                      this.setState({
                        CopyOrderHistory: checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">CopyTradeHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.CopyTradeHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="CopyTradeHistory"
                    onChange={(checked) => {
                      this.setState({
                        CopyTradeHistory: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Admin Profit</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.adminProfit}
                    onlabel="ON"
                    offlabel="OFF"
                    name="adminProfit"
                    onChange={(checked) => {
                      this.setState({ adminProfit: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Badges</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.badges}
                    onlabel="ON"
                    offlabel="OFF"
                    name="badges"
                    onChange={(checked) => {
                      this.setState({ badges: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Copy Trade Subscription</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.subscription}
                    onlabel="ON"
                    offlabel="OFF"
                    name="subscription"
                    onChange={(checked) => {
                      this.setState({ subscription: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Copy Trade Subscribers</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.subscribers}
                    onlabel="ON"
                    offlabel="OFF"
                    name="subscribers"
                    onChange={(checked) => {
                      this.setState({ subscribers: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Passbook</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.passbook}
                    onlabel="ON"
                    offlabel="OFF"
                    name="passbook"
                    onChange={(checked) => {
                      this.setState({ passbook: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">TDS Setting</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.tdsSetting}
                    onlabel="ON"
                    offlabel="OFF"
                    name="tdsSetting"
                    onChange={(checked) => {
                      this.setState({
                        tdsSetting: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">User TDS</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.userTDS}
                    onlabel="ON"
                    offlabel="OFF"
                    name="userTDS"
                    onChange={(checked) => {
                      this.setState({
                        userTDS: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">User TDS Tax</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.userTDSTax}
                    onlabel="ON"
                    offlabel="OFF"
                    name="userTDSTax"
                    onChange={(checked) => {
                      this.setState({
                        userTDSTax: checked,
                      });
                    }}
                  />
                </div>
              </div>

              {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">AdminSubadmin_controller</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.AdminSubadmin_controller}
                                        onlabel='ON'
                                        offlabel='OFF'
                                        name="AdminSubadmin_controller"
                                        onChange={(checked) => {
                                            this.setState({ 'AdminSubadmin_controller': checked })
                                        }}
                                    />
                                </div>
                            </div>*/}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              {loader && <i class="fas fa-spinner fa-spin"></i>}
              Add Admin
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AdminSideAddModal;