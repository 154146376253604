import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import action
import { updateAdmin } from "../../actions/admin";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "react-toastify/dist/ReactToastify.css";
// import lib
import { toastAlert } from "../../lib/toastAlert";
import fileObjectUrl from "../../lib/fileObjectUrl";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  _id: "",
  role: "",
  companyName: "",
  name: "",
  email: "",
};

class AdminUpdateModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loader: false,
      formValue: initialFormValue,
      User: false,
      UserBalance: false,
      Currency: false,
      PriceConversation: false,
      SpotPair: false,
      SideSetting: false,
      FaqCategory: false,
      Faq: false,
      SupportCategory: false,
      Support: false,
      EmailTemplate: false,
      CmsPage: false,
      Kyc: false,
      WithdrawList: false,
      DepositList: false,
      ContactUs: false,
      Announcement: false,
      Launchpad: false,
      Language: false,
      SpotOrderHistory: false,
      SpotTradeHistory: false,
      SpotCommision: false,

      p2ppair: false,
      p2pAds: false,
      p2ptradehistory: false,
      p2pdisputelist: false,
      p2pcommissionhistory: false,
      StakeOrderHistory: false,
      stackingsettlement: false,
      Newletter: false,
      Homepagecms: false,

      TradingBot: false,
      BlogCategory: false,
      airdrop: false,
      BlogArticle: false,
      Staking: false,
      copyTradeSettings: false,
      copyTradeApplications: false,
      topTraders: false,
      bannedTraders: false,
      followers: false,
      adminProfit: false,
      badges: false,
      subscription: false,
      subscribers: false,
      passbook: false,
      CopyOrderHistory: false,
      CopyTradeHistory: false,
      tdsSetting: false,
      userTDS: false,
      userTDSTax: false,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { record } = nextProps;
    if (record) {
      let value = {
        name: record.name,
        email: record.email,
        role: record.role,
        _id: record._id,
      };
      this.setState({ formValue: value });
      this.setState({
        User: record.User,
        UserBalance: record.UserBalance,
        Currency: record.Currency,
        PriceConversation: record.PriceConversation,
        SpotPair: record.SpotPair,
        SideSetting: record.SideSetting,
        FaqCategory: record.FaqCategory,
        Faq: record.Faq,
        SupportCategory: record.SupportCategory,
        Support: record.Support,
        EmailTemplate: record.EmailTemplate,
        CmsPage: record.CmsPage,
        Kyc: record.Kyc,
        WithdrawList: record.WithdrawList,
        DepositList: record.DepositList,
        ContactUs: record.ContactUs,
        Announcement: record.Announcement,
        Launchpad: record.Launchpad,

        p2ppair: record.p2ppair,
        p2pAds: record.p2pAds,
        p2ptradehistory: record.p2ptradehistory,
        p2pdisputelist: record.p2pdisputelist,
        p2pcommissionhistory: record.p2pcommissionhistory,
        StakeOrderHistory: record.StakeOrderHistory,
        stackingsettlement: record.stackingsettlement,
        Newletter: record.Newletter,
        Homepagecms: record.Homepagecms,

        Language: record.Language,
        SpotOrderHistory: record.SpotOrderHistory,
        SpotTradeHistory: record.SpotTradeHistory,
        SpotCommision: record.SpotCommision,
        TradingBot: record.TradingBot,
        BlogCategory: record.BlogCategory,
        BlogArticle: record.BlogArticle,
        Staking: record.Staking,
        airdrop: record.airdrop,
        copyTradeSettings: record.copyTradeSettings,
        copyTradeApplications: record.copyTradeApplications,
        topTraders: record.topTraders,
        bannedTraders: record.bannedTraders,
        followers: record.followers,
        adminProfit: record.adminProfit,
        badges: record.badges,
        subscription: record.subscription,
        subscribers: record.subscribers,
        passbook: record.passbook,
        CopyOrderHistory: record.CopyOrderHistory,
        CopyTradeHistory: record.CopyTradeHistory,
        tdsSetting: record.tdsSetting,
        userTDS: record.userTDS,
        userTDSTax: record.userTDSTax,
      });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleFile = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: files[0] } };
    this.setState({ formValue: formData });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { fetchData } = this.props;

    // if (formValue && formValue.currencyImage) {
    //   if (formValue.currencyImage.size > 20000) {
    //     this.setState({ errors: { currencyImage: "Image size should be less than  20 Kb" } })
    //     toastAlert('error', "Image size should be less than  20 Kb", 'currencyUpdateModal')
    //     return false
    //   }
    // }

    try {
      const {
        formValue,
        User,
        UserBalance,
        Currency,
        PriceConversation,
        SpotPair,
        SideSetting,
        FaqCategory,
        Faq,
        SupportCategory,
        Support,
        EmailTemplate,
        CmsPage,
        Kyc,
        WithdrawList,
        DepositList,
        ContactUs,
        Announcement,
        Launchpad,
        Language,

        p2ppair,
        p2pAds,
        p2ptradehistory,
        p2pdisputelist,
        p2pcommissionhistory,
        stackingsettlement,
        StakeOrderHistory,
        Newletter,
        Homepagecms,

        SpotOrderHistory,
        SpotTradeHistory,
        SpotCommision,
        TradingBot,
        BlogCategory,
        BlogArticle,
        Staking,
        airdrop,
        copyTradeSettings,
        copyTradeApplications,
        topTraders,
        bannedTraders,
        followers,
        adminProfit,
        badges,
        subscription,
        subscribers,
        passbook,
        CopyOrderHistory,
        CopyTradeHistory,
        tdsSetting,
        userTDS,
        userTDSTax,
      } = this.state;
      let reqData = {
        ...formValue,
        User,
        UserBalance,
        Currency,
        PriceConversation,
        SpotPair,
        SideSetting,
        FaqCategory,
        Faq,
        SupportCategory,
        Support,
        EmailTemplate,
        CmsPage,
        Kyc,
        WithdrawList,
        DepositList,
        ContactUs,
        Announcement,
        Launchpad,
        Language,

        p2ppair,
        p2pAds,
        p2ptradehistory,
        p2pdisputelist,
        p2pcommissionhistory,
        stackingsettlement,
        StakeOrderHistory,
        Newletter,
        Homepagecms,

        SpotOrderHistory,
        SpotTradeHistory,
        SpotCommision,
        TradingBot,
        BlogCategory,
        BlogArticle,
        Staking,
        airdrop,
        copyTradeSettings,
        copyTradeApplications,
        topTraders,
        bannedTraders,
        followers,
        adminProfit,
        badges,
        subscription,
        subscribers,
        passbook,
        CopyOrderHistory,
        CopyTradeHistory,
        tdsSetting,
        userTDS,
        userTDSTax,
      };
      // const formData = new FormData();
      // formData.append("currencyId", formValue.currencyId);
      // formData.append("type", formValue.type);
      // formData.append("currencyName", formValue.currencyName);
      // formData.append("currencySymbol", formValue.currencySymbol);
      // formData.append("contractAddress", formValue.contractAddress);
      // formData.append("minABI", formValue.minABI);
      // formData.append("decimals", formValue.decimals);
      // formData.append("withdrawFee", formValue.withdrawFee);
      // formData.append("minimumWithdraw", formValue.minimumWithdraw);
      // formData.append("bankName", formValue.bankName);
      // formData.append("accountNo", formValue.accountNo);
      // formData.append("holderName", formValue.holderName);
      // formData.append("bankcode", formValue.bankcode);
      // formData.append("country", formValue.country);
      // formData.append("currencyImage", formValue.currencyImage);
      // formData.append("status", formValue.status);

      this.setState({ loader: true });

      const { status, loading, message, error } = await updateAdmin(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.handleClose();
        fetchData();
        toastAlert("success", message, "currencyUpdateModal");
      } else if (status == "failed") {
        if (error) {
          this.setState({ errors: error });
        } else {
          toastAlert("error", message, "currencyUpdateModal");
        }
      }
    } catch (err) {}
  };

  render() {
    const { _id, role, name, email } = this.state.formValue;
    const { errors, loader } = this.state;

    const { isShow } = this.props;

    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Update Admin</h4>
          </Modal.Header>
          <Modal.Body>
            <form noValidate onSubmit={this.handleSubmit} id="add-template">
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Select Admin type</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"role"}
                    value={role}
                    onChange={this.handleChange}
                  >
                    <option value={""}>{"Select Admin Type"}</option>
                    <option value={"Admin"}>{"Admin"}</option>
                    {/* <option value={"subadmin"}>{"subadmin"}</option> */}
                  </Form.Control>
                  <span className="text-danger">{errors.serviceId}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={name}
                    name="name"
                    type="text"
                    error={errors.name}
                    className={classnames("form-control", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Email</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={email}
                    name="email"
                    type="text"
                    error={errors.email}
                    className={classnames("form-control", {
                      invalid: errors.email,
                    })}
                  />
                  <span className="text-danger">{errors.email}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">User</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.User}
                    onlabel="ON"
                    offlabel="OFF"
                    name="User"
                    onChange={(checked) => {
                      this.setState({ User: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">UserBalance</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.UserBalance}
                    onlabel="ON"
                    offlabel="OFF"
                    name="UserBalance"
                    onChange={(checked) => {
                      this.setState({ UserBalance: checked });
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="name">Currency</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Currency}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Currency"
                    onChange={(checked) => {
                      this.setState({ Currency: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">PriceConversation</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.PriceConversation}
                    onlabel="ON"
                    offlabel="OFF"
                    name="PriceConversation"
                    onChange={(checked) => {
                      this.setState({ PriceConversation: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SpotPair</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SpotPair}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SpotPair"
                    onChange={(checked) => {
                      this.setState({ SpotPair: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SideSetting</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SideSetting}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SideSetting"
                    onChange={(checked) => {
                      this.setState({ SideSetting: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">FaqCategory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.FaqCategory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="FaqCategory"
                    onChange={(checked) => {
                      this.setState({ FaqCategory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Faq</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Faq}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Faq"
                    onChange={(checked) => {
                      this.setState({ Faq: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SupportCategory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SupportCategory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SupportCategory"
                    onChange={(checked) => {
                      this.setState({ SupportCategory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Support</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Support}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Support"
                    onChange={(checked) => {
                      this.setState({ Support: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">EmailTemplate</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.EmailTemplate}
                    onlabel="ON"
                    offlabel="OFF"
                    name="EmailTemplate"
                    onChange={(checked) => {
                      this.setState({ EmailTemplate: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">CmsPage</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.CmsPage}
                    onlabel="ON"
                    offlabel="OFF"
                    name="CmsPage"
                    onChange={(checked) => {
                      this.setState({ CmsPage: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Kyc</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Kyc}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Kyc"
                    onChange={(checked) => {
                      this.setState({ Kyc: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">WithdrawList</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.WithdrawList}
                    onlabel="ON"
                    offlabel="OFF"
                    name="WithdrawList"
                    onChange={(checked) => {
                      this.setState({ WithdrawList: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">DepositList</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.DepositList}
                    onlabel="ON"
                    offlabel="OFF"
                    name="DepositList"
                    onChange={(checked) => {
                      this.setState({ DepositList: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">ContactUs</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.ContactUs}
                    onlabel="ON"
                    offlabel="OFF"
                    name="ContactUs"
                    onChange={(checked) => {
                      this.setState({ ContactUs: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Announcement</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Announcement}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Announcement"
                    onChange={(checked) => {
                      this.setState({ Announcement: checked });
                    }}
                  />
                </div>
              </div>

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Launchpad</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Launchpad}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Launchpad"
                    onChange={(checked) => {
                      this.setState({ Launchpad: checked });
                    }}
                  />
                </div>
              </div> */}

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Language</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Language}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Language"
                    onChange={(checked) => {
                      this.setState({ Language: checked });
                    }}
                  />
                </div>
              </div> */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SpotOrderHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SpotOrderHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SpotOrderHistory"
                    onChange={(checked) => {
                      this.setState({ SpotOrderHistory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SpotTradeHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SpotTradeHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SpotTradeHistory"
                    onChange={(checked) => {
                      this.setState({ SpotTradeHistory: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">SpotCommisionHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.SpotCommision}
                    onlabel="ON"
                    offlabel="OFF"
                    name="SpotCommision"
                    onChange={(checked) => {
                      this.setState({ SpotCommision: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Airdrop</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.airdrop}
                    onlabel="ON"
                    offlabel="OFF"
                    name="airdrop"
                    onChange={(checked) => {
                      this.setState({ airdrop: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">TradingBot</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.TradingBot}
                    onlabel="ON"
                    offlabel="OFF"
                    name="TradingBot"
                    onChange={(checked) => {
                      this.setState({ TradingBot: checked });
                    }}
                  />
                </div>
              </div>
              {/* 
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">BlogCategory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.BlogCategory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="BlogCategory"
                    onChange={(checked) => {
                      this.setState({ BlogCategory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">BlogArticle</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.BlogArticle}
                    onlabel="ON"
                    offlabel="OFF"
                    name="BlogArticle"
                    onChange={(checked) => {
                      this.setState({ BlogArticle: checked });
                    }}
                  />
                </div>
              </div> */}
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Staking</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Staking}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Staking"
                    onChange={(checked) => {
                      this.setState({ Staking: checked });
                    }}
                  />
                </div>
              </div>
              {/* */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2ppair</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2ppair}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2ppair"
                    onChange={(checked) => {
                      this.setState({ p2ppair: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2pAds</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2pAds}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2pAds"
                    onChange={(checked) => {
                      this.setState({ p2pAds: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2ptradehistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2ptradehistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2ptradehistory"
                    onChange={(checked) => {
                      this.setState({ p2ptradehistory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2pdisputelist</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2pdisputelist}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2pdisputelist"
                    onChange={(checked) => {
                      this.setState({ p2pdisputelist: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">P2pcommissionhistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.p2pcommissionhistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="p2pcommissionhistory"
                    onChange={(checked) => {
                      this.setState({ p2pcommissionhistory: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">stackingsettlement</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.stackingsettlement}
                    onlabel="ON"
                    offlabel="OFF"
                    name="stackingsettlement"
                    onChange={(checked) => {
                      this.setState({ stackingsettlement: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Newletter</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Newletter}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Newletter"
                    onChange={(checked) => {
                      this.setState({ Newletter: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Homepagecms</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.Homepagecms}
                    onlabel="ON"
                    offlabel="OFF"
                    name="Homepagecms"
                    onChange={(checked) => {
                      this.setState({ Homepagecms: checked });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">StakeOrderHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.StakeOrderHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="StakeOrderHistory"
                    onChange={(checked) => {
                      this.setState({ StakeOrderHistory: checked });
                    }}
                  />
                </div>
              </div>

              {/* */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Copy Trade Settings</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.copyTradeSettings}
                    onlabel="ON"
                    offlabel="OFF"
                    name="copyTradeSettings"
                    onChange={(checked) => {
                      this.setState({ copyTradeSettings: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Copy Trade Applications</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.copyTradeApplications}
                    onlabel="ON"
                    offlabel="OFF"
                    name="copyTradeApplications"
                    onChange={(checked) => {
                      this.setState({ copyTradeApplications: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Top Traders</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.topTraders}
                    onlabel="ON"
                    offlabel="OFF"
                    name="topTraders"
                    onChange={(checked) => {
                      this.setState({ topTraders: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Banned Traders</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.bannedTraders}
                    onlabel="ON"
                    offlabel="OFF"
                    name="bannedTraders"
                    onChange={(checked) => {
                      this.setState({ bannedTraders: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">CopyOrderHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.CopyOrderHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="CopyOrderHistory"
                    onChange={(checked) => {
                      this.setState({
                        CopyOrderHistory: checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">CopyTradeHistory</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.CopyTradeHistory}
                    onlabel="ON"
                    offlabel="OFF"
                    name="CopyTradeHistory"
                    onChange={(checked) => {
                      this.setState({
                        CopyTradeHistory: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Users Profit History</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.followers}
                    onlabel="ON"
                    offlabel="OFF"
                    name="followers"
                    onChange={(checked) => {
                      this.setState({ followers: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Admin Profit</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.adminProfit}
                    onlabel="ON"
                    offlabel="OFF"
                    name="adminProfit"
                    onChange={(checked) => {
                      this.setState({ adminProfit: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Badges</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.badges}
                    onlabel="ON"
                    offlabel="OFF"
                    name="badges"
                    onChange={(checked) => {
                      this.setState({ badges: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Copy Trade Subscription</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.subscription}
                    onlabel="ON"
                    offlabel="OFF"
                    name="subscription"
                    onChange={(checked) => {
                      this.setState({ subscription: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Copy Trade Subscribers</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.subscribers}
                    onlabel="ON"
                    offlabel="OFF"
                    name="subscribers"
                    onChange={(checked) => {
                      this.setState({ subscribers: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Passbook</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.passbook}
                    onlabel="ON"
                    offlabel="OFF"
                    name="passbook"
                    onChange={(checked) => {
                      this.setState({ passbook: checked });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">TDS Setting</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.tdsSetting}
                    onlabel="ON"
                    offlabel="OFF"
                    name="tdsSetting"
                    onChange={(checked) => {
                      this.setState({
                        tdsSetting: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">User TDS</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.userTDS}
                    onlabel="ON"
                    offlabel="OFF"
                    name="userTDS"
                    onChange={(checked) => {
                      this.setState({
                        userTDS: checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">User TDS Tax</label>
                </div>
                <div className="col-md-9">
                  <BootstrapSwitchButton
                    checked={this.state.userTDSTax}
                    onlabel="ON"
                    offlabel="OFF"
                    name="userTDSTax"
                    onChange={(checked) => {
                      this.setState({
                        userTDSTax: checked,
                      });
                    }}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              {loader && <i class="fas fa-spinner fa-spin"></i>}Update Admin
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AdminUpdateModal;
