// import config
import axios from "../config/axios";

let templateSource;

export const templateList = async (data = {}) => {
    try {
        if (templateSource) templateSource.cancel();
        templateSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/emailTemplate`,
            method: "patch",
            data,
            cancelToken: templateSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const addEmailTemplate = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/emailTemplate`,
            method: "post",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const editEmailTemplate = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/emailTemplate`,
            method: "put",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};