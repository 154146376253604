import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replyContact } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Modal, Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";

import { toastAlert } from "../../lib/toastAlert";

class ContactUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      id: this.props.record._id,
      name: this.props.record.name,
      email: this.props.record.email,
      mobilenumber: this.props.record.mobilenumber,
      message: this.props.record.message,
      reply: this.props.record.reply,
      errors: {},
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  handleEditorChange(reply, editor) {
    this.setState({ reply });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record) {
      this.setState({
        id: nextProps.record._id,
        name: nextProps.record.name,
        email: nextProps.record.email,
        mobilenumber: nextProps.record.mobilenumber,
        message: nextProps.record.message,
        reply: nextProps.record.reply,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }    if (
      nextProps.auth !== undefined &&
      nextProps.auth.replycontact !== undefined &&
      nextProps.auth.replycontact.data !== undefined &&
      nextProps.auth.replycontact.data.message !== undefined &&
      nextProps.auth.replycontact.data.success
    ) {
      toastAlert("success", nextProps.auth.replycontact.data.message);
      nextProps.auth.replycontact = "";
    }
  }

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ reply: "" });
  };

  onContactReplay = (e) => {
    e.preventDefault();
    const newReply = {
      _id: this.state.id,
      reply: this.state.reply,
    };
    if (!this.state.reply) {
      toastAlert("error", "Please Enter Message");
      return;
    }
    let v = this.props.replyContact(newReply);
    this.setState({
      reply: "",
    });
    this.handleClose();
  };

  render() {
    const { errors } = this.state;
    const { isShow } = this.props;

    return (
      <div>
        {/* <div className="modal fade" id="update-contact-modal">
                    <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Reply to contact</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body"> */}
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Reply to contact</h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <input
                onChange={this.onChange}
                value={this.state.id}
                id="contact-update-id"
                type="text"
                className="d-none"
              />
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="col-md-9">{this.state.name}</div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Email</label>
                </div>
                <div className="col-md-9">{this.state.email}</div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Question</label>
                </div>
                <div className="col-md-9">{this.state.message}</div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="reply">Reply</label>
                </div>
                <div className="col-md-9">
                  <Editor
                    apiKey="5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5"
                    // initialValue={this.state.reply}
                    value={this.state.reply}
                    onEditorChange={this.handleEditorChange}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help",
                    }}
                  />
                  <span className="text-danger">{errors.reply}</span>
                </div>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.handleClose}
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                form="update-contact"
                type="submit"
                onClick={this.onContactReplay}
                className="btn btn-primary"
              >
                Reply
              </button>
            </div>
            {/* //     </div> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ContactUpdateModal.propTypes = {
  replyContact: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { replyContact })(
  withRouter(ContactUpdateModal)
);
