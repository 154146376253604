// import config
import axios from "../config/axios";

let spotTradeSource, spotOrderSource;

export const spotOrderHistory = async (data) => {
    try {

        if (spotOrderSource) spotOrderSource.cancel();
        spotOrderSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/spotOrderHistory`,
            data,
            cancelToken: spotOrderSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
            error: err.response.data.errors,
        };
    }
};

export const spotTradeHistory = async (data = {}) => {
    try {

        if (spotTradeSource) spotTradeSource.cancel();
        spotTradeSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/spotTradeHistory`,
            data,
            cancelToken: spotTradeSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const perpetualOrderHistory = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/perpetualOrderHistory`,
            params: data,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};

export const perpetualTradeHistory = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/perpetualTradeHistory`,
            params: data,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};
