// import lib
import isEmpty from '../../../lib/isEmpty';

const validation = value => {
    let errors = {};

    if (isEmpty(value.pairId)) {
        errors.pairId = "Pair field is Required"
    }

    if (isEmpty(value.side)) {
        errors.side = "Side field is Required"
    } else if (!Array.isArray(value.side)) {
        errors.side = "Type field is required";
    } else if (!(value.side.some(r => ['buy', 'sell'].includes(r)))) {
        errors.side = "invalid side"
    }

    if (value.side && value.side.includes('buy')) {
        if (isEmpty(value.buyStartPricePerc)) {
            errors.buyStartPricePerc = "Start Price percentage field is Required"
        } else if (isNaN(value.buyStartPricePerc)) {
            errors.buyStartPricePerc = "Start Price percentage only numeric value"
        } else if (parseFloat(value.buyStartPricePerc) <= 0) {
            errors.buyStartPricePerc = "Please enter a valid percentage"
        } else if (parseFloat(value.buyStartPricePerc) >= 100){
            errors.buyStartPricePerc = "Percentage value should be 1-99."
        }

        if (isEmpty(value.buyEndPricePerc)) {
            errors.buyEndPricePerc = "End Price percentage field is Required"
        } else if (isNaN(value.buyEndPricePerc)) {
            errors.buyEndPricePerc = "End Price percentage only numeric value"
        } else if (parseFloat(value.buyEndPricePerc) <= 0) {
            errors.buyEndPricePerc = "Please enter a valid percentage"
        } else if (parseFloat(value.buyEndPricePerc) <= parseFloat(value.buyStartPricePerc)) {
            errors.buyEndPricePerc = "End price percentage should be greater than start price percentage"
        } else if (parseFloat(value.buyEndPricePerc) >= 100){
            errors.buyEndPricePerc = "Percentage value should be 1-99."
        }
    }

    if (value.side && value.side.includes('sell')) {
        if (isEmpty(value.sellStartPricePerc)) {
            errors.sellStartPricePerc = "Start Price percentage field is Required"
        } else if (isNaN(value.sellStartPricePerc)) {
            errors.sellStartPricePerc = "Start Price percentage only numeric value"
        } else if (parseFloat(value.sellStartPricePerc) <= 0) {
            errors.sellStartPricePerc = "Please enter a valid percentage"
        } else if (parseFloat(value.sellStartPricePerc) >= 100){
            errors.sellStartPricePerc = "Percentage value should be 1-99."
        }

        if (isEmpty(value.sellEndPricePerc)) {
            errors.sellEndPricePerc = "End Price percentage field is Required"
        } else if (isNaN(value.sellEndPricePerc)) {
            errors.sellEndPricePerc = "End Price percentage only numeric value"
        } else if (parseFloat(value.sellEndPricePerc) <= 0) {
            errors.sellEndPricePerc = "Please enter a valid percentage"
        } else if (parseFloat(value.sellEndPricePerc) >= parseFloat(value.sellStartPricePerc)) {
            errors.sellEndPricePerc = "End price percentage should be lesser than start price percentages"
        } else if (parseFloat(value.sellEndPricePerc) >= 100){
            errors.sellEndPricePerc = "Percentage value should be 1-99."
        }
    }

    if (isEmpty(value.startQuantity)) {
        errors.startQuantity = "Start Quantity field is Required"
    } else if (isNaN(value.startQuantity)) {
        errors.startQuantity = "Start Quantity only numeric value"
    } else if (parseFloat(value.startQuantity) < 0) {
        errors.startQuantity = "Start Quantity only positive numeric value"
    }

    if (isEmpty(value.endQuantity)) {
        errors.endQuantity = "End Quantity field is Required"
    } else if (isNaN(value.endQuantity)) {
        errors.endQuantity = "End Quantity only numeric value"
    } else if (parseFloat(value.endQuantity) < 0) {
        errors.endQuantity = "End Quantity only positive numeric value"
    } else if (parseFloat(value.startQuantity) > parseFloat(value.endQuantity)) {
        errors.endQuantity = "End Quantity should be higher than Start Quantity"
    }

    if (isEmpty(value.count)) {
        errors.count = "Count field is Required"
    } else if (isNaN(value.count)) {
        errors.count = "Count only numeric value"
    } else if (parseFloat(value.count) <= 0) {
        errors.count = "Count only positive numeric value"
    }

    return errors;
}

export const botUsrValid = value => {
    let errors = {};

    if (isEmpty(value.firstName)) {
        errors.firstName = "First Name field is Required"
    }

    if (isEmpty(value.lastName)) {
        errors.lastName = "Last Name field is Required"
    }

    if (isEmpty(value.email)) {
        errors.email = "Email field is Required"
    }

    return errors;
}

export default validation;