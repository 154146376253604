let baseUrl, socketUrl;
console.log("REACT_APP_MODE", process.env.REACT_APP_MODE);
if (process.env.REACT_APP_MODE === "live") {
  baseUrl = "https://api.whitecrypto.in/";
  socketUrl = "https://api.whitecrypto.in/";
} else if (process.env.REACT_APP_MODE === "production") {
  baseUrl = "https://moneyfarmapi.wealwin.com/";
  socketUrl = "https://moneyfarmapi.wealwin.com/";
} else if (process.env.REACT_APP_MODE === "stage") {
  baseUrl = "https://stagingapi.whitecrypto.in/";
  socketUrl = "https://stagingapi.whitecrypto.in/";
}  else {
  baseUrl = "http://localhost:2053/";
  socketUrl = "http://localhost:2053/";
}

module.exports = {
  baseUrl: baseUrl,
  imageUrl: baseUrl,
  socketUrl: socketUrl,
};
