// import package
import React from "react";
import { Modal, Table } from "react-bootstrap";

class ViewTradeBot extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
    }

    render() {
        const { isShow, record } = this.props;

        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                    scrollable={true}
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">View Trade bot</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <tr>
                                <td>Pair</td>
                                <td>{record.pair}</td>
                            </tr>

                            <tr>
                                <td>Side</td>
                                <td>{record.side && record.side.join()}</td>
                            </tr>

                            {
                                record.side && record.side.includes('buy') && <tr>
                                    <td>Buy Start Price (%)</td>
                                    <td>{record.buyStartPricePerc}</td>
                                </tr>
                            }

                            {
                                record.side && record.side.includes('buy') && <tr>
                                    <td>Buy End Price (%)</td>
                                    <td>{record.buyEndPricePerc}</td>
                                </tr>
                            }

                            {
                                record.side && record.side.includes('sell') && <tr>
                                    <td>Sell Start Price (%)</td>
                                    <td>{record.sellStartPricePerc}</td>
                                </tr>
                            }

                            {
                                record.side && record.side.includes('sell') && <tr>
                                    <td>Sell End Price (%)</td>
                                    <td>{record.sellEndPricePerc}</td>
                                </tr>
                            }

                            <tr>
                                <td>Start Quantity</td>
                                <td>{record.startQuantity}</td>
                            </tr>

                            <tr>
                                <td>End Quantity</td>
                                <td>{record.endQuantity}</td>
                            </tr>

                            <tr>
                                <td>Count</td>
                                <td>{record.count}</td>
                            </tr>

                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default ViewTradeBot;