import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { adsList } from "../../actions/p2pAction";

// import library
import { firstLetterCase } from "../../lib/capitalize";

class P2PAds extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "orderDate",
                text: "Date & Time",
                className: "date",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.orderDate).format("DD-MM-YYYY HH:mm:ss");
                },
            },
            {
                key: "userId",
                text: "Email",
                className: "userId",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.userId ? record.userId.email : "-";
                },
            },

            {
                key: "BuyorSell",
                text: "Trade Type",
                className: "userId",
                align: "left",
                sortable: true,
            },

            {
                key: "firstCurrency",
                text: "Currency Pair",
                className: "userId",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.currencyPair;
                },
            },

            {
                key: "price",
                text: "Price",
                className: "userId",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.priceString;
                },
            },

            {
                key: "quantity",
                text: "Quantity",
                className: "userId",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.quantityString;
                },
            },

            {
                key: "limit",
                text: "Limit",
                className: "userId",
                align: "left",
                sortable: true,
            },

            {
                key: "status",
                text: "Status",
                className: "userId",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.status == 0 ? "Open": record.status == 1 ? "Completed": record.status == 2 ? "Partial": record.status == 3 ? "Cancel": record.status == 5 ? "Closed": "";
                },
            },
        ];

        this.state = {
            records: [],
            totalCount: 0,
            offset: 0,
            pageSize: 10,
            sortOrder: {
                column: "orderDate",
                order: "desc",
            },
            loading: true,
            exportType: "current",
            extraButtons: [{
                className: "btn btn-primary buttons-pdf",
                title: "Export PDF",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
                    </span>,
                ],
                onClick: (event) => {
                    this.exportPDF();
                },
            },
            {
                className: "btn btn-primary buttons-csv",
                title: "Export CSV",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    this.exportCSV();
                },
            }]
        };

        this.config = {
            page_size: this.state.pageSize,
            sort: this.state.sortOrder,
            length_menu: [10, 20, 50],
            no_data_text: "No Record found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            key_column: "_id",
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            pagination: "advance",
            show_info: true,
            button: {
                extra: true,
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    async getData(filter = "") {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                sortOrder: this.state.sortOrder,
                filter,
                limit: this.state.pageSize,
                offset: this.state.offset,
            };
            const { status, result, totalCount } = await adsList(data);
            if (status == "success") {
                this.setState({ loading: false });
                this.setState({ totalCount, records: result });
            }
        } catch (err) {}
    }

    search = data => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number-1)*pageSize;
        let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
            this.getData(filter);
        }); 
    }

    exportCSV = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await adsList(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];
        let separator = ";";
        let headers = [
            "Date & Time",
            "Email",
            "Trade Type",
            "Currency Pair",
            "Price",
            "Quantity",
            "Limit",
            "Status",
        ];

        let rows = [];
        records.length > 0 && records.map((elt) => rows.push({
            "Date & Time": moment(elt.orderDate).format("DD-MM-YYYY HH:mm"),
            "Email": elt.userId.email,
            "Trade Type": elt.BuyorSell,
            "Currency Pair": elt.currencyPair,
            "Price": elt.priceString,
            "Quantity": elt.quantityString,
            "Limit": elt.limit,
            "Status": elt.status == 0 ? "Open": elt.status == 1 ? "Completed": elt.status == 2 ? "Partial": elt.status == 3 ? "Cancel": elt.status == 5 ? "Closed": "",
        }));

        let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
            headers.map((k) => {
                let cell = row[k] === null || row[k] === undefined ? "" : row[k];

                cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator)
        ).join("\n")}`;

        let title = "P2P Ads";
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // In case of IE 10+
            navigator.msSaveBlob(blob, title);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", title);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    exportPDF = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await adsList(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];

        let unit = "pt";
        let size = "A4"; // Use A1, A2, A3 or A4
        let orientation = "landscape"; // portrait or landscape

        let marginLeft = 40;
        let doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);

        let title = "P2P Ads";
        let headers = [[
            "Date & Time",
            "Email",
            "Trade Type",
            "Currency Pair",
            "Price",
            "Quantity",
            "Limit",
            "Status",
        ]];

        let downloadData = records.length > 0 && records.map((elt) => [
            moment(elt.orderDate).format("DD-MM-YYYY HH:mm"),
            elt.userId.email,
            elt.BuyorSell,
            elt.currencyPair,
            elt.priceString,
            elt.quantityString,
            elt.limit,
            elt.status == 0 ? "Open": elt.status == 1 ? "Completed": elt.status == 2 ? "Partial": elt.status == 3 ? "Cancel": elt.status == 5 ? "Closed": "",
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: downloadData,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("P2P Ads.pdf");
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <div className="float-xl-right">
                                Export
                                {["current", "all"].map((v) => (
                                    <Form.Check
                                        inline
                                        type="radio"
                                        name="exportType"
                                        value={v}
                                        key={v}
                                        onChange={(e) => this.setState({ exportType: e.target.value })}
                                        defaultChecked={v == this.state.exportType}
                                        label={firstLetterCase(v)}
                                    />
                                ))}
                            </div>
                            <h3 className="mt-2 text-secondary">
                                P2P Ads
                            </h3>
                            <div style={{ overflow: "scroll" }}>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.totalCount}
                                    loading={this.state.loading}
                                    onChange={this.search}
                                    extraButtons={this.state.extraButtons}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default P2PAds;