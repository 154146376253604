import React, { Component } from "react";
import classnames from "classnames";
import Spinner from "react-bootstrap/Spinner";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import {
  getCopyTradeSettings,
  updateCopyTradeSettings,
} from "../../actions/copyTradeAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";

class CopyTradeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      minimumDeposit: "",
      maximumFollowers: "",
      minimumCommission: "",
      maximumCommission: "",
      reduceCommissions: [
        { days: 7, reduce: 50 },
        { days: 10, reduce: 3 },
      ],
      adminFee: "",
      adminFeeEach: "",
      warnDays: "",
      banDays: "",
      autoUnFolDays: "",
      maximumTraders: "",
      minimumFolDeposit: "",
      minimumAllocationInUSDT: "",
      minimumAllocationEachInUSDT: "",
      minimumAllocationInINR: "",
      minimumAllocationEachInINR: "",
      errors: {},
      loading: false,
      loader: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (e) => {
    try {
      this.setState({ loading: true });
      let { status, loading, result } = await getCopyTradeSettings();
      if (status) {
        this.setState({
          id: result._id,
          minimumDeposit: result.minimumDeposit,
          maximumFollowers: result.maximumFollowers,
          minimumCommission: result.minimumCommission,
          maximumCommission: result.maximumCommission,
          reduceCommissions: result.reduceCommissions,
          adminFee: result.adminFee,
          adminFeeEach: result.adminFeeEach,
          warnDays: result.warnDays,
          banDays: result.banDays,
          autoUnFolDays: result.autoUnFolDays,
          maximumTraders: result.maximumTraders,
          minimumFolDeposit: result.minimumFolDeposit,
          minimumAllocationInUSDT: result.minimumAllocationInUSDT,
          minimumAllocationEachInUSDT: result.minimumAllocationEachInUSDT,
          minimumAllocationInINR: result.minimumAllocationInINR,
          minimumAllocationEachInINR: result.minimumAllocationEachInINR,
        }, function () {
          this.setState({ loading });
        });
      } else {
        this.setState({ loading });
      }
    } catch (err) {}
  };

  updateSetting = async (e) => {
    e.preventDefault();

    try {
      let data = {
        id: this.state.id,
        minimumDeposit: this.state.minimumDeposit,
        maximumFollowers: this.state.maximumFollowers,
        minimumCommission: this.state.minimumCommission,
        maximumCommission: this.state.maximumCommission,
        reduceCommissions: this.state.reduceCommissions,
        adminFee: this.state.adminFee,
        adminFeeEach: this.state.adminFeeEach,
        warnDays: this.state.warnDays,
        banDays: this.state.banDays,
        autoUnFolDays: this.state.autoUnFolDays,
        maximumTraders: this.state.maximumTraders,
        minimumFolDeposit: this.state.minimumFolDeposit,
        minimumAllocationInUSDT: this.state.minimumAllocationInUSDT,
        minimumAllocationEachInUSDT: this.state.minimumAllocationEachInUSDT,
        minimumAllocationInINR: this.state.minimumAllocationInINR,
        minimumAllocationEachInINR: this.state.minimumAllocationEachInINR,
      };
      this.setState({ loader: true });
      let { status, loading, message, error } = await updateCopyTradeSettings(data);
      this.setState({ loader: loading });
      if (status) {
        toastAlert("success", message, "addTemplate");
      } else {
        if (error) {
          this.setState({ errors: error });
        }
        toastAlert("error", message, "addTemplate");
      }
    } catch (err) {}
  };

  onChange = (e) => {
    let errors = { ...this.state.errors, ...{ [e.target.id]: "" } };
    this.setState({ errors: errors });
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChange = (e) => {
    let { id, value } = e.target;
    let idArray = id.split("_");
    let key = idArray[1];
    let name = idArray[0];
    let reduceCommissions =  this.state.reduceCommissions;
    reduceCommissions[key][name] = value;
    this.setState({ reduceCommissions: reduceCommissions });

    let errors = { ...this.state.errors, ...{ [e.target.id]: "" } };
    this.setState({ errors: errors });
  };

  validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  validInt = (e) => {
    if (new RegExp(`^\\d*?$`).test(e.target.value) || (e.target.value = "")) {
      e.preventDefault();
    }
  };

  render() {
    const {
      minimumDeposit,
      maximumFollowers,
      minimumCommission,
      maximumCommission,
      reduceCommissions,
      adminFee,
      adminFeeEach,
      autoUnFolDays,
      maximumTraders,
      warnDays,
      banDays,
      minimumFolDeposit,
      minimumAllocationInUSDT,
      minimumAllocationEachInUSDT,
      minimumAllocationInINR,
      minimumAllocationEachInINR,
      errors,
      loader,
      loading,
    } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              {loading ? (
                <div className="row mt-1">
                  <div className="col-md-6 text-center">
                    <Spinner animation="border" size="sm" />
                  </div>
                </div>
              ) : (
                <>
                  <form
                    noValidate
                    onSubmit={this.updateSetting}
                    id="update-profile"
                  >

                    <h3 className="mt-2 text-secondary">Become A Trader Settings</h3>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="minimumDeposit">
                          Minimum Balance (USDT)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={minimumDeposit}
                          id="minimumDeposit"
                          type="text"
                          error={errors.minimumDeposit}
                          className={classnames("form-control", {
                            invalid: errors.minimumDeposit,
                          })}
                        />
                        <span className="text-danger">
                          {errors.minimumDeposit}
                        </span>
                      </div>
                    </div>

                    <h3 className="mt-2 text-secondary">Trader Settings</h3>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="maximumFollowers">
                          Maximum Followers
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={maximumFollowers}
                          id="maximumFollowers"
                          type="text"
                          error={errors.maximumFollowers}
                          className={classnames("form-control", {
                            invalid: errors.maximumFollowers,
                          })}
                        />
                        <span className="text-danger">
                          {errors.maximumFollowers}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="minimumCommission">
                          Minimum Trader Commission (%)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={minimumCommission}
                          id="minimumCommission"
                          type="text"
                          error={errors.minimumCommission}
                          className={classnames("form-control", {
                            invalid: errors.minimumCommission,
                          })}
                        />
                        <span className="text-danger">
                          {errors.minimumCommission}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="maximumCommission">
                          Maximum Trader Commission (%)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={maximumCommission}
                          id="maximumCommission"
                          type="text"
                          error={errors.maximumCommission}
                          className={classnames("form-control", {
                            invalid: errors.maximumCommission,
                          })}
                        />
                        <span className="text-danger">
                          {errors.maximumCommission}
                        </span>
                      </div>
                    </div>

                    {reduceCommissions.map((reduceCommission, i) => {
                      return (
                        <div className="row mt-2" key={i}>
                          <div className="col-md-3">
                            {i === 0 && (
                              <label htmlFor="reduceCommission">
                                Reduce Commissions (%)
                              </label>
                            )}
                          </div>
                          <div className="col-md-1">
                            <label htmlFor={`days_${i}`}>After</label>
                          </div>
                          <div className="col-md-1">
                            <input
                              onInput={this.validPositive}
                              onChange={this.handleChange}
                              value={reduceCommission.days}
                              id={`days_${i}`}
                              type="text"
                              error={errors[`days_${i}`]}
                              className={classnames("form-control", {
                                invalid: errors[`days_${i}`],
                              })}
                            />
                            <span className="text-danger">
                              {errors[`days_${i}`]}
                            </span>
                          </div>
                          <div className="col-md-1">
                            <label htmlFor={`reduce_${i}`}>Days</label>
                          </div>
                          <div className="col-md-1">
                            <input
                              onInput={this.validPositive}
                              onChange={this.handleChange}
                              value={reduceCommission.reduce}
                              id={`reduce_${i}`}
                              type="text"
                              error={errors[`reduce_${i}`]}
                              className={classnames("form-control", {
                                invalid: errors[`reduce_${i}`],
                              })}
                            />
                            <span className="text-danger">
                              {errors[`reduce_${i}`]}
                            </span>
                          </div>
                          <div className="col-md-1">
                            <label htmlFor={`reduce_${i}`}>%</label>
                          </div>
                        </div>
                      );
                    })}


                    <div className="row mt-2">
                      <div className="col-md-3">
                          <label htmlFor="adminCommission">
                            Admin Commission (%)
                          </label>
                      </div>
                      <div className="col-md-1">
                        <label htmlFor="adminFee">Take</label>
                      </div>
                      <div className="col-md-1">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={adminFee}
                          id="adminFee"
                          type="text"
                          error={errors["adminFee"]}
                          className={classnames("form-control", {
                            invalid: errors["adminFee"],
                          })}
                        />
                        <span className="text-danger">
                          {errors["adminFee"]}
                        </span>
                      </div>
                      <div className="col-md-1">
                        <label htmlFor="adminFeeEach">Each</label>
                      </div>
                      <div className="col-md-1">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={adminFeeEach}
                          id="adminFeeEach"
                          type="text"
                          error={errors["adminFeeEach"]}
                          className={classnames("form-control", {
                            invalid: errors["adminFeeEach"],
                          })}
                        />
                        <span className="text-danger">
                          {errors["adminFeeEach"]}
                        </span>
                      </div>
                      <div className="col-md-1">
                        <label htmlFor={"adminFeeEach"}>%</label>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="warnDays">
                          Trader Warning (In Days)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validInt}
                          onChange={this.onChange}
                          value={warnDays}
                          id="warnDays"
                          type="text"
                          error={errors.warnDays}
                          className={classnames("form-control", {
                            invalid: errors.warnDays,
                          })}
                        />
                        <span className="text-danger">
                          {errors.warnDays}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="banDays">
                          Trader Ban (In Days)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validInt}
                          onChange={this.onChange}
                          value={banDays}
                          id="banDays"
                          type="text"
                          error={errors.banDays}
                          className={classnames("form-control", {
                            invalid: errors.banDays,
                          })}
                        />
                        <span className="text-danger">
                          {errors.banDays}
                        </span>
                      </div>
                    </div>

                    <h3 className="mt-2 text-secondary">Follower Settings</h3>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="minimumFolDeposit">
                          Minimum Balance (USDT)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={minimumFolDeposit}
                          id="minimumFolDeposit"
                          type="text"
                          error={errors.minimumFolDeposit}
                          className={classnames("form-control", {
                            invalid: errors.minimumFolDeposit,
                          })}
                        />
                        <span className="text-danger">
                          {errors.minimumFolDeposit}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="minimumAllocationInUSDT">
                          Minimum Allocation (USDT)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={minimumAllocationInUSDT}
                          id="minimumAllocationInUSDT"
                          type="text"
                          error={errors.minimumAllocationInUSDT}
                          className={classnames("form-control", {
                            invalid: errors.minimumAllocationInUSDT,
                          })}
                        />
                        <span className="text-danger">
                          {errors.minimumAllocationInUSDT}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="minimumAllocationEachInUSDT">
                          Minimum Allocation Each (USDT)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={minimumAllocationEachInUSDT}
                          id="minimumAllocationEachInUSDT"
                          type="text"
                          error={errors.minimumAllocationEachInUSDT}
                          className={classnames("form-control", {
                            invalid: errors.minimumAllocationEachInUSDT,
                          })}
                        />
                        <span className="text-danger">
                          {errors.minimumAllocationEachInUSDT}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="minimumAllocationInINR">
                          Minimum Allocation (INR)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={minimumAllocationInINR}
                          id="minimumAllocationInINR"
                          type="text"
                          error={errors.minimumAllocationInINR}
                          className={classnames("form-control", {
                            invalid: errors.minimumAllocationInINR,
                          })}
                        />
                        <span className="text-danger">
                          {errors.minimumAllocationInINR}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="minimumAllocationEachInINR">
                          Minimum Allocation Each (INR)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={minimumAllocationEachInINR}
                          id="minimumAllocationEachInINR"
                          type="text"
                          error={errors.minimumAllocationEachInINR}
                          className={classnames("form-control", {
                            invalid: errors.minimumAllocationEachInINR,
                          })}
                        />
                        <span className="text-danger">
                          {errors.minimumAllocationEachInINR}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="autoUnFolDays">
                          Auto Unfollow (In Days)
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={autoUnFolDays}
                          id="autoUnFolDays"
                          type="text"
                          error={errors.autoUnFolDays}
                          className={classnames("form-control", {
                            invalid: errors.autoUnFolDays,
                          })}
                        />
                        <span className="text-danger">
                          {errors.autoUnFolDays}
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="maximumTraders">
                          Maximum Traders
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onInput={this.validPositive}
                          onChange={this.onChange}
                          value={maximumTraders}
                          id="maximumTraders"
                          type="text"
                          error={errors.maximumTraders}
                          className={classnames("form-control", {
                            invalid: errors.maximumTraders,
                          })}
                        />
                        <span className="text-danger">
                          {errors.maximumTraders}
                        </span>
                      </div>
                    </div>
                  </form>
                  <br />
                  <button
                    form="update-profile"
                    type="submit"
                    className="btn btn-primary"
                    disabled={loader}
                  >
                    Update
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CopyTradeSettings;