let key = {};
// if (process.env.NODE_ENV === "production") {
if (process.env.REACT_APP_MODE === "live") {
    console.log("Set live Config");
    const API_URL = "https://api.whitecrypto.in";

    key = {
        secretOrKey: "FxUum76z",
        Recaptchakey: "6Lf-oQAaAAAAAHsxE5WyOHwmKav2lOfeL5KeA7AV", //local
        API_URL: `${API_URL}`,
        FRONT_URL: "https://whitecrypto.in",
        ADMIN_URL: "https://controls.whitecrypto.in",
        getGeoInfo: "https://ipapi.co/json/",
        BINANCE: {
            Email: "harsh261530@gmail.com",
            PhoneNo: "9510450791",
        },
        WAZIRX: {
            Email: "praj29685@gmail.com",
            PhoneNo: "7383307137",
        },
    };
} else if (process.env.REACT_APP_MODE === "production") {
    console.log("Set Production Config");
    const API_URL = "https://whitecryptoapi.wealwin.com";

    key = {
        secretOrKey: "FxUum76z",
        Recaptchakey: "6Lf-oQAaAAAAAHsxE5WyOHwmKav2lOfeL5KeA7AV", //local
        API_URL: `${API_URL}`,
        FRONT_URL: "https://whitecrypto-frontend.pages.dev",
        ADMIN_URL: "https://whitecrypto-controls.pages.dev",
        getGeoInfo: "https://ipapi.co/json/",
        BINANCE: {
            Email: "sampleone@yopmail.com",
            PhoneNo: "1234567891",
        },
        WAZIRX: {
            Email: "praj29685@gmail.com",
            PhoneNo: "7383307137",
        },
    };
} else if (process.env.REACT_APP_MODE === "stage") {
    console.log("Set Production Config");
    const API_URL = "https://stagingapi.whitecrypto.in";

    key = {
        secretOrKey: "FxUum76z",
        Recaptchakey: "6Lf-oQAaAAAAAHsxE5WyOHwmKav2lOfeL5KeA7AV", //local
        API_URL: `${API_URL}`,
        FRONT_URL: "https://staging.whitecrypto.in",
        ADMIN_URL: "https://stagingcontrols.whitecrypto.in",
        getGeoInfo: "https://ipapi.co/json/",
        BINANCE: {
            Email: "harsh261530@gmail.com",
            PhoneNo: "9510450791",
        },
        WAZIRX: {
            Email: "praj29685@gmail.com",
            PhoneNo: "7383307137",
        },
    };
} else {
    console.log("Set Development Config");
    const API_URL = "http://localhost";
    key = {
        secretOrKey: "FxUum76z",
        Recaptchakey: "6LdpeoQUAAAAAHwFEDfpcA-W5-leSH8548lZWWeb", //local
        API_URL: `${API_URL}:2053`,
        TRADE_URL: 'http://54.211.230.83:8081/api/trade',
        FRONT_URL: 'http://localhost:3000',
        ADMIN_URL: 'http://localhost:3001',
        getGeoInfo: "https://ipapi.co/json/",
        BINANCE: {
            Email: "jagatheesh.wealwin@gmail.com",
            PhoneNo: "1234567891",
        },
        WAZIRX: {
            Email: "praj29685@gmail.com",
            PhoneNo: "7383307137",
        },
        socialMedia: {
            facebook: {
                appId: "1034988646970193",
            },
            linkedIn: {
                clientId: "78szlpfkw7ee7s",
                redirectUrl: "https://99893158a13c.ngrok.io/signup",
                oauthUrl:
                    "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
                scope: "r_liteprofile%20r_emailaddress",
                state: "123456",
            },
        },
    };
}

export default key;
