import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import jsPDF from "jspdf";
import moment from "moment";
import { Form } from "react-bootstrap";

//import components
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//import actions
import { copyOrderHistory } from "../../actions/copyTradeAction";

// import library
import { firstLetterCase } from "../../lib/capitalize";

class CopyOrderHistory extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "orderDate",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return moment(record.orderDate).format("DD-MM-YYYY HH:mm");
        },
      },
      {
        key: "userId.email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.userId && record.userId.email ? record.userId.email : "-";
        },
      },

      {
        key: "userId.phoneNo",
        text: "Phone No",
        className: "phoneNo",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.userId && record.userId.phoneNo ? record.userId.phoneNo : "-";
        },
      },

      {
        key: "firstCurrency",
        text: "Base Currency",
        className: "pairName",
        align: "left",
        sortable: true,
      },
      {
        key: "secondCurrency",
        text: "Quote Currency",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "orderType",
        text: "Type",
        className: "Type",
        align: "left",
        sortable: true,
      },
      {
        key: "buyorsell",
        text: "Side",
        className: "Side",
        align: "left",
        sortable: true,
      },
      {
        key: "averagePrice",
        text: "Average",
        className: "Average",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.averagePrice
            ? parseFloat(parseFloat(record.averagePrice).toFixed(8))
            : "-";
        },
      },
      {
        key: "price",
        text: "Price",
        className: "Price",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.orderType == "limit"
            ? record.price
              ? parseFloat(parseFloat(record.price).toFixed(8))
              : "-"
            : "Market";
        },
      },

      {
        key: "filledQuantity",
        text: "Executed",
        className: "Excuted",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.filledQuantity
            ? parseFloat(parseFloat(record.filledQuantity).toFixed(8))
            : "-";
        },
      },
      {
        key: "quantity",
        text: "Amount",
        className: "Amount",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.quantity
            ? parseFloat(parseFloat(record.quantity).toFixed(8))
            : "-";
        },
      },
      {
        key: "orderValue",
        text: "Total",
        className: "Total",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.orderValue
            ? parseFloat(parseFloat(record.orderValue).toFixed(8))
            : "-";
        },
      },
      {
        key: "status",
        text: "Status",
        className: "Status",
        align: "left",
        sortable: true,
        cell: (record) => {
          return firstLetterCase(record.status);
        },
      },
      {
        key: "traderId",
        text: "Trader",
        className: "trader",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.traderId ? record.traderId.name : "-";
        },
      },
    ];

    this.state = {
      records: [],
      totalCount: 0,
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "orderDate",
        order: "desc",
      },
      loading: true,
      exportType: "current",
      extraButtons: [{
        className: "btn btn-primary buttons-pdf",
        title: "Export PDF",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportPDF();
        }
      },
      {
        className: "btn btn-primary buttons-csv",
        title: "Export CSV",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportCSV();
        }
      }]
    };

    this.config = {
      page_size: this.state.pageSize,
      sort: this.state.sortOrder,
      length_menu: [10, 20, 50],
      no_data_text: "No Records found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "_id",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      pagination: "advance",
      show_info: true,
      button: {
        extra: true,
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData(filter = "") {
    try {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let data = {
        timezone,
        sortOrder: this.state.sortOrder,
        filter,
        limit: this.state.pageSize,
        offset: this.state.offset
      };
      const { status, result, totalCount } = await copyOrderHistory(data);
      if (status == "success") {
        this.setState({ loading: false });
        this.setState({ records: [], totalCount: 0 }, function() {
          this.setState({ totalCount, records: result });
        });
      }
    } catch (err) {}
  }

  exportCSV = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await copyOrderHistory(data);
    
    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];
    let separator = ";";
    let headers = [
      "Date",
      "Email",
      "Phone No",
      "Base Currency",
      "Quote Currency",
      "Type",
      "side",
      "Average",
      "price",
      "Executed",
      "Amount",
      "Total",
      "Trader",
    ];

    let rows = [];
    records.length > 0 && records.map((elt) => rows.push({
      "Date": moment(elt.orderDate).format("DD-MM-YYYY HH:mm"),
      "Email": elt.userId && elt.userId.email ? elt.userId.email:"-",
      "Phone No": elt.userId && elt.userId.phoneNo ? elt.userId.phoneNo:"-",
      "Base Currency": elt.firstCurrency,
      "Quote Currency": elt.secondCurrency,
      "Type": elt.orderType,
      "Side": elt.buyorsell,
      "Average": elt.averagePrice ? parseFloat(parseFloat(elt.averagePrice).toFixed(8)):0,
      "Price": elt.orderType == "limit" ? elt.price ? parseFloat(parseFloat(elt.price).toFixed(8)): 0: "Market",
      "Executed": elt.filledQuantity ? parseFloat(parseFloat(elt.filledQuantity).toFixed(8)) : 0,
      "Amount": elt.quantity ? parseFloat(parseFloat(elt.quantity).toFixed(8)):0,
      "Total": elt.orderValue ? parseFloat(parseFloat(elt.orderValue).toFixed(8)):0,
      "Status": firstLetterCase(elt.status),
      "Trader": elt.traderId ? elt.traderId.name : "-",
    }));

    let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
      headers.map((k) => {
        let cell = row[k] === null || row[k] === undefined ? "" : row[k];

        cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator)
    ).join("\n")}`;

    let title = "Copy Order History";
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // In case of IE 10+
      navigator.msSaveBlob(blob, title);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", title);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  async exportPDF() {
    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await copyOrderHistory(data);
    
    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
          <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];

    let unit = "pt";
    let size = "A4"; // Use A1, A2, A3 or A4
    let orientation = "landscape"; // portrait or landscape

    let marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title = "Copy Order History";
    let headers = [[
      "Date",
      "Email",
      "Phone No",
      "Base Currency",
      "Quote Currency",
      "Type",
      "side",
      "Average",
      "price",
      "Executed",
      "Amount",
      "Total",
      "Trader",
    ]];

    let downloadData = records.length > 0 && records.map((elt) => [
      moment(elt.orderDate).format("DD-MM-YYYY HH:mm"),
      elt.userId && elt.userId.email ? elt.userId.email : "-",
      elt.userId && elt.userId.phoneNo ? elt.userId.phoneNo : "-",
      elt.firstCurrency,
      elt.secondCurrency,
      elt.orderType,
      elt.buyorsell,
      elt.averagePrice
        ? parseFloat(parseFloat(elt.averagePrice).toFixed(8))
        : "-",
      elt.orderType == "limit" ? elt.price ? parseFloat(parseFloat(elt.price).toFixed(8)): 0: "Market",
      elt.filledQuantity
        ? parseFloat(parseFloat(elt.filledQuantity).toFixed(8))
        : "-",
      elt.quantity ? parseFloat(parseFloat(elt.quantity).toFixed(8)) : "-",
      elt.orderValue
        ? parseFloat(parseFloat(elt.orderValue).toFixed(8))
        : "-",
      firstLetterCase(elt.status),
      elt.traderId ? elt.traderId.name : "-",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: downloadData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Copy Order History.pdf");
  }

  search = data => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number-1)*pageSize;
    let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
    this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
      this.getData(filter);
    }); 
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="float-xl-right">
                Export
                {["current", "all"].map((v) => (
                  <Form.Check
                    inline
                    type="radio"
                    name="exportType"
                    value={v}
                    key={v}
                    onChange={(e) => this.setState({ exportType: e.target.value })}
                    defaultChecked={v == this.state.exportType}
                    label={firstLetterCase(v)}
                  />
                ))}
              </div>
              <h3 className="mt-2 text-secondary">Copy Order History</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={this.state.totalCount}
                loading={this.state.loading}
                onChange={this.search}
                extraButtons={this.state.extraButtons}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CopyOrderHistory;