import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";

//import compoents
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import PairAddModal from "../partials/PairAddModal";
import PairUpdateModal from "../partials/PairUpdateModal";

//import action
import { spotPairList } from "../../actions/tradePairAction";
import { getcurrencyList } from "../../actions/currency";

// import library
import { firstLetterCase } from "../../lib/capitalize";

class Pairmanagement extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "firstCurrencySymbol",
        text: "Base Currency",
        className: "firstCurrencySymbol",
        align: "left",
        sortable: true,
      },
      {
        key: "secondCurrencySymbol",
        text: "Quote Currency",
        className: "secondCurrencySymbol",
        align: "left",
        sortable: true,
      },
      {
        key: "botstatus",
        text: "Botstatus",
        className: "botstatus",
        align: "left",
        sortable: true,
      },
      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: true,
        cell: (record) => {
          return firstLetterCase(record.status);
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-spot-modal"
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.state = {
      records: [],
      totalCount: 0,
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "_id",
        order: "desc",
      },
      loading: true,
      exportType: "current",
      extraButtons: [
        {
          className: "btn btn-primary buttons-pdf",
          title: "Export PDF",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-file-pdf-o"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            this.exportPDF();
          },
        },
        {
          className: "btn btn-primary buttons-csv",
          title: "Export CSV",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-file-excel-o"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            this.exportCSV();
          },
        },
      ],
      addFormModal: false,
      editFormModal: false,
      currencyOptions: [],
      editRecord: {},
    };

    this.config = {
      page_size: this.state.limit,
      sort: this.state.sortOrder,
      length_menu: [10, 20, 50],
      no_data_text: "No Pairs found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "_id",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      pagination: "advance",
      show_info: true,
      button: {
        extra: true,
      },
    };

    this.getData = this.getData.bind(this);
    this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
    this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    this.getCurrencyData = this.getCurrencyData.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getCurrencyData();
  }

  getData = async (filter = "") => {
    try {
      let data = {
        sortOrder: this.state.sortOrder,
        filter,
        limit: this.state.pageSize,
        offset: this.state.offset,
      };
      let { status, totalCount, result } = await spotPairList(data);
      if (status) {
        this.setState({ loading: false });
        this.setState({ records: result, totalCount });
      }
    } catch (e) {}
  };

  async getCurrencyData() {
    let { result, status } = await getcurrencyList();

    if (status == "success") {
      let currencyarray = [];
      result.map((item, i) => {
        let id = item._id;
        let label = item.currencySymbol;
        let obj = { value: id, label: label };
        currencyarray.push(obj);
        this.setState({ currencyOptions: currencyarray });
      });
    }
  }

  editRecord(record) {
    this.setState({
      editFormModal: true,
      editRecord: record,
    });
  }

  addRecord() {
    this.setState({ addFormModal: true });
  }

  handleCloseAddForm() {
    this.setState({ addFormModal: false });
  }

  handleCloseEditForm() {
    this.setState({ editFormModal: false });
  }

  search = data => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number-1)*pageSize;
    let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
    this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
      this.getData(filter);
    });  
  }

  exportCSV = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await spotPairList(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];
    let separator = ";";
    let headers = [
      "Base Currency",
      "Quote Currency",
      "Botstatus",
      "Status"
    ];

    let rows = [];
    records.length > 0 && records.map((elt) => rows.push({
      "Base Currency": elt.firstCurrencySymbol,
      "Quote Currency": elt.secondCurrencySymbol,
      "Botstatus": elt.botstatus,
      "Status": firstLetterCase(elt.status),
    }));

    let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
      headers.map((k) => {
        let cell = row[k] === null || row[k] === undefined ? "" : row[k];

        cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator)
    ).join("\n")}`;

    let title = "Spot Trade Pairs";
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
          // In case of IE 10+
      navigator.msSaveBlob(blob, title);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
              // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", title);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  exportPDF = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await spotPairList(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];

    let unit = "pt";
    let size = "A4"; // Use A1, A2, A3 or A4
    let orientation = "landscape"; // portrait or landscape

    let marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title = "Spot Trade Pairs";
    let headers = [[
      "Base Currency",
      "Quote Currency",
      "Botstatus",
      "Status"
    ]];

    let downloadData = records.length > 0 && records.map((elt) => [
      elt.firstCurrencySymbol,
      elt.secondCurrencySymbol,
      elt.botstatus,
      firstLetterCase(elt.status),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: downloadData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Spot Trade Pairs.pdf");
  }

  render() {
    const { addFormModal, editRecord, editFormModal, currencyOptions } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <PairAddModal
            isShow={addFormModal}
            onHide={this.handleCloseAddForm}
            currencyOptions={currencyOptions}
            fetchData={this.getData}
          />
          <PairUpdateModal
            isShow={editFormModal}
            onHide={this.handleCloseEditForm}
            currencyOptions={currencyOptions}
            fetchData={this.getData}
            record={editRecord}
          />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button
                onClick={() => this.addRecord()}
                className="btn btn-outline-primary float-end mt-3 mr-2"
                data-toggle="modal"
                data-target="#add-spot-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add Pair Page
              </button>
              <div className="float-xl-right">
                Export
                {["current", "all"].map((v) => (
                  <Form.Check
                    inline
                    type="radio"
                    name="exportType"
                    value={v}
                    key={v}
                    onChange={(e) => this.setState({ exportType: e.target.value })}
                    defaultChecked={v == this.state.exportType}
                    label={firstLetterCase(v)}
                  />
                ))}
              </div>
              <h3 className="mt-2 text-secondary">Spot Trade Pairs</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={this.state.totalCount}
                loading={this.state.loading}
                onChange={this.search}
                extraButtons={this.state.extraButtons}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pairmanagement;