// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios from "../config/axios";
import jwt_decode from "jwt-decode";
import Config from "../config/index";

// import lib
import { setAuthToken } from "../lib/localStorage";
import { SET_CURRENT_USER } from "./types";

let loginHistorySource, subAdminsSource;

export const login = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/login`,
            data,
        });

        setAuthToken(respData.data.token);
        // decodeJwt(respData.data.token, dispatch)
        const decoded = jwt_decode(respData.data.token);
        decodeJwt(respData.data.token, dispatch);

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        console.log(err);
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const getGeoInfoData = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `${Config.getGeoInfo}`,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const loginHistory = async (data = {}) => {
    try {
        if (loginHistorySource) loginHistorySource.cancel();
        loginHistorySource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/loginHistory`,
            data,
            cancelToken: loginHistorySource.token,
        });

        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const subAdmins = async (data = {}) => {
    try {
        if (subAdminsSource) subAdminsSource.cancel();
        subAdminsSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/subAdmins`,
            method: "post",
            data,
            cancelToken: subAdminsSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount
        };
    } catch (err) {
        return {
            status: "failed"
        };
    }
};

export const updateAdmin = async (reqData) => {
    try {
        const respData = await axios({
            url: `/adminapi/adminUpdate`,
            method: "post",
            data: reqData,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const addAdmin = async (reqData) => {
    try {
        const respData = await axios({
            url: `/adminapi/adminAdd`,
            method: "post",
            data: reqData,
        });
        console.log(respData, "ddddddddddddd");
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            error: respData.data.errors,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const deleteAdmin = async (reqData) => {
    try {
        const respData = await axios({
            url: `/adminapi/deleteAdmin`,
            method: "post",
            data: reqData,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};