import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

//import lib
import { toastAlert } from "../../lib/toastAlert";
import { AddTDSData } from "../../actions/tdsAction";

const options = [
  { value: "Crypto/INR", label: "Crypto/INR" },
  { value: "Crypto/Crypto", label: "Crypto/Crypto" },
  { value: "P2P", label: "P2P" },
];

const initialFormValue = {
  market: "crypto/inr",
  orderType: "spot",
  tdsratebuy: "0",
  tdsratesell: "0",
  itdsrate_buy: "0",
  itdsrate_sell: "0",
};

class TDSAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      formValue: initialFormValue,
      errors: {},
    };
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    console.log(name, value, "onchange");
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { formValue } = this.state;
      const { fetchData } = this.props;
      let reqData = formValue;
      let { status, error, message } = await AddTDSData(
        reqData
      );
      if (status == "success") {
        fetchData();
        toastAlert("success", message, "addTemplate");
        this.handleClose();
      } else {
        if (error) {
          this.setState({ errors: error });
        }
        toastAlert("error", message, "addSpotPair");
      }
    } catch (err) {}
  };

  render() {
    const { errors } = this.state;
    const {
      market,
      orderType,
      tdsratebuy,
      tdsratesell,
      itdsrate_buy,
      itdsrate_sell,
    } = this.state.formValue;

    const { isShow, currencyOptions } = this.props;
    
    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Add TDS</h4>
          </Modal.Header>
          <Modal.Body>
            <form noValidate id="add-spot">

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="OrderType">Order Type</label>
                </div>

                <div className="col-md-9">
                  <div class="form-check">
                  <input
                    id="spot"
                    value="spot"
                    name="orderType"
                    type="radio"
                    onChange={this.handleChange}
                    />
                    &nbsp;
                    <label class="form-check-label" for="spot">
                    Spot
                    </label>
                      
                  </div>

                  <div class="form-check">
                  <input
                    id="p2p"
                    value="p2p"
                    name="orderType"
                    type="radio"
                    onChange={this.handleChange}
                    />
                    &nbsp;
                    <label class="form-check-label" for="p2p">
                        P2P
                    </label>

                  </div>
                  <span className="text-danger">{errors.orderType}</span>
                </div>

              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="Market">Market</label>
                </div>

                <div className="col-md-9">

                  <div class="form-check">
                  <input
                    id="crypto/inr"
                    value="crypto/inr"
                    name="market"
                    type="radio"
                    onChange={this.handleChange}
                    />
                    &nbsp;
                    <label class="form-check-label" for="crypto/inr">
                      Crypto/INR
                    </label>

                  </div>
                  <div class="form-check">
                  <input
                    id="crypto/crypto"
                    value="crypto/crypto"
                    name="market"
                    type="radio"
                    onChange={this.handleChange}
                    />
                    &nbsp;
                    <label class="form-check-label" for="crypto/crypto">
                      Crypto/Crypto
                    </label>
                  </div>
                  <span className="text-danger">{errors.market}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="tdsratebuy">TDS Rate Buy (%) </label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={tdsratebuy}
                    name="tdsratebuy"
                    error={errors.tdsratebuy}
                    id="tdsratebuy"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.tdsratebuy,
                    })}
                  />
                  <span className="text-danger">{errors.tdsratebuy}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="tdsratesell">TDS Rate Sell (%)</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={tdsratesell}
                    name="tdsratesell"
                    error={errors.tdsratesell}
                    id="tdsratesell"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.tdsratesell,
                    })}
                  />
                  <span className="text-danger">{errors.tdsratesell}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="itdsrate_buy">TDS Rate Buy(%)</label>
                  <span>If not ITR Filed</span>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={itdsrate_buy}
                    name="itdsrate_buy"
                    error={errors.itdsrate_buy}
                    id="itdsrate_buy"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.itdsrate_buy,
                    })}
                  />
                  <span className="text-danger">{errors.itdsrate_buy}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="itdsrate_sell">TDS Rate Sell (%)</label>
                  <span>If not ITR Filed</span>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={itdsrate_sell}
                    name="itdsrate_sell"
                    error={errors.itdsrate_sell}
                    id="itdsrate_sell"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.itdsrate_sell,
                    })}
                  />
                  <span className="text-danger">{errors.itdsrate_sell}</span>
                </div>
              </div>

            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default TDSAddModal;
