import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// import lib
import isLogin from "../../lib/isLogin";

const ConditionRoute = ({ component: Component, layout: Layout, type, store, menuKey, ...rest }) => {
    const authData = useSelector((state) => state.auth);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (type == "auth" && isLogin() == true) {
                    return <Redirect to="/dashboard" />;
                } else if (type == "private" && isLogin() != true) {
                    return <Redirect to="/login" />;
                }
                return <Component {...props} menuKey={menuKey} />;
            }}
        />
    );
};

export default ConditionRoute;