import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@ashvin27/react-datatable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import FaqAddModal from "../partials/FaqAddModal";
import FaqUpdateModal from "../partials/FaqUpdateModal";

// import action
import { getFaqCategory, faqList } from "../../actions/faqActions";

// import library
import { firstLetterCase } from "../../lib/capitalize";

class FaqPage extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "categoryName",
                text: "Category Name",
                className: "question",
                align: "left",
                sortable: true,
            },
            {
                key: "question",
                text: "Question",
                className: "question",
                align: "left",
                sortable: true,
            },
            {
                key: "answer",
                text: "Answer",
                className: "answer",
                align: "left",
                sortable: true,
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return firstLetterCase(record.status);
                },
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-faq-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                },
            },
        ];

        this.state = {
            addFormModal: false,
            editFormModal: false,
            editRecord: {},
            records: [],
            totalCount: 0,
            offset: 0,
            pageSize: 10,
            sortOrder: {
                column: "_id",
                order: "desc",
            },
            loading: true,
            categoryOption: [],
            exportType: "current",
            extraButtons: [{
                className: "btn btn-primary buttons-pdf",
                title: "Export PDF",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
                    </span>,
                ],
                onClick: (event) => {
                    this.exportPDF();
                },
            },
            {
                className: "btn btn-primary buttons-csv",
                title: "Export CSV",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    this.exportCSV();
                },
            }]
        };

        this.config = {
            page_size: this.state.pageSize,
            length_menu: [10, 20, 50],
            no_data_text: "No Faq found!",
            sort: this.state.sortOrder,
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            key_column: "_id",
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            show_info: true,
            button: {
                extra: true,
            },
        };

        this.getData = this.getData.bind(this);
        this.refetch = this.refetch.bind(this);
        this.fetchFaqCategoryDropDown = this.fetchFaqCategoryDropDown.bind(this);
        this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
        this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    }

    componentDidMount() {
        this.getData();
        this.fetchFaqCategoryDropDown();
    }

    async getData(filter = "") {
        let data = {
            sortOrder: this.state.sortOrder,
            filter,
            limit: this.state.pageSize,
            offset: this.state.offset,
        };
        try {
            const { status, result, totalCount } = await faqList(data);
            if (status == "success") {
                this.setState({ loading: false });
                this.setState({ totalCount, records: result });
            }
        } catch (err) {}
    }

    async fetchFaqCategoryDropDown() {
        try {
            const { status, result } = await getFaqCategory();
            if (status == "success") {
                this.setState({ categoryOption: result });
            }
        } catch (err) {}
    }

    editRecord(record) {
        this.setState({
            editFormModal: true,
            editRecord: record,
        });
    }

    addRecord() {
        this.setState({ addFormModal: true });
    }

    handleCloseAddForm() {
        this.setState({ addFormModal: false });
    }

    handleCloseEditForm() {
        this.setState({ editFormModal: false, editRecord: {} });
    }

    refetch() {
        this.getData();
    }

    search = data => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number-1)*pageSize;
        let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
            this.getData(filter);
        }); 
    }

    exportCSV = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let data = {
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await faqList(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];
        let separator = ";";
        let headers = [
            "Category Name",
            "Question",
            "Answer",
            "Status"
        ];

        let rows = [];
        records.length > 0 && records.map((elt) => rows.push({
            "Category Name": elt.categoryName,
            "Question": elt.question,
            "Answer": elt.answer,
            "Status": firstLetterCase(elt.status)
        }));

        let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
            headers.map((k) => {
                let cell = row[k] === null || row[k] === undefined ? "" : row[k];

                cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator)
        ).join("\n")}`;

        let title = "Faq";
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // In case of IE 10+
            navigator.msSaveBlob(blob, title);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", title);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    async exportPDF() {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let data = {
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });


        let { status, result } = await faqList(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];

        let unit = "pt";
        let size = "A4"; // Use A1, A2, A3 or A4
        let orientation = "landscape"; // portrait or landscape

        let marginLeft = 40;
        let doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);

        let title = "Faq";
        let headers = [[
            "Category Name",
            "Question",
            "Answer",
            "Status"
        ]];

        let downloadData = records.length > 0 && records.map((elt) => [
            elt.categoryName,
            elt.question,
            elt.answer,
            firstLetterCase(elt.status)
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: downloadData,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Faq.pdf");
    }

    render() {
        const {
            addFormModal,
            editFormModal,
            editRecord,
            categoryOption,
        } = this.state;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey} />
                    <FaqAddModal
                        isShow={addFormModal}
                        onHide={this.handleCloseAddForm}
                        categoryOption={categoryOption}
                        fetchData={this.refetch}
                    />
                    <FaqUpdateModal
                        isShow={editFormModal}
                        onHide={this.handleCloseEditForm}
                        fetchData={this.refetch}
                        categoryOption={categoryOption}
                        record={editRecord}
                    />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button
                                onClick={() => this.addRecord()}
                                className="btn btn-outline-primary float-end mt-3 mr-2"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Add FAQ
                            </button>
                            <div className="float-xl-right">
                                Export
                                {["current", "all"].map((v) => (
                                    <Form.Check
                                        inline
                                        type="radio"
                                        name="exportType"
                                        value={v}
                                        key={v}
                                        onChange={(e) => this.setState({ exportType: e.target.value })}
                                        defaultChecked={v == this.state.exportType}
                                        label={firstLetterCase(v)}
                                    />
                                ))}
                            </div>
                            <h3 className="mt-2 text-secondary">FAQ List</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                loading={this.state.loading}
                                onChange={this.search}
                                extraButtons={this.state.extraButtons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqPage;