import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { getBannedTraders, releaseTrader } from "../../actions/copyTradeAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import { firstLetterCase } from "../../lib/capitalize";

class BannedTraders extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "user",
                text: "User",
                className: "identifier",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.user.name;
                },
            },
            {
                key: "bannedMax",
                text: "Max Banned (In Year)",
                className: "bannedMax",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return `${record.bannedMax.count} (${record.bannedMax.year})`;
                },
            },
            {
                key: "curFollow",
                text: "Total Followers",
                className: "followers",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.curFollow;
                },
            },
            {
                key: "lastTradeDate",
                text: "Last Traded Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.lastTradeDate).format("DD-MM-YYYY HH:mm:ss");
                },
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: (record) => {
                    if (record.status == 2) {
                        return (
                            <Fragment>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.release(record, 3)}
                                >
                                    Release
                                </button>
                            </Fragment>
                        );
                    }
                },
            },
        ];

        this.state = {
            totalCount: 0,
            records: [],
            offset: 0,
            pageSize: 10,
            sortOrder: {
                column: "lastTradeDate",
                order: "desc"
            },
            loading: true,
            exportType: "current",
            extraButtons: [{
                className: "btn btn-primary buttons-pdf",
                title: "Export PDF",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
                    </span>,
                ],
                onClick: (event) => {
                    this.exportPDF();
                },
            },
            {
                className: "btn btn-primary buttons-csv",
                title: "Export CSV",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    this.exportCSV();
                },
            }],
        };

        this.config = {
            page_size: this.state.pageSize,
            sort: this.state.sortOrder,
            length_menu: [10, 20, 50],
            no_data_text: "No Traders found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            key_column: "_id",
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            pagination: "advance",
            show_info: true,
            button: {
                extra: true,
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (filter = "") => {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                sortOrder: this.state.sortOrder,
                filter,
                limit: this.state.pageSize,
                offset: this.state.offset,
            };
            let { status, result, totalCount } = await getBannedTraders(data);
            if (status) {
                this.setState({ loading: false });
                this.setState({ records: result, totalCount });
            }
        } catch (err) {}
    };

    search = (data) => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number - 1) * pageSize;
        let sortOrder = data.sort_order ? data.sort_order : this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function () {
            this.getData(filter);
        });
    };

    release = async (record) => {
        if (window.confirm("Are you sure?")) {
            try {
                let data = { id: record._id };
                this.setState({ loader: true });
                let { status, loading, message } = await releaseTrader(data);
                this.setState({ loader: loading });
                if (status) {
                    toastAlert("success", message, "addTemplate");
                } else {
                    toastAlert("error", message, "addTemplate");
                }
                this.getData();
            } catch (err) {}
        }
    };

    exportCSV = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1,
            page: "trader",
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });

        let { status, result } = await getBannedTraders(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[1].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];
        let separator = ";";
        let headers = [
            "User",
            "Max Banned (In Year)",
            "Total Followers",
            "Last Traded Date",
        ];

        let rows = [];
        records.length > 0 && records.map((elt) => rows.push({
            "User": elt.user.name,
            "Max Banned (In Year)": `${elt.bannedMax.count} (${elt.bannedMax.year})`,
            "Total Followers": elt.curFollow,
            "Last Traded Date": elt.lastTradeDate ? moment(elt.lastTradeDate).format("DD-MM-YYYY HH:mm:ss"):"-",
        }));

        let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
            headers.map((k) => {
                let cell = row[k] === null || row[k] === undefined ? "" : row[k];

                cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator)
        ).join("\n")}`;

        let title = "Banned Traders";
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // In case of IE 10+
            navigator.msSaveBlob(blob, title);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", title);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    exportPDF = async () => {

        let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder,
            filter,
            limit: exportType == "current" ? pageSize:totalCount,
            offset: exportType == "current" ? offset:0,
            download: 1,
        };

        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
            return { extraButtons };
        });

        let { status, result } = await getBannedTraders(data);
        
        this.setState((prevState) => {
            let extraButtons = [...prevState.extraButtons];
            extraButtons[0].children = [
                <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
            ];
            return { extraButtons };
        });

        let records = status ? result: [];

        let unit = "pt";
        let size = "A4"; // Use A1, A2, A3 or A4
        let orientation = "landscape"; // portrait or landscape

        let marginLeft = 40;
        let doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);

        let title = "Banned Traders";
        let headers = [[
            "User",
            "Max Banned (In Year)",
            "Total Followers",
            "Last Traded Date",
        ]];

        let downloadData = records.length > 0 && records.map((elt) => [
            elt.user.name,
            `${elt.bannedMax.count} (${elt.bannedMax.year})`,
            elt.curFollow,
            elt.lastTradeDate ? moment(elt.lastTradeDate).format("DD-MM-YYYY HH:mm:ss"):"-",
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: downloadData,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`${title}.pdf`);
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <div className="float-xl-right">
                                Export
                                {["current", "all"].map((v) => (
                                    <Form.Check
                                        inline
                                        type="radio"
                                        name="exportType"
                                        value={v}
                                        key={v}
                                        onChange={(e) => this.setState({ exportType: e.target.value })}
                                        defaultChecked={v == this.state.exportType}
                                        label={firstLetterCase(v)}
                                    />
                                ))}
                            </div>
                            <h3 className="mt-2 text-secondary">
                                Banned Traders List
                            </h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                loading={this.state.loading}
                                onChange={this.search}
                                total_record={this.state.totalCount}
                                extraButtons={this.state.extraButtons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BannedTraders;