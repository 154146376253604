// import config
import axios from "../config/axios";
import config from "../config";
import keys from "./config";
const url = keys.baseUrl;

let tdsTaxSource, userTDSSource;

export const getTDS = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/getTDS`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};

export const AddTDSData = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/tdsadd`,
            method: "post",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
            message: err.response.data.message,
        };
    }
};

export const UpdateTDSData = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/tdsadd`,
            method: "put",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};

export const getUserTDS = async (data = {}) => {
    try {

        if (userTDSSource) userTDSSource.cancel();
        userTDSSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getallusertds`,
            data,
            cancelToken: userTDSSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const UpdateUserTDS = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/TDS`,
            method: "put",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};

//TDS_TAX List
export const getTdsTaxList = async (data = {}) => {
    try {
        if (tdsTaxSource) tdsTaxSource.cancel();
        tdsTaxSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getTdsTaxList`,
            data,
            cancelToken: tdsTaxSource.token,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};
