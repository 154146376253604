import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '@ashvin27/react-datatable';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import StakingAddModal from "../partials/StakingAddModal";
import StakingUpdateModal from "../partials/StakingUpdateModal";

// import action
import { stakingList } from '../../actions/staking';
import { getCurrency } from '../../actions/currency';

class Staking extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "coin",
                text: "Staking Currency",
                className: "stakingName",
                align: "left",
                sortable: true,
                width: 200
            },
            {
                key: "minimumAmount",
                text: "Minimum Amount",
                className: "stakingName",
                align: "left",
                sortable: true,
                width: 200
            },
            {
                key: "maximumAmount",
                text: "Maximum Amount",
                className: "stakingName",
                align: "left",
                sortable: true,
                width: 200
            },
            {
                key: "status",
                text: "status",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-staking-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Staking",
            no_data_text: 'No Stacking found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            addFormModal: false,
            editFormModal: false,
            editRecord: {},
            records: [],
            search: '',
            page: 1,
            limit: 10,
            count: 0,
            loader: false,
            currencyOption: []
        };


        this.fetchStakeList = this.fetchStakeList.bind(this);
        this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
        this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    }

    componentDidMount() {
        this.fetchCurrency()
        this.fetchStakeList()
    };

    async fetchStakeList() {
        try {
            this.setState({ "loader": true })
            const { status, loading, message, result } = await stakingList();
            this.setState({ "loader": loading })
            if (status == 'success') {
                this.setState({ records: result })
            }
        } catch (err) { }
    }

    async fetchCurrency() {
        try {
            const { status, result } = await getCurrency();
            if (status == 'success') {
                this.setState({ currencyOption: result })
            }
        } catch (err) { }
    }

    addRecord() {
        this.setState({ addFormModal: true })
    }

    editRecord(record) {
        this.setState({
            editFormModal: true,
            editRecord: record
        })
    }

    handleCloseAddForm() {
        this.setState({ addFormModal: false })
    }

    handleCloseEditForm() {
        this.setState({ editFormModal: false, editRecord: {} })
    }

    render() {
        const { addFormModal, currencyOption, loader, editFormModal, editRecord } = this.state;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar menuKey={this.props.menuKey} />
                    <StakingAddModal
                        isShow={addFormModal}
                        onHide={this.handleCloseAddForm}
                        currencyOption={currencyOption}
                        fetchData={this.fetchStakeList}
                    />
                    <StakingUpdateModal
                        isShow={editFormModal}
                        onHide={this.handleCloseEditForm}
                        currencyOption={currencyOption}
                        record={editRecord}
                        fetchData={this.fetchStakeList}
                    />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button
                                onClick={() => this.addRecord()}
                                className="btn btn-outline-primary float-end mt-3 mr-2" >
                                <FontAwesomeIcon icon={faPlus} /> Add Staking

                            </button>
                            <h3 className="mt-2 text-secondary"> Staking List</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                loading={loader}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Staking;