import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import action
import { updateCurrency } from "../../actions/currency";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import fileObjectUrl from "../../lib/fileObjectUrl";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  currencyId: "",
  currencyName: "",
  currencySymbol: "",
  type: "crypto",
  withdrawFee: 0,
  minimumWithdraw: 0,
  currencyImage: "",
  bankName: "",
  accountNo: "",
  holderName: "",
  bankcode: "",
  country: "",
  tokenType: "erc20",
  minABI: "",
  contractAddress: "",
  decimals: 0,
  status: "active",
  CoinpaymetNetWorkFee: 0.1,
  isPrimary: false,
};

class CurrencyUpdateModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loader: false,
      formValue: initialFormValue,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { record, imageUrl } = nextProps;
    if (record) {
      if (record.type == "crypto") {
        let formData = {
          currencyId: record._id,
          currencyName: record.currencyName,
          currencySymbol: record.currencySymbol,
          currencyImage: isEmpty(record.currencyImage)
            ? ""
            : imageUrl + record.currencyImage,
          type: record.type,
          withdrawFee: record.withdrawFee,
          minimumWithdraw: record.minimumWithdraw,
          status: record.status,
          CoinpaymetNetWorkFee: isEmpty(record.CoinpaymetNetWorkFee)
            ? 0.1
            : record.CoinpaymetNetWorkFee,
          isPrimary: false,
        };
        this.setState({ formValue: formData });
      } else if (record.type == "fiat") {
        let formData = {
          currencyId: record._id,
          currencyName: record.currencyName,
          currencySymbol: record.currencySymbol,
          currencyImage: isEmpty(record.currencyImage)
            ? ""
            : imageUrl + record.currencyImage,
          type: record.type,
          withdrawFee: record.withdrawFee,
          minimumWithdraw: record.minimumWithdraw,
          bankName: record.bankDetails.bankName,
          accountNo: record.bankDetails.accountNo,
          holderName: record.bankDetails.holderName,
          bankcode: record.bankDetails.bankcode,
          country: record.bankDetails.country,
          status: record.status,
          isPrimary: false,
        };
        this.setState({ formValue: formData });
      } else if (record.type == "token") {
        let formData = {
          currencyId: record._id,
          currencyName: record.currencyName,
          currencySymbol: record.currencySymbol,
          currencyImage: isEmpty(record.currencyImage)
            ? ""
            : imageUrl + record.currencyImage,
          type: record.type,
          tokenType: record.tokenType,
          withdrawFee: record.withdrawFee,
          minimumWithdraw: record.minimumWithdraw,
          minABI: record.minABI,
          contractAddress: record.contractAddress,
          decimals: record.decimals,
          status: record.status,
          CoinpaymetNetWorkFee: isEmpty(record.CoinpaymetNetWorkFee)
            ? 0.1
            : record.CoinpaymetNetWorkFee,
          isPrimary: false,
        };
        this.setState({ formValue: formData });
      }
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleFile = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: files[0] } };
    this.setState({ formValue: formData });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { formValue } = this.state;
    const { fetchData } = this.props;

    if (formValue && formValue.currencyImage) {
      if (formValue.currencyImage.size > 20000) {
        this.setState({
          errors: { currencyImage: "Image size should be less than  20 Kb" },
        });
        toastAlert(
          "error",
          "Image size should be less than  20 Kb",
          "currencyUpdateModal"
        );
        return false;
      }
    }

    if(formValue && formValue.status=="deactive" && !window.confirm("if you want to deactivate this currency, it will deactivate all spot & p2p pairs related to this one. Confirm?")) 
      return false;

    try {
      const formData = new FormData();
      formData.append("currencyId", formValue.currencyId);
      formData.append("type", formValue.type);
      formData.append("tokenType", formValue.tokenType);
      formData.append("currencyName", formValue.currencyName);
      formData.append("currencySymbol", formValue.currencySymbol);
      formData.append("contractAddress", formValue.contractAddress);
      formData.append("minABI", formValue.minABI);
      formData.append("decimals", formValue.decimals);
      formData.append("withdrawFee", formValue.withdrawFee);
      formData.append("minimumWithdraw", formValue.minimumWithdraw);
      formData.append("bankName", formValue.bankName);
      formData.append("accountNo", formValue.accountNo);
      formData.append("holderName", formValue.holderName);
      formData.append("bankcode", formValue.bankcode);
      formData.append("country", formValue.country);
      formData.append("currencyImage", formValue.currencyImage);
      formData.append("CoinpaymetNetWorkFee", formValue.CoinpaymetNetWorkFee);

      formData.append("status", formValue.status);

      this.setState({ loader: true });

      const { status, loading, message, error } = await updateCurrency(
        formData
      );
      this.setState({ loader: loading });
      if (status == "success") {
        this.handleClose();
        fetchData();
        toastAlert("success", message, "currencyUpdateModal");
      } else if (status == "failed") {
        if (error) {
          this.setState({ errors: error });
        } else {
          toastAlert("error", message, "currencyUpdateModal");
        }
      }
    } catch (err) {}
  };

  render() {
    const {
      type,
      tokenType,
      currencyName,
      currencySymbol,
      decimals,
      contractAddress,
      minABI,
      withdrawFee,
      minimumWithdraw,
      bankName,
      accountNo,
      holderName,
      bankcode,
      country,
      currencyImage,
      CoinpaymetNetWorkFee,
      status,
    } = this.state.formValue;
    const { errors, loader } = this.state;

    const { isShow } = this.props;

    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Update Currency</h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Currency Type</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    name="type"
                    value={type}
                    onChange={this.handleChange}
                    as="select"
                    custom
                  >
                    <option value={"crypto"}>Crypto</option>
                    <option value={"token"}>Token</option>
                    <option value={"fiat"}>Fiat</option>
                  </Form.Control>

                  <span className="text-danger">{errors.type}</span>
                </div>
              </div>

              {type == "token" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencyName">Token Type</label>
                  </div>
                  <div className="col-md-9">
                    <Form.Control
                      name="tokenType"
                      value={tokenType}
                      onChange={this.handleChange}
                      as="select"
                      custom
                    >
                      <option value={"erc20"}>ERC 20</option>
                      <option value={"bep20"}>BEP 20</option>
                      <option value={"trc20"}>TRC 20</option>
                      <option value={"spl"}>SPL</option>
                    </Form.Control>

                    <span className="text-danger">{errors.type}</span>
                  </div>
                </div>
              )}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Currency Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="currencyName"
                    type="text"
                    value={currencyName}
                    onChange={this.handleChange}
                    error={errors.currencyName}
                    className={classnames("form-control", {
                      invalid: errors.currencyName,
                    })}
                  />
                  <span className="text-danger">{errors.currencyName}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencySymbol">Currency Symbol</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="currencySymbol"
                    type="text"
                    value={currencySymbol}
                    onChange={this.handleChange}
                    error={errors.currencySymbol}
                    className={classnames("form-control", {
                      invalid: errors.currencySymbol,
                    })}
                  />
                  <span className="text-danger">{errors.currencySymbol}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencySymbol">Coinpaymet NetWork Fee</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="CoinpaymetNetWorkFee"
                    type="number"
                    value={CoinpaymetNetWorkFee}
                    onChange={this.handleChange}
                    error={errors.CoinpaymetNetWorkFee}
                    className={classnames("form-control", {
                      invalid: errors.CoinpaymetNetWorkFee,
                    })}
                  />
                  <span className="text-danger">
                    {errors.CoinpaymetNetWorkFee}
                  </span>
                </div>
              </div>

              {type == "token" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencyName">Contract Address</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="contractAddress"
                      type="text"
                      onChange={this.handleChange}
                      value={contractAddress}
                      error={errors.contractAddress}
                      className={classnames("form-control", {
                        invalid: errors.contractAddress,
                      })}
                    />
                    <span className="text-danger">
                      {errors.contractAddress}
                    </span>
                  </div>
                </div>
              )}

              {type == "token" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencyName">Min ABI</label>
                  </div>
                  <div className="col-md-9">
                    <textarea
                      name="minABI"
                      type="text"
                      value={minABI}
                      onChange={this.handleChange}
                      error={errors.minABI}
                      className={classnames("form-control", {
                        invalid: errors.minABI,
                      })}
                    />
                    <span className="text-danger">{errors.minABI}</span>
                  </div>
                </div>
              )}

              {type == "token" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Decimals</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="decimals"
                      type="number"
                      value={decimals}
                      onChange={this.handleChange}
                      error={errors.decimals}
                      className={classnames("form-control", {
                        invalid: errors.decimals,
                      })}
                    />
                    <span className="text-danger">{errors.decimals}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Bank Name</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="bankName"
                      type="text"
                      value={bankName}
                      onChange={this.handleChange}
                      error={errors.bankName}
                      className={classnames("form-control", {
                        invalid: errors.bankName,
                      })}
                    />
                    <span className="text-danger">{errors.bankName}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Account No.</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="accountNo"
                      type="number"
                      value={accountNo}
                      onChange={this.handleChange}
                      error={errors.accountNo}
                      className={classnames("form-control", {
                        invalid: errors.accountNo,
                      })}
                    />
                    <span className="text-danger">{errors.accountNo}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Holder Name</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="holderName"
                      type="text"
                      value={holderName}
                      onChange={this.handleChange}
                      error={errors.holderName}
                      className={classnames("form-control", {
                        invalid: errors.holderName,
                      })}
                    />
                    <span className="text-danger">{errors.holderName}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">IBAN Code</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="bankcode"
                      type="text"
                      value={bankcode}
                      onChange={this.handleChange}
                      error={errors.bankcode}
                      className={classnames("form-control", {
                        invalid: errors.bankcode,
                      })}
                    />
                    <span className="text-danger">{errors.bankcode}</span>
                  </div>
                </div>
              )}

              {type == "fiat" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="currencySymbol">Country</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="country"
                      type="text"
                      value={country}
                      onChange={this.handleChange}
                      error={errors.country}
                      className={classnames("form-control", {
                        invalid: errors.country,
                      })}
                    />
                    <span className="text-danger">{errors.country}</span>
                  </div>
                </div>
              )}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="fee">Withdrawal Fee(%)</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="withdrawFee"
                    type="number"
                    value={withdrawFee}
                    min="0"
                    max="10000"
                    onChange={this.handleChange}
                    error={errors.withdrawFee}
                    className={classnames("form-control", {
                      invalid: errors.withdrawFee,
                    })}
                  />
                  <span className="text-danger">{errors.withdrawFee}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="minimum">Minimum Withdrawal</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="minimumWithdraw"
                    type="number"
                    min="0"
                    max="10000"
                    value={minimumWithdraw}
                    onChange={this.handleChange}
                    error={errors.minimumWithdraw}
                    className={classnames("form-control", {
                      invalid: errors.minimumWithdraw,
                    })}
                  />
                  <span className="text-danger">{errors.minimumWithdraw}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Status</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    name="status"
                    value={status}
                    onChange={this.handleChange}
                    as="select"
                    custom
                  >
                    <option value={"active"}>Active</option>
                    <option value={"deactive"}>Deactive</option>
                  </Form.Control>

                  <span className="text-danger">{errors.type}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="minimum">Currency icon</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="currencyImage"
                    type="file"
                    onChange={this.handleFile}
                    accept="image/x-png,image/gif,image/jpeg"
                    aria-describedby="fileHelp"
                  />
                  <span className="text-danger">{errors.currencyImage}</span>
                  <img
                    className="img-fluid proofThumb"
                    src={fileObjectUrl(currencyImage)}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              {loader && <i class="fas fa-spinner fa-spin"></i>}Update currency
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CurrencyUpdateModal;
