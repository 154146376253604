import React, { Component } from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import action
import { subscriptionAddUpdate } from "../../actions/copyTradeAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import { firstLetterCase } from "../../lib/capitalize";

const initialFormValue = {
    id: "",
    type: "",
    count: 0,
    price: 0,
    adminFee: "",
    adminFeeEach: "",
    badges: [],
    status: false,
};

class SubscriptionModal extends Component {
    constructor() {
        super();
        this.state = {
            formValue: initialFormValue,
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record) {
            this.setState({
                formValue: {
                    id: record._id,
                    type: record.type,
                    count: record.count,
                    price: record.price,
                    adminFee: record.adminFee,
                    adminFeeEach: record.adminFeeEach,
                    badges: record.badges,
                    status: record.status,
                },
            });
        }
    }

    handleChange = (e) => {
        let { name, id, value } = e.target;
        let formData;
        if (name == "badges") {
            let { badges } = this.state.formValue;
            let index = badges.findIndex((badge) => badge.badgeId === value);
            if (index !== -1) {
                badges.splice(index, 1);
            } else {
                badges.push({ badgeId: value });
            }
            formData = { ...this.state.formValue, ...{ [name]: badges } };
        } else if(name == "badgeCount") {
            let { badges } = this.state.formValue;
            let index = badges.findIndex((badge) => badge.badgeId === id);
            if (index !== -1) {
                badges[index]["count"] = value;
            }
            formData = { ...this.state.formValue, ...{ [name]: badges } };
        } else {
            formData = { ...this.state.formValue, ...{ [name]: value } };
        }
        this.setState({ formValue: formData });
    };

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ formValue: initialFormValue, errors: {} });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { fetchData } = this.props;
            const { formValue } = this.state;

            const { response, loading, message, error } =
                await subscriptionAddUpdate(formValue);
            if (response == "success") {
                fetchData();
                toastAlert("success", message);
                this.handleClose();
            } else {
                if (error) {
                    this.setState({ errors: error });
                }
                toastAlert("error", message);
            }
        } catch (err) {}
    };

    validInt = async (e) => {
        if (
            new RegExp(`^\\d*?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };

    validPositive = (e) => {
        if (
            new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };

    validPrice = async (e) => {
        if (
            new RegExp(`^\\d*(\\.\\d{0,2})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };

    render() {
        const { errors } = this.state;
        const {
            id,
            type,
            count,
            price,
            adminFee,
            adminFeeEach,
            badges,
            status,
        } = this.state.formValue;
        const { activeBadges, isShow } = this.props;

        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="md"
                    centered
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">
                            {id ? "Edit" : "Add"} Subscription
                        </h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form noValidate onSubmit={this.handleSubmit}>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <label htmlFor="name">Type</label>
                                </div>

                                <div className="col-md-8">
                                    {["trader", "follower"].map((v) => (
                                        <Form.Check
                                            onChange={this.handleChange}
                                            inline
                                            type="radio"
                                            name="type"
                                            value={v}
                                            key={v}
                                            defaultChecked={v == type}
                                            label={firstLetterCase(v)}
                                        />
                                    ))}
                                    <div className="text-danger">{errors.type}</div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <label htmlFor="question">Count</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        onChange={this.handleChange}
                                        value={count}
                                        name="count"
                                        type="text"
                                        onInput={this.validInt}
                                        error={errors.count}
                                        className={classnames("form-control", {
                                            invalid: errors.count,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.count}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <label htmlFor="question">Price ($)</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        onChange={this.handleChange}
                                        value={price}
                                        name="price"
                                        type="text"
                                        onInput={this.validPrice}
                                        error={errors.price}
                                        className={classnames("form-control", {
                                            invalid: errors.price,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.price}
                                    </span>
                                </div>
                            </div>

                            {type == "follower" && activeBadges.map((v, i) => {
                                let index = badges.findIndex((badge) => Object.keys(v)[0] == badge.badgeId);
                                return (
                                    <div className="row mt-2">
                                        <div className="col-md-4">
                                            {i === 0 && (
                                                <label htmlFor="name">Can Follow Badges</label>
                                            )}
                                        </div>

                                        <div className="col-md-8">
                                            <div className="col-md-1 d-block d-md-inline">
                                                <input
                                                    onChange={this.handleChange}
                                                    type="checkbox"
                                                    name="badges"
                                                    value={Object.keys(v)[0]}
                                                    key={Object.keys(v)[0]}
                                                    defaultChecked={index!==-1}
                                                    id={`${Object.keys(v)[0]}_id`}
                                                />
                                                <label className="ms-2" htmlFor={`${Object.keys(v)[0]}_id`}>{Object.values(v)[0]}</label>
                                            </div>

                                            {index!==-1 && (
                                                <>
                                                    <input
                                                        onInput={this.validInt}
                                                        onChange={this.handleChange}
                                                        value={badges[index]["count"]}
                                                        name="badgeCount"
                                                        type="text"
                                                        id={Object.keys(v)[0]}
                                                        className={classnames("ms-2 col-md-3 d-block d-md-inline")}
                                                    />
                                                    <div className="col-md-1 d-block d-md-inline">
                                                        <label className="ms-2" htmlFor={Object.keys(v)[0]}>Count</label>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}

                            {type == "trader" && (
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <label htmlFor="adminCommission">
                                            Admin Commission (%)
                                        </label>
                                    </div>
                                    <div className="col-md-1">
                                        <label htmlFor="adminFee">Take</label>
                                    </div>
                                    <div className="col-md-2">
                                        <input
                                            onInput={this.validPositive}
                                            onChange={this.handleChange}
                                            value={adminFee}
                                            name="adminFee"
                                            type="text"
                                            error={errors.adminFee}
                                            className={classnames(
                                                "form-control",
                                                {
                                                    invalid: errors.adminFee,
                                                }
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.adminFee}
                                        </span>
                                    </div>
                                    <div className="col-md-1">
                                        <label htmlFor="adminFeeEach">
                                            Each
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <input
                                            onInput={this.validPositive}
                                            onChange={this.handleChange}
                                            value={adminFeeEach}
                                            name="adminFeeEach"
                                            type="text"
                                            error={errors.adminFeeEach}
                                            className={classnames(
                                                "form-control",
                                                {
                                                    invalid:
                                                        errors.adminFeeEach,
                                                }
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.adminFeeEach}
                                        </span>
                                    </div>
                                    <div className="col-md-1">
                                        <label htmlFor="adminFeeEach">%</label>
                                    </div>
                                </div>
                            )}

                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <label htmlFor="name">Status</label>
                                </div>

                                <div className="col-md-8">
                                    <Form.Control
                                        as="select"
                                        custom
                                        name={"status"}
                                        value={status}
                                        onChange={this.handleChange}
                                    >
                                        <option value={true}>Active</option>
                                        <option value={false}>Deactive</option>
                                    </Form.Control>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            type="submit"
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default SubscriptionModal;
