import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { newsletteremail } from "../../actions/userActions";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Multiselect from "multiselect-dropdown-react";
import { addairdrop, getairdropList } from "../../actions/airdropAction";
import { Modal, Form } from "react-bootstrap";
import { currencyList, getcurrencyList } from "../../actions/currency";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import isEmpty from "is-empty";

import AirdropList from "./Airdroplist"

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_assigned: false,
      email: {},
      tokenamount: "",
      errors: {},
      firstCurrencyId: "",
      currencyOptions: [],
      formValue: "",
    };
    console.log(this.state, "state");
  }

  componentDidMount() {
    this.getData();
    this.getCurrencyData();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps, "nextprops");
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.profile !== undefined &&
      nextProps.auth.profile.data !== undefined &&
      nextProps.auth.profile.data.message !== undefined
    ) {
      toast(nextProps.auth.profile.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.profile = undefined;
    }
  }

  result = (params) => {
    console.log(params, "emailssssemailssss");
    this.setState({
      email1: params,
    });


  };
  search = (params) => {
    console.log(params);
    this.setState({
      email1: params,
    });

    console.log(params, "emailssss");
  };

  onChange = (e) => {
    this.setState({ tokenamount: e.target.value });
  };

  getData() {
    axios
      // .get(url + "adminApi/user")getUserListforairdrop
      .get("adminApi/getUserListforairdrop")

      .then((res) => {
       
        var arremail = [];
        res.data.result.data.map((item, i) => {
          console.log("email",)
          if(isEmpty(item.email)){
            console.log(item.phoneNo, "RSSss");
            const name = item.phoneNo;
            const value = item.phoneNo;
            const _id=item._id
            const obj = { label: name, value: value ,_id:_id};
            arremail.push(obj);
          }else{
            const name = item.email;
            const value = item.email;
            const _id=item._id
            const obj = { label: name, value: value ,_id:_id};
            arremail.push(obj);
          }
          
        });

        this.setState({ email: arremail, email_assigned: true });
      })
      .catch();
    console.log(this.props, "authget1");
  }

  async getCurrencyData() {
    const { result, status } = await getcurrencyList();

    if (status == "success") {
      var currencyarray = [];
      result.map((item, i) => {
        const id = item._id;
        const label = item.currencySymbol;
        const obj = { value: id, label: label };
        currencyarray.push(obj);
        this.setState({ currencyOptions: currencyarray });
      });
    }
  }

  onemailsend = async (e) => {
    const { formValue } = this.state;
    e.preventDefault();
      if(!this.state.email1){
        toast("Please Select Email", {
          position: toast.POSITION.TOP_CENTER,
        });
        return
      }
      if(!this.state.formValue){
        toast("Please Select Currency", {
          position: toast.POSITION.TOP_CENTER,
        });
        return
      }
      if(!this.state.tokenamount){
        toast("Please Enter Amount", {
          position: toast.POSITION.TOP_CENTER,
        });
        return
      }
      if(this.state.tokenamount<=0){
        toast("Please Enter Valid Amount", {
          position: toast.POSITION.TOP_CENTER,
        });
        return
      }

      if (window.confirm("Are you sure to send") == true) {
       
        
      const emailist = [];
      var length = this.state.email1.length
      this.state.email1 && this.state.email1.length > 0 && this.state.email1.map((item, el) => {
        emailist.push(item.value)
      })

      console.log("emailistemailist", emailist)
      if (emailist && this.state.tokenamount > 0 && formValue) {
        const data = {
          email: this.state.email1,
          tokenamount: this.state.tokenamount,

          currencyId: formValue,
        };

        const { status, loading, message, error } = await addairdrop(data);

        if (status == "success") {
          toast("Airdrop sent successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          // this.props.history.push("/airdroplist");
          window.location.reload()
        }
      } else {
        toast("Please Fill all the fields", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    let { name, id, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [id]: value } };
    this.setState({ formValue: formData });
  };

  render() {
    const { errors, currencyOptions } = this.state;
    var email_try = this.state.email[0];
    const { formValue } = this.state;

    const { firstCurrencyId } = this.state.formValue;

    if (typeof email_try == "object") {
    }
    if (this.state.email_assigned) {
      Object.keys(email_try).forEach(function (key) { });
    }

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Send AirDrop</h3>
              <form noValidate onSubmit={this.onemailsend} id="send-email" autocomplete="off">
                <div className="row mt-2">
                  <div className="col-md-3">
                    {/* <label htmlFor="email">Email : </label> */}
                  </div>
                  <div className="col-md-9">
                    {this.state.email1 && this.state.email1.length > 0 && this.state.email1.map((item, el) => {
                      return (

                        <p >{item.value},{" "}</p>

                      )

                    })}
                    <span className="text-danger">{errors.email}</span>
                  </div>
                </div>


                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="email">Email : </label>
                  </div>
                  <div className="col-md-9">
                    {this.state.email_assigned ? (
                      <ReactMultiSelectCheckboxes options={this.state.email} onChange={this.result} />
                      // <Multiselect
                      // onSearch={this.search}
                      //   options={this.state.email}
                      //   onSelectOptions={this.result}
                      // />
                    ) : (
                      ""
                    )}

                    <span className="text-danger">{errors.email}</span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="identifier">Currency / Token : </label>
                  </div>
                  <div className="col-md-9">
                    <Form.Control
                      as="select"
                      custom
                      name={"firstCurrencyId"}
                      id={"firstCurrencyId"}
                      value={firstCurrencyId}
                      onChange={this.handleChange}
                    >
                      <option value={""}>{"Select Currency"}</option>
                      {currencyOptions &&
                        currencyOptions.length > 0 &&
                        currencyOptions.map((item, key) => {
                          // if (item.label == "MUDRA") {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                          // }
                        })}
                    </Form.Control>
                    <span className="text-danger">
                      {errors.firstCurrencyId}
                    </span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="content">Token Amount : </label>
                  </div>

                  <div className="col-md-9">
                    <input
                      onChange={this.onChange}
                      value={this.state.tokenamount}
                      id="tokenamount"
                      type="text"
                      // error={errors.categoryNameerr}
                      className={classnames("form-control", {
                        invalid: errors.tokenamount,
                      })}
                    />
                    <span style={{ color: "red" }}>
                      {errors.tokenamounterr}
                    </span>
                    {/*<span className="text-danger">{errors.categoryNameerr}</span> */}
                  </div>
                </div>
              </form>
              <br />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="send-email"
                  type="submit"
                  className="btn btn-primary"
                >
                  Send
                </button>
              </div>
              <AirdropList/>
            </div>
          </div>


         
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Newsletter.propTypes = {
  newsletteremail: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { newsletteremail })(
  withRouter(Newsletter)
);
