import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import TDSUpdate from "../partials/userTDSUpdateModal";

// import action
import { getAllUserKyc } from "../../actions/userKycAction";
import { getUserTDS } from "../../actions/tdsAction";

// import lib
import { firstLetterCase } from "../../lib/capitalize";

class UserTDSList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "name",
        text: "Name",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{record.name ? record.name : "-"}</Fragment>;
        },
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{record.email ? record.email : "-"}</Fragment>;
        },
      },
      {
        key: "tds_status",
        text: "TDS Status",
        className: "tds_status",
        align: "left",
        sortable: true,
        cell: (record) => {
          if (record.tds_status == "0") {
            return <Fragment>New</Fragment>;
          } else if (record.tds_status == "1") {
            return <Fragment>Verified</Fragment>;
          } else {
            return <Fragment>Pending</Fragment>;
          }
        },
      },

      {
        key: "tdsproof",
        text: "TDS Document",
        className: "tdsproof",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>
                &nbsp;&nbsp;&nbsp;
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.editRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  View
                </button>
              </span>
            </>
          );
        },
      },
    ];

    this.state = {
      totalCount: 0,
      records: [],
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "createdAt",
        order: "desc",
      },
      loading: true,
      exportType: "current",
      extraButtons: [{
        className: "btn btn-primary buttons-pdf",
        title: "Export PDF",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportPDF();
        },
      },
      {
        className: "btn btn-primary buttons-csv",
        title: "Export CSV",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportCSV();
        },
      }],
    };

    this.config = {
      page_size: this.state.pageSize,
      sort: this.state.sortOrder,
      length_menu: [10, 20, 50],
      no_data_text: "No TDS found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "_id",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      pagination: "advance",
      show_info: true,
      button: {
        extra: true,
      }
    };

    this.getData = this.getData.bind(this);
    this.handleCloseDocForm = this.handleCloseDocForm.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (filter = "") => {
    try {
      let data = {
        sortOrder: this.state.sortOrder,
        filter,
        limit: this.state.pageSize,
        offset: this.state.offset,
      };
      this.setState({ loading: true });
      let { status, result, totalCount } = await getUserTDS(data);
      if (status == "success") {
        this.setState({ loading: false });
        this.setState({ records: result, totalCount });
      }
    } catch (err) {}
  }

  editRecord(record) {
    let docRecord = {
      userId: record.userId,
      id: record._id,
      endfy: record.endfy,
      startfy: record.startfy,
      tdsproof: record.tdsproof,
      email: record.email,
      tds_status: record.tds_status,
      name: record.name,
    };
    this.setState({ docFormModal: true, docRecord });
  }

  search = data => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number-1)*pageSize;
    let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
    this.setState({ records: [], offset, pageSize, sortOrder, filter }, function() {
      this.getData(filter);
    }); 
  }

  handleCloseDocForm() {
    this.setState({ docFormModal: false });
  }

  exportCSV = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let data = {
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1,
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });

    let { status, result } = await getUserTDS(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];
    let separator = ";";

    let headers = [
      "Name",
      "Email",
      "TDS Status"
    ];

    let rows = [];
    records.length > 0 && records.map((elt) => rows.push({
      "Name": elt.name ? elt.name : "-",
      "Email": elt.email ? elt.email : "-",
      "TDS Status": elt.tds_status=="0" ? "New": elt.tds_status=="1" ? "Verified" : "Pending",
    }));

    let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
      headers.map((k) => {
        let cell = row[k] === null || row[k] === undefined ? "" : row[k];

        cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator)
    ).join("\n")}`;

    let title = "User TDS";
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // In case of IE 10+
      navigator.msSaveBlob(blob, title);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", title);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  exportPDF = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let data = {
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1,
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });

    let { status, result } = await getUserTDS(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];

    let unit = "pt";
    let size = "A4"; // Use A1, A2, A3 or A4
    let orientation = "landscape"; // portrait or landscape

    let marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title = "User TDS";
    let headers = [[
      "Name",
      "Email",
      "TDS Status"
    ]];

    let downloadData = records.length > 0 && records.map((elt) => [
      elt.name ? elt.name : "-",
      elt.email ? elt.email : "-",
      elt.tds_status=="0" ? "New": elt.tds_status=="1" ? "Verified" : "Pending",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: downloadData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${title}.pdf`);
  }

  render() {
    const { docFormModal, docRecord, loader, count } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <TDSUpdate
            isShow={docFormModal}
            onHide={this.handleCloseDocForm}
            fetchData={this.getData}
            record={docRecord}
          />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="float-xl-right">
                Export
                {["current", "all"].map((v) => (
                  <Form.Check
                    inline
                    type="radio"
                    name="exportType"
                    value={v}
                    key={v}
                    onChange={(e) => this.setState({ exportType: e.target.value })}
                    defaultChecked={v == this.state.exportType}
                    label={firstLetterCase(v)}
                  />
                ))}
              </div>
              <h3 className="mt-2 text-secondary">User TDS List</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                loading={this.state.loading}
                onChange={this.search}
                total_record={this.state.totalCount}
                extraButtons={this.state.extraButtons}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserTDSList;