import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import authLogo from "../../images/logo.png";
import config from "../../config/index";
import browser from "browser-detect";

// import action
import { login, getGeoInfoData } from "../../actions/admin";

// import lib
import { toastAlert } from "../../lib/toastAlert";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      loginHistory: {},
    };
  }

  getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      console.log("result", result);

      const browserResult = browser();
      console.log(browserResult, "browserResultbrowserResult");
      this.setState({
        loginHistory: {
          countryName: result.country_name,
          countryCode: result.country_calling_code,
          ipaddress: result.ip,
          region: result.region,
          broswername: browserResult.name,
          ismobile: browserResult.mobile,
          os: browserResult.os,
        },
      });
    } catch (err) {}
  };

  componentDidMount() {
    document.body.style.backgroundColor = "black";
    // if (this.props.auth.isAuthenticated) {
    //     this.props.history.push("/dashboard");
    // }
    this.getGeoInfo()
  }

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.auth.isAuthenticated) {
  //         this.props.history.push("/dashboard");
  //     }

  //     if (nextProps.errors) {
  //         this.setState({
  //             errors: nextProps.errors
  //         });
  //     }
  // }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = async (e) => {
    console.log("submit 1232131313");
    e.preventDefault();
    const { dispatch, history } = this.props;
    const reqData = {
      email: this.state.email,
      password: this.state.password,
      loginHistory: this.state.loginHistory,
    };
    try {
      const { status, loading, message, error } = await login(
        reqData,
        dispatch
      );
      if (status == "success") {
        // history.push("/dashboard");
        setTimeout(() => {
          window.location.href = config.ADMIN_URL + "/dashboard";
        }, 2000);

        toastAlert("success", message, "login");
      } else {
        if (error) {
          this.setState({ errors: error });
        }
        toastAlert("error", message, "login");
      }
    } catch (err) {}
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-4 mx-auto mt-5 card shadow-lg changecolor">
            <div className="card-body p-2">
              <center className="pt-3">
                <img src={authLogo} className="img-fluid" alt="" />
              </center>
              <h2 className="text-center  backgorund">Login</h2>
              <form noValidate onSubmit={this.onSubmit} className="white">
                <label htmlFor="email" className="backgorund">
                  Email :
                </label>
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("form-control", {
                    invalid: errors.email,
                  })}
                />
                <span className="text-danger">{errors.email}</span>
                <br />
                <label htmlFor="password" className="backgorund">
                  Password :
                </label>
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("form-control", {
                    invalid: errors.password,
                  })}
                />
                <span className="text-danger">{errors.password}</span>
                <p className="text-center pb-0 mt-2">
                  <button
                    type="submit"
                    className="btn btn-large btn-primary mt-2 px-5"
                  >
                    Login
                  </button>
                </p>
                <p className="grey-text text-darken-1">
                  {/* Don't have an account? <Link to="/forgot">Forgot Password?</Link> */}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(Login);
