import axios from "../config/axios";

let adSource, tradeSource, disputeSource, revenueSource;

export const getDisputeList = async () => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminApi/p2pdisputelist`,
        });
        return {
            status: respData.data.status,
            message: respData.data.result.messages,
            result: respData.data.data,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};
export const p2presolveBuyer = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminApi/p2presolveBuyer`,
            data: data,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.result.messages,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};
export const p2presolveSeller = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminApi/p2presolveSeller`,
            data: data,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.result.messages,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const adsList = async (data) => {
    try {

        if (adSource) adSource.cancel();
        adSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/adsList`,
            method: "post",
            data,
            cancelToken: adSource.token,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const tradeList = async (data) => {
    try {

        if (tradeSource) tradeSource.cancel();
        tradeSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/tradeList`,
            method: "post",
            data,
            cancelToken: tradeSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const disputeList = async (data) => {
    try {

        if (disputeSource) disputeSource.cancel();
        disputeSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/disputeList`,
            method: "post",
            data,
            cancelToken: disputeSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const adminRevenue = async (data) => {
    try {

        if (revenueSource) revenueSource.cancel();
        revenueSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/adminRevenue`,
            method: "post",
            data,
            cancelToken: revenueSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};