import React, { Component } from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import action
import { badgeAddUpdate } from "../../actions/copyTradeAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import fileObjectUrl from "../../lib/fileObjectUrl";

const initialFormValue = {
    id: "",
    name: "",
    profit: 0,
    loss: 0,
    trades: 0,
    maximumCommission: 0,
    badgeIcon: "",
    badgeIconUrl: "",
    status: false,
};

class BadgeModal extends Component {
    constructor() {
        super();
        this.state = {
            formValue: initialFormValue,
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record) {
            this.setState({
                formValue: {
                    id: record._id,
                    name: record.name,
                    profit: record.profit,
                    loss: record.loss,
                    trades: record.trades,
                    maximumCommission: record.maximumCommission,
                    badgeIconUrl: record.iconUrl,
                    status: record.status,
                },
            });
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
    };

    handleFile = (e) => {
        e.preventDefault();
        const { name, files } = e.target;
        let badgeIconUrl = fileObjectUrl(files[0]);
        let formData = { ...this.state.formValue, ...{ [name]: files[0], badgeIconUrl } };
        this.setState({ formValue: formData });
    };

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ formValue: initialFormValue, errors: {} });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { fetchData } = this.props;
            const { id, name, profit, loss, trades, maximumCommission, badgeIcon, status } = this.state.formValue;

            const formData = new FormData();
            formData.append("id", id);
            formData.append("name", name);
            formData.append("profit", profit);
            formData.append("loss", loss);
            formData.append("trades", trades);
            formData.append("maximumCommission", maximumCommission);
            formData.append("badgeIcon", badgeIcon);
            formData.append("status", status);

            const { response, loading, message, error } = await badgeAddUpdate(formData);
            if (response == "success") {
                fetchData();
                toastAlert("success", message);
                this.handleClose();
            } else {
                if (error) {
                    this.setState({ errors: error });
                }
                toastAlert("error", message);
            }
        } catch (err) {}
    };

    validPositive = async (e) => {
        if (new RegExp(`^\\d*?$`).test(e.target.value) || (e.target.value = "")) {
          e.preventDefault();
        }
    }

    render() {
        const { errors } = this.state;
        const { id, name, profit, loss, trades, maximumCommission, badgeIcon, badgeIconUrl, status } = this.state.formValue;
        const { isShow } = this.props;

        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="md"
                    centered
                    // scrollable={true}
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">{ id ? "Edit":"Add"} Badge</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            noValidate
                            onSubmit={this.handleSubmit}
                            id="add-faq"
                        >
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="question">
                                        Name
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={name}
                                        name="name"
                                        type="text"
                                        error={errors.name}
                                        className={classnames("form-control", {
                                            invalid: errors.name,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.name}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="question">
                                        Profit Ratio
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={profit}
                                        name="profit"
                                        type="text"
                                        onInput={this.validPositive}
                                        error={errors.profit}
                                        className={classnames("form-control", {
                                            invalid: errors.profit,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.profit}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="question">
                                        Loss Ratio
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={loss}
                                        name="loss"
                                        type="text"
                                        onInput={this.validPositive}
                                        error={errors.loss}
                                        className={classnames("form-control", {
                                            invalid: errors.loss,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.loss}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="question">
                                        Trades Count
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={trades}
                                        name="trades"
                                        type="text"
                                        onInput={this.validPositive}
                                        error={errors.trades}
                                        className={classnames("form-control", {
                                            invalid: errors.trades,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.trades}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="question">
                                        Maximum Commission
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={maximumCommission}
                                        name="maximumCommission"
                                        type="text"
                                        onInput={this.validPositive}
                                        error={errors.maximumCommission}
                                        className={classnames("form-control", {
                                            invalid: errors.maximumCommission,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.maximumCommission}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="minimum">Badge Icon</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        name="badgeIcon"
                                        type="file"
                                        onChange={this.handleFile}
                                        accept="image/x-png,image/gif,image/jpeg"
                                        aria-describedby="fileHelp"
                                    />
                                    <div className="text-danger">{errors.badgeIcon}</div>
                                    {
                                        badgeIconUrl && (
                                            <img className="img-fluid proofThumb" src={badgeIconUrl} />
                                        )
                                    }
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Status</label>
                                </div>

                                <div className="col-md-9">
                                    <Form.Control
                                        as="select"
                                        custom
                                        name={"status"}
                                        value={status}
                                        onChange={this.handleChange}
                                    >
                                        <option value={true}>Active</option>
                                        <option value={false}>Deactive</option>
                                    </Form.Control>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            type="submit"
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default BadgeModal;