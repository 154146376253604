// import config
import axios from "../config/axios";
import config from "../config";
import keys from "./config";

const url = keys.baseUrl;

let cmsSource;

export const getCmsList = async (data = {}) => {
    try {

        if (cmsSource) cmsSource.cancel();
        cmsSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/cms`,
            method: "post",
            data,
            cancelToken: cmsSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const updateCms = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/cms`,
            method: "put",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.result,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

// export const addCms = (cmsData) => dispatch => {
//     axios
//         .post(url + "api/cms-add", cmsData)
//         .then(res =>
//             dispatch({
//                 type: CMS_ADD,
//                 payload: res,
//             })
//         ).catch(err =>
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: err.response.data
//             })
//         );
// };

export const cmsDelete = async (id) => {
    try {
        const respData = await axios({
            url: `/adminapi/cms`,
            method: "delete",
            data: { id },
        });
        return {
            status: "success",
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getLaunchpadCmsList = async () => {
    try {
        const respData = await axios({
            url: `/adminApi/get-launchpadcms`,
            method: "get",
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        console.log("err", err);
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const updateLaunchpadCms = async (data) => {
    console.log("data", data);
    try {
        const respData = await axios({
            url: `${config.API_URL}/adminApi/updateLaunchpadCms`,

            method: "put",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};
export const addhompagecms = async (data) => {
    console.log("data", data);
    try {
        const respData = await axios({
            url: `${config.API_URL}/adminApi/addhompagecms`,

            method: "post",
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};