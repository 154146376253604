import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

//import compoentns
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//imprt actions
import { TicketList } from "../../actions/supportAction";

//lib
import { firstLetterCase } from "../../lib/capitalize";

class Support extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "email",
        text: "Raised By",
        className: "email",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.email ? record.email : "+" + record.phoneCode + " " + record.phone;
        },
      },
      {
        key: "createdAt",
        text: "Raised On",
        className: "createdAt",
        align: "left",
        sortable: true,
        cell: (record) => {
          return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
        },
      },
      {
        key: "categorySubjectname",
        text: "Subject",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: true,
        cell: (record) => {
          return firstLetterCase(record.status);
        }
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-reply"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.state = {
      totalCount: 0,
      records: [],
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "createdAt",
        order: "desc"
      },
      loading: true,
      exportType: "current",
      extraButtons: [{
        className: "btn btn-primary buttons-pdf",
        title: "Export PDF",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportPDF();
        },
      },
      {
        className: "btn btn-primary buttons-csv",
        title: "Export CSV",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
          </span>
        ],
        onClick: (event) => {
          this.exportCSV();
        },
      }],
    };

    this.config = {
      page_size: this.state.pageSize,
      sort: this.state.sortOrder,
      length_menu: [10, 20, 50],
      no_data_text: "No ticket found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "_id",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      pagination: "advance",
      show_info: true,
      button: {
        extra: true,
      }
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (filter = "") => {
    try {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let data = {
        timezone,
        sortOrder: this.state.sortOrder,
        filter,
        limit: this.state.pageSize,
        offset: this.state.offset,
      };
      let { status, result, totalCount } = await TicketList(data);
      if (status) {
        this.setState({ loading: false });
        this.setState({ records: result, totalCount });
      }
    } catch (err) {}
  }

  search = data => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number-1)*pageSize;
    let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
    this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
      this.getData(filter);
    }); 
  }

  editRecord(record) {
    this.props.history.push("/support_reply/" + record._id);
  }

  exportCSV = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1,
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });

    let { status, result } = await TicketList(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];
    let separator = ";";

    let headers = [
      "Raised By",
      "Raised On",
      "Subject",
      "Status",
    ];

    let rows = [];
    records.length > 0 && records.map((elt) => rows.push({
      "Raised By": elt.email ? elt.email : "+" + elt.phoneCode + " " + elt.phone,
      "Raised On": moment(elt.createdAt).format("DD-MM-YYYY HH:mm:ss"),
      "Subject": elt.categorySubjectname,
      "Status": firstLetterCase(elt.status),
    }));

    let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
      headers.map((k) => {
        let cell = row[k] === null || row[k] === undefined ? "" : row[k];

        cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator)
    ).join("\n")}`;

    let title = "Support Tickets";
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // In case of IE 10+
      navigator.msSaveBlob(blob, title);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", title);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  exportPDF = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1,
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });

    let { status, result } = await TicketList(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];

    let unit = "pt";
    let size = "A4"; // Use A1, A2, A3 or A4
    let orientation = "landscape"; // portrait or landscape

    let marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title = "Support Tickets";
    let headers = [[
      "Raised By",
      "Raised On",
      "Subject",
      "Status",
    ]];

    let downloadData = records.length > 0 && records.map((elt) => [
      elt.email ? elt.email : "+" + elt.phoneCode + " " + elt.phone,
      moment(elt.createdAt).format("DD-MM-YYYY HH:mm:ss"),
      elt.categorySubjectname,
      firstLetterCase(elt.status),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: downloadData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${title}.pdf`);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="float-xl-right">
                Export
                {["current", "all"].map((v) => (
                  <Form.Check
                    inline
                    type="radio"
                    name="exportType"
                    value={v}
                    key={v}
                    onChange={(e) => this.setState({ exportType: e.target.value })}
                    defaultChecked={v == this.state.exportType}
                    label={firstLetterCase(v)}
                  />
                ))}
              </div>
              <h3 className="mt-2 text-secondary">Support Ticket List</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                loading={this.state.loading}
                onChange={this.search}
                total_record={this.state.totalCount}
                extraButtons={this.state.extraButtons}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Support;