// import lib
import axios from "../config/axios";

let spotPairSource, p2pPairSource;

export const spotPairList = async (data = {}) => {
    try {

        if (spotPairSource) spotPairSource.cancel();
        spotPairSource = axios.CancelToken.source();

        let respData = await axios({
            method: "patch",
            url: `/adminApi/spotPair`,
            data,
            cancelToken: spotPairSource.token,
        });
        return {
            status: "success",
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const addSpotPair = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminApi/spotPair`,
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const editSpotPair = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/adminApi/spotPair`,
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const P2PPairList = async (data) => {
    try {
        if (p2pPairSource) p2pPairSource.cancel();
        p2pPairSource = axios.CancelToken.source();
        let respData = await axios({
            method: "patch",
            url: `/adminApi/p2pPair`,
            data,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            cancelToken: p2pPairSource.token,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};

export const addP2PPair = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminApi/p2pPair`,
            data: data,
        });
        console.log("resp---", respData);
        return {
            status: true,
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        console.log("err0---", err);
        return {
            status: false,
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const editP2PPair = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/adminApi/p2pPair`,
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};