import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "ckeditor4-react";
import Multiselect from "multiselect-dropdown-react";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import {
  newsletteremail,
  getSubscribedEmails,
} from "../../actions/userActions";

// import lib
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  selectedMails: [],
  message: "",
};

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_assigned: false,
      emails: {},
      formValue: initialFormValue,
      errors: {},
      loading: true,
      submitLoader: false,
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  onSelected = (params) => {
    let formData = {
      ...this.state.formValue,
      ...{ selectedMails: params },
    };
    this.setState({ formValue: formData });
    this.setState({ errors: { ...this.state.errors, ...{ selectedMails:"" } } });
  };

  handleEditorChange(e) {
    let formData = {
      ...this.state.formValue,
      ...{ message: e.editor.getData() },
    };
    this.setState({ formValue: formData });
    this.setState({ errors: { ...this.state.errors, ...{ message:"" } } });
  }

  getData = async () => {
    try {
      let { status, result } = await getSubscribedEmails();
      if (status) {
        this.setState({ loading: false });
        let subscribers = [];
        result.map((item, i) => {
          subscribers.push({ name: item.email, value: item.email });
        });
        this.setState({ emails: subscribers, email_assigned: true });
      }
    } catch (err) {}
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let { formValue } = this.state;
      this.setState({ submitLoader: true });
      let { status, message, error } = await newsletteremail(formValue);
      this.setState({ submitLoader: false });
      if (status == "success") {
        toastAlert("success", message);
        window.location.reload(false);
      } else {
        if (error)
          this.setState({ errors: error });
        if (message)
          toastAlert("error", message);
      }
    } catch (err) {}
  };

  render() {
    let { message } = this.state.formValue;
    let { email_assigned, emails, errors, loading, submitLoader } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Newletter Details</h3>
              <form noValidate onSubmit={this.handleSubmit} id="send-email">
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="col-md-9">
                    {loading ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : email_assigned ? (
                      <Multiselect
                        options={emails}
                        onSelectOptions={this.onSelected}
                      />
                    ) : (
                      ""
                    )}
                    <span className="text-danger">{errors.selectedMails}</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="content">Message</label>
                  </div>
                  <div className="col-md-9">
                    <CKEditor
                      config={{
                        extraAllowedContent: "div(*)",
                        allowedContent: true,
                        height: 300,
                      }}
                      initData={message}
                      onChange={this.handleEditorChange}
                    />
                    <span className="text-danger">{errors.message}</span>
                  </div>
                </div>
              </form>
              {submitLoader ? (
                      <i
                        className="fa fa-spinner fa-spin float-xl-right mt-2"
                        aria-hidden="true"
                      ></i>
                    ) : (
              <button
                form="send-email"
                type="submit"
                className="btn btn-primary float-xl-right mt-2"
              >
                Send
              </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Newsletter;