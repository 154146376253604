import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE,
    PROFILE_UPDATE,
    PASSWORD_UPDATE,
    SETTINGS_UPDATE,
    REPLY_CONTACT,
    SUPPORT_REPLY,

} from "./types";
import keys from "./config";

const url = keys.baseUrl;

let usersSource, balanceSource;

export const addUser = (userData, history) => dispatch => {
    axios
        .post(url + "api/user-add", userData)
        .then(res =>
            dispatch({
                type: USER_ADD,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const updateUser = (userData) => dispatch => {
    axios
        .post(url + "api/user-update", userData)
        .then(res =>
            dispatch({
                type: USER_UPDATE,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const updateProfile = (userData) => dispatch => {
    console.log(userData, 'userData2');
    axios
        .post(url + "adminapi/profileupload", userData)
        .then(res =>
            dispatch({
                type: PROFILE_UPDATE,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const updateChangepassword =async (userData)  => {
   
   
        try {
             console.log("userDatauserDatauserData",userData)
            let respData = await axios({
                'method': 'post',
                'url': `/adminApi/resetPassword`,
                 'data':userData
            });
    
            return {
                status: true,
                loading: false,
                message: respData.data.messages
            }
        }
        catch (err) {
            return {
                status: false,
                loading: false,
                message: err.response.data.message,
                error: err.response.data.errors
            }
        }
};

export const newsletteremail = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/sendNewsLetter`,
            data
        });
        return {
            status: "success",
            message: respData.data.message
        };
    } catch (err) {
        return {
            status: "failed",
            message: err.response.data.message,
            error: err.response.data.errors
        };
    }
};

export const getSubscribedEmails = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/getSubscribedEmails`
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const updateSettings = (userData) => dispatch => {
    axios
        .post(url + "api/updateSettings", userData)
        .then(res =>
            dispatch({
                type: SETTINGS_UPDATE,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const replyContact = (userData) => dispatch => {
    axios
        .post(url + "adminApi/replycontact", userData)
        .then(res =>
            dispatch({
                type: REPLY_CONTACT,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const replysupport = (supportreplyData) => dispatch => {
    console.log(supportreplyData, 'supportreplyData');
    console.log(dispatch, 'dispatch');

    axios
        .post(url + "api/support_reply_admin", supportreplyData)
        .then(res =>
            dispatch({
                type: SUPPORT_REPLY,
                payload: res,
            })
        ).catch(err =>

            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const updatedynamic = (userData) => dispatch => {
    console.log("userdata ", userData)
    axios
        .post(url + "api/updatedynamic", userData)
        .then(res =>
            dispatch({
                type: SETTINGS_UPDATE,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const getTableDataDynamic = (dynobj) => dispatch => {
    axios
        .post(url + "cryptoapi/getTableDataDynamic", dynobj)
        .then(res => dispatch({
            type: SETTINGS_UPDATE,
            payload: res,
        }))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const Botfunction = (userData) => dispatch => {
    axios
        .post(url + "api/Botfunction", userData)
        .then(res =>
            dispatch({
                type: SETTINGS_UPDATE,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const SpotTradeOrderplacing = (userData) => dispatch => {
    axios
        .post(url+"cryptoapi/spottradeorderplacing", userData)
        .then(res =>
            dispatch({
                type: SETTINGS_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const getUsers = async (data = {}) => {
    try {

        if (usersSource) usersSource.cancel();
        usersSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getUsers`,
            data,
            cancelToken: usersSource.token,
        });
        return {
            status: true,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: false
        }
    }
}

export const updateStatus = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `adminapi/user-changestatus`,
            data,
        });
        return {
            status: true,
            message: respData.data.message
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message
        };
    }
};

export const deactivate2FA = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `adminapi/user-2faremove`,
            data,
        });
        return {
            status: true,
            message: respData.data.message
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message
        };
    }
};

export const updateUserDiscount = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/updateUserDiscount`,
            data
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getUserBalnce = async (data = {}) => {
    try {

        if (balanceSource) balanceSource.cancel();
        balanceSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/getUserBalnce`,
            data,
            cancelToken: balanceSource.token,
        });
        return {
            status: true,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: false
        }
    }
}
