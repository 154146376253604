// import config
import axios from "../config/axios";
import config from "../config";
import keys from "./config";
const url = keys.baseUrl;

export const getairdropList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/airdrop`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addairdrop = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/airdrop`,
      method: "post",
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const addfees = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/feesowntoken`,
      method: "post",
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getfees = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/feesowntoken`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addfeesreferral = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/savereferral`,
      method: "post",
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getfees_referral = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/savereferral`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
