// import config
import axios from "../config/axios";

let priceCNVSource;

export const priceCNVlist = async (data = {}) => {
    try {

        if (priceCNVSource) priceCNVSource.cancel();
        priceCNVSource = axios.CancelToken.source();

        const respData = await axios({
            url: `/adminapi/priceCNV`,
            method: "post",
            data,
            cancelToken: priceCNVSource.token,
        });
        return {
            status: "success",
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const priceCNVUpdate = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/adminapi/priceCNV`, //priceCNV -- price conversion
            data: data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};